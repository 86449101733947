/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Table, Popconfirm } from "antd";
import styled from "styled-components";
import { Input } from "antd";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { permissionCheck } from "../../helpers/helpers";
import DatatableHeaderText from "../text/datatable/datatableHeaderText";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { selectTab } from "../../store/tab/tabSlice";

export default function TableData({
	rows,
	columns,
	option = false,
	setValue = () => { },
	setValue2 = () => { },
	searchActive = false,
	selectPath,
	deleteItem = () => { },
	details = true,
	onChange,
}) {
	const { Search } = Input;
	const history = useHistory();
	const dispatch = useDispatch();
	const [newRows, setNewRows] = useState();
	const [eddit, setEddit] = useState(false);
	const [prueba, setPrueba] = useState(false);

	const dataState = useSelector((state) => state.authState)
	const handleRol = () => {
		if (dataState.rol === 'admin') {
			setPrueba(true)
		} else if (dataState.rol === 'admin_isp') {
			setPrueba(false)
		} else if (dataState.rol === 'multifiber') {
			setPrueba(true)
		} else if (dataState.rol === 'supervisor_isp') {
			setPrueba(false)
		}
	}

	const array = dataState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const rowSelection = (item, key) => {
		// cundo llegue al detalle, comienza en el primer Tab
		dispatch(selectTab({ selectedTab: 0 }))
		selectPath && history.push(selectPath, { params: item });
	};

	const rowsOptios = rows?.map((e) => ({ ...e, option: e?.name }));

	const renderOptions = (_, record) => {
		if (rolPermission("delete_ostype")) {
			return (
				<ContainerButton>
					<Popconfirm
						placement="topRight"
						icon={null}
						title="¿Eliminar registro?"
						description={() => {
							return (
								<span>
									Si eliminas este registro, se eliminará de forma permanente de tu historial
								</span>
							);
						}}
						onConfirm={(e) => {
							e.stopPropagation();
							deleteItem(record);
						}}
						onCancel={(e) => { e.stopPropagation() }}
						okText="Eliminar"
						cancelText="Cancelar"
					>
						<button
							onClick={(e) => e.stopPropagation()}
							className="btn-datatable-displacement"
						>
							Eliminar
						</button>
					</Popconfirm>
				</ContainerButton>
			);
		}
		return null; // Si no se cumple la condición, no se renderiza nada
	};

	const columnsOptios = [
		...columns,
		{
			title: <DatatableHeaderText value="Acción" />,
			dataIndex: "option",
			key: "option",
			width: "150px",
			className: "rowOptios",
			render: renderOptions,
		}
	];




	useEffect(() => { handleRol() }, [])

	return (
		<ContainerTable
			colorHover={details && '#0060ff'}
		>
			<Table
				className="table"
				columns={
					option && rolPermission("delete_ostype")
						? columnsOptios
						: columns
				}
				dataSource={option ? rowsOptios : rows}
				responsive={"xs"}
				pagination={false}
				// scroll={{ x: 1500, y: 1500 }}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							rowSelection(record, rowIndex);
						},
					};
				}}
				onChange={onChange}
			/>
		</ContainerTable>
	);
}

const ContainerTable = styled.div`

	.table {
		min-height: calc(100vh - 250px);
		tr th,
		tr td {
			text-align: center;
			border:none;
			outline:none;
		}

		tr td {
			cursor: pointer;
		}
	}
`;

const ContainerButton = styled.div`

.btn-datatable-displacement{
	color: #2B80FF;
	background: transparent;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-datatable-displacement{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-datatable-displacement{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.btn-datatable-displacement{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

