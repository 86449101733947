import React, { useState, useCallback, useEffect } from 'react'
import { Panel } from '../../../components/panel/panel'
import { Calendar, momentLocalizer } from 'react-big-calendar'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import getMessagesES from '../../../helpers/getMessages';
import styled from 'styled-components';
import NormalText from '../../../components/text/NormalText';
import { Divider, TimePicker, Popconfirm } from 'antd';
import CustomModal from '../../../components/modal/CustomModal';
import UseOperator from '../../../hooks/UseOperator';
import { deleteLunchAbsence, getLunchAbsence, hoursAvailability, patchLunchAbsence, postLunchAbsence } from '../../../api/absences';
import dayjs from 'dayjs';
import { disabledHours, maxTime, minTime, stringCapitalize } from '../../../helpers/helpers';
import { selectTab } from '../../../store/tab/tabSlice';
import { useDispatch } from 'react-redux';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';

// Formats
const hourFormat = 'HH:mm';
const localizer = momentLocalizer(moment);

export default function LunchPanel({ data }) {
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [isVisibleEditForm, setIsVisibleEditForm] = useState(false);

	const [lunchAbsences, setLunchAbsences] = useState(null)
	const [technicianLunchStatus, setTechnicianLunchStatus] = useState(null)

	const [absence, setAbsdence] = useState()
	const [absenceEdit, setAbsdenceEdit] = useState()

	const currentMonth = moment().format('MM');
	const technnicianID = data?.technician?.ID
	const { storedOperatorID } = UseOperator()
	const dispatch = useDispatch();

	// const selectedTab = useSelector((state) => state?.tabs?.selectedTab);
	const goToDisponibilidad = () => dispatch(selectTab(3))

	const onModalClose = () => setIsVisibleForm(false)
	const onModalOpen = () => setIsVisibleForm(true)

	const onModalCloseEdit = () => setIsVisibleEditForm(false)
	const onModalOpenEdit = () => setIsVisibleEditForm(true)

	useEffect(() => {
		storedOperatorID && getLunchAbsence(technnicianID, storedOperatorID).then(res => setLunchAbsences(res))
		storedOperatorID && hoursAvailability(currentMonth, storedOperatorID, technnicianID, goToDisponibilidad).then(res => setTechnicianLunchStatus(res))
	}, [storedOperatorID, technnicianID])

	const handleSelectSlot = useCallback(
		(event) => {
			onModalOpen()
			const selectedDate = moment(event?.start, "ddd MMM DD YYYY HH:mm:ss").format("DD-MM-YYYY");
			const selectedStart = moment(event?.start, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");
			const selectedEnd = moment(event?.end, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");

			setAbsdence({
				"date": selectedDate,
				"start": selectedStart,
				"end": selectedEnd
			})
		},
		[]
	)

	const onStartChange = (_, timeString) => setAbsdence({ ...absence, "start": timeString })
	const onEndChange = (_, timeString) => setAbsdence({ ...absence, "end": timeString })

	const events = lunchAbsences?.map((item) => {
		return {
			title: "Hora libre",
			start: moment(item?.datetime_start, "DD-MM-YYYY HH:mm").toDate(),
			end: moment(item?.datetime_end, "DD-MM-YYYY HH:mm").toDate(),
			color: "#0060F8",
			ID: item?.ID
		}
	})

	const eventStyleGetter = (event) => {
		let style = {
			backgroundColor: "#0060F8",
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
		};
		if (event.type === 'current-date-line') {
			style = {
				...style,
				className: "custom__line",
			};
		}
		return style

	};

	const CustomHeader = ({ label, onNavigate }) => {
		return (
			<HeaderCalendar>
				<div className="left" />

				<div className="mid">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</div>

				<div className="right" />

			</HeaderCalendar>
		)
	}

	const absenceData = {
		"technician": technnicianID,
		"operator": storedOperatorID,
		"absence": absence
	}

	// --------------------------------------
	// -----------EDIT FORM------------------
	const onStartChangeEdit = (_, timeString) => setAbsdenceEdit({ ...absenceEdit, "start": timeString })
	const onEndChangeEdit = (_, timeString) => setAbsdenceEdit({ ...absenceEdit, "end": timeString })

	const handleSelectEvent = useCallback(
		(event) => {
			onModalOpenEdit()

			const selectedStart = moment(event?.start, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");
			const selectedEnd = moment(event?.end, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");

			setAbsdenceEdit({
				"start": selectedStart,
				"end": selectedEnd,
				"ID": event?.ID,
			})
		},
		[]
	)

	return (
		<Panel>
			<Container>
				<Calendar
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					eventPropGetter={eventStyleGetter}
					style={{ height: "100%" }}
					min={minTime}
					max={maxTime}
					views={["", "week", ""]}
					culture='es'
					messages={getMessagesES()}
					defaultView='week'

					components={{
						toolbar: CustomHeader,
					}}

					tooltipAccessor=""

					onSelectEvent={handleSelectEvent}
					onSelectSlot={handleSelectSlot}

					selectable={technicianLunchStatus?.status === 200 ? true : false}
					formats={{
						timeGutterFormat: "h:mm a",
						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(moment(start).format('MMM D'))} - ${stringCapitalize(moment(end).format('MMM D'))}`
						),

						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),
					}}
				/>

				{
					isVisibleForm && (
						<CustomModal>
							{/* <CardEvent onClick={onModalClose}> */}
							<CardEvent>
								<div className="card">
									<NormalText value="Confirmación" />
									<div style={{ marginBottom: 7 }}></div>

									<NormalText bold={true} value="Registro de hora de almuerzo" color="#2B80FF" />

									<div className="row space-between">
										<div className="left__picker">
											<NormalText value="Inicio" color="#131523" />
											<TimePicker
												format={hourFormat}
												inputReadOnly
												placeholder=""
												style={{ width: "100%" }}
												disabledHours={disabledHours}
												onChange={onStartChange}
												defaultValue={dayjs(absence?.start, hourFormat)}
											/>

										</div>
										<div className="right__picker">
											<NormalText size="12px" value="Fin" color="#131523" />
											<TimePicker
												format={hourFormat}
												inputReadOnly
												placeholder=""
												style={{ width: "100%" }}
												disabledHours={disabledHours}
												onChange={onEndChange}
												defaultValue={dayjs(absence?.end, hourFormat)}
											/>
										</div>
									</div>

									<Divider />

									<div className="row flex-end">
										<button className="btn_normal" onClick={onModalClose}>Cancelar</button>
										<button className="btn_silver" onClick={() => postLunchAbsence(absenceData)}>Continuar</button>
									</div>
								</div>
							</CardEvent>
						</CustomModal>
					)
				}

				{
					isVisibleEditForm && (
						<CustomModal>
							{/* <CardEvent onClick={onModalClose}> */}
							<CardEvent>
								<div className="card">

									<div className="row space-between">
										<NormalText value="Confirmación" />

										<Popconfirm
											placement="topRight"
											icon={""}
											title="¿Eliminar Pregunta?"
											description={() => {
												return (
													`Si eliminas esta pregunta, se eliminará de forma permanente de tu galería de imágenes`)
											}}
											onConfirm={() => deleteLunchAbsence(absenceEdit?.ID)}
											okText="Eliminar"
											cancelText="Cancelar"
										>
											<button className="btn_noBorder">
												<i className="fa-solid fa-trash"></i>
											</button>
										</Popconfirm>
									</div>

									<div style={{ marginBottom: 7 }}></div>
									<NormalText size="12px" bold={true} value="Edición de hora de almuerzo" color="#2B80FF" />

									<div className="row space-between">
										<div className="left__picker">
											<NormalText size="12px" value="Inicio" color="#131523" />
											<TimePicker
												format={hourFormat}
												inputReadOnly
												placeholder=""
												style={{ width: "100%" }}
												disabledHours={disabledHours}

												onChange={onStartChangeEdit}

												// absenceEdit
												defaultValue={dayjs(absenceEdit?.start, hourFormat)}
											/>

										</div>
										<div className="right__picker">
											<NormalText size="12px" value="Fin" color="#131523" />
											<TimePicker
												format={hourFormat}
												inputReadOnly
												placeholder=""
												style={{ width: "100%" }}
												disabledHours={disabledHours}
												onChange={onEndChangeEdit}

												defaultValue={dayjs(absenceEdit?.end, hourFormat)}
											/>
										</div>
									</div>

									<Divider />

									<div className="row flex-end">

										<button className="btn_normal" onClick={onModalCloseEdit}>Cancelar</button>
										<button className="btn_silver" onClick={() => patchLunchAbsence(absenceEdit?.ID, absenceEdit)}>Continuar</button>
									</div>

								</div>

							</CardEvent>
						</CustomModal>
					)
				}
			</Container>
		</Panel>
	)
}

const CardEvent = styled.div`

  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card{
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding: 20px;
    display : flex;
    flex-flow: column;
    background: white;
  }

  .row{
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
  }
  .flex-end{
    justify-content: flex-end;
  }
  .space-between{
    display: flex;
    justify-content: space-between;
  }

  .left__picker{
    width: 47%;
    background: white;
  }
  .right__picker{
    width: 47%;
    background: white;
  }
  button{
    border: none;
    outline: none;
  }

 .ant-picker-suffix {display: none}

	.btn_normal{
    background: transparent;
    font-size: ${smallSizes.fontSize.fontSize12};
    color: #5A607F;
    z-index:10;
    margin-right: 14px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;
  }

  .btn_silver{
    background: #DDE4EB;
		font-size: ${smallSizes.fontSize.fontSize12};
    color: #001737;
    z-index:10;
    border-radius: 3px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;

    :hover{
      background: #2B80FF;
      color: white;
    }
  }

  .btn_noBorder{
    border: none;
    outline: none;
    background: #F53C56;
    width: ${smallSizes.lunch.dimension};
    height: ${smallSizes.lunch.dimension};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fa-trash{
    color: white;
    margin: 0;
    padding: 0;
    font-size: 11px
  }
  
	@media screen and (min-width: ${devices.smallDesk}){

		.btn_normal{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.btn_noBorder{
			width: ${smallSizes.lunch.dimension};
			height: ${smallSizes.lunch.dimension};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){

		.btn_normal{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.btn_noBorder{
			width: ${mediumSizes.lunch.dimension};
			height: ${mediumSizes.lunch.dimension};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.input_card_ui{
			font-size: ${largeSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.btn_noBorder{
			width: ${largeSizes.lunch.dimension};
			height: ${largeSizes.lunch.dimension};
		}
	}
`

const HeaderCalendar = styled.div`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 15px;

	button{
		border: none;
		outline: none;
	}

	.left,
	.mid,
	.right{
		flex: 1;
	}
	.left{
		background: white;
	}
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mid .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size:  ${smallSizes.fontSize.fontSize18};
	}
	.mid button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	

	// ----------------------------------

	.right{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.mid button{
			font-size: ${smallSizes.fontSize.fontSize18};
		}

		.mid .label{
			font-size:  ${smallSizes.fontSize.fontSize18};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.mid button{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}

		.mid .label{
			font-size:  ${mediumSizes.fontSize.fontSize18};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.mid button{
			font-size: ${largeSizes.fontSize.fontSize18};
		}

		.mid .label{
			font-size:  ${largeSizes.fontSize.fontSize18};
		}
	}
`;
const Container = styled.div`
  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
      padding: 0 12.44px;
  }
  .rbc-event{
      background: #0060F8;
  }

.rbc-button-link {
	font-size: ${smallSizes.fontSize.fontSize15};
}

.rbc-day-slot .rbc-event-label {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12}
}

//---------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`;
