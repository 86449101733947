import { myFetchGet } from "../services/services"

async function getCategory(typeOS) {
    try {
        return await myFetchGet(`api/v1/category/?os_type=${typeOS}`)
    } catch (error) { }
}

async function getCategories() {
    try { return await myFetchGet(`api/v1/category/`) }
    catch (error) { }
}

export { getCategory, getCategories }