/* eslint-disable */
import React, { useState } from 'react'
import { Avatar, Popconfirm } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onLogout } from '../../store/auth/authSlice';
import { useRol } from '../../hooks/useRol';
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes.js";


const Header = () => {
	const [showDialog, setShowDialog] = useState(true);

	const history = useHistory();
	const dispatch = useDispatch();

	const [isVisible, setIsVisible] = useState(false)

	// extraer datos del redux
	const dataState = useSelector((state) => state?.authState)

	// nombre del rol segun dataState
	const nameRol = useRol(dataState)

	function logOut() {
		history.replace("/");
		localStorage.clear();
		setIsVisible(false)
		dispatch(onLogout())
	}

	const userDialog = () => setShowDialog(!showDialog)

	const userName = dataState?.user_detail?.name + " " + dataState?.user_detail?.last_name;

	return (
		<HeaderContainer>
			<div className="left">

				<span className='text_help'>Ayuda: contacto@multifiber.app</span>
			</div>
			<div className="right">
				<div onClick={() => setIsVisible(!isVisible)}>
					<div className='droppable_content'>
						{showDialog && (
							<div className='user_dialog'>
								<span className='etiqueta_azul'>{nameRol}</span>
								<div className="after"></div>
							</div>
						)}
						<Avatar className='header-avatar' icon={<UserOutlined />} onClick={userDialog} />
						<div className="user_name">
							<span className='text_name'>{userName}</span>
						</div>
						<DownOutlined style={{ cursor: "pointer" }} />
					</div>
				</div>
				{
					isVisible === true
						? <div className="visible_box column">
							<span className='logout-title'>Opciones de perfil</span>

							<hr style={{ width: "100%", height: "1px", background: "#E9ECEF" }} />
							<Popconfirm
								placement='leftTop'
								icon={''}
								title="¿Cerrar Sesión?"
								description={`Para salir del sistema haz click en "SÍ" o en cancelar para seguir trabajando`}
								okText='Sí'
								cancelText='Cancelar'
								onConfirm={logOut}
							>
								<span style={{ padding: "0px 24px" }}>
									<i style={{ color: "#2B80FF", marginRight: 8.4 }} className="fa-solid fa-arrow-right-from-bracket"></i>
									<span className='logout-text'>Cerrar sesión</span>
								</span>
							</Popconfirm>

						</div>
						: null
				}
			</div>
		</HeaderContainer>
	)
}

export default Header
const HeaderContainer = styled.div`

width: 100%;
height: ${smallSizes.header.heightHeader};
display: flex;
justify-content:space-between;
align-items: center;
padding: 0 3.6em 0 2.5em !important;
background: white;

.text_help{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.left{
	height:100%;
	display: flex;
	align-items: center;
}

.right{
	width: 204px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;  
}

.user_name{
	width:140px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
}

.text_name{
	font-size: ${smallSizes.fontSize.fontSize12}
}

.header-avatar {
		width: ${smallSizes.header.headerAvatar};
		height: ${smallSizes.header.headerAvatar};
		line-height: ${smallSizes.header.headerAvatar};
	}

.right .droppable_content{
	width: 204px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.right .visible_box{
	width: ${smallSizes.header.visibleBoxWidth};
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 10px 0;
	display: flex;
	background: white; 
	position: absolute;  
	bottom: ${smallSizes.header.visibleBoxBottom};
	user-select: none;
	z-index: 1000;
}

.right .visible_box h6{
	cursor: pointer;
	color: silver;
	:hover{
		color: #f1f1f1;
	}
}

.etiqueta_azul{
	cursor: pointer;
	color: white;
	font-size: ${smallSizes.fontSize.fontSize12}
}

.column{
	display: flex;
	flex-flow: column;
}

.user_dialog{
	width: ${smallSizes.header.widtDialog};
	height: ${smallSizes.header.heightDialog};
	border-radius: 5px;
	background: #0060f8;
	position: absolute;
	left: ${smallSizes.header.leftDialog};
	display: flex;
	justify-content: center;
	align-items: center;
}

.after {
	width: 10px; 
	height: 10px; 
	position: absolute;
	right: -10px;
	top: 6px;
	background: white;
	border-left: 7px solid #0060f8;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
}

.logout-title{
	font-size: ${smallSizes.fontSize.fontSize13} ;
	color: #868E96;
	padding 0px 24px;
}

.logout-text{
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #131523;
}

@media screen and (min-width: ${devices.smallDesk}){

	height: ${smallSizes.header.heightHeader};

	.text_help{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text_name{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.etiqueta_azul{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.user_dialog{
		width: ${smallSizes.header.widtDialog};
		height: ${smallSizes.header.heightDialog};
		left: ${smallSizes.header.leftDialog};
	}

	.header-avatar {
		width: ${smallSizes.header.headerAvatar};
		height: ${smallSizes.header.headerAvatar};
		line-height: ${smallSizes.header.headerAvatar};
	}

	.right .visible_box{
		width: ${smallSizes.header.visibleBoxWidth};
		bottom: ${smallSizes.header.visibleBoxBottom};
	}

	.logout-title{
		font-size: ${smallSizes.fontSize.fontSize13} ;
	}

	.logout-text{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	height: ${mediumSizes.header.heightHeader};

	.text_help{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text_name{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.etiqueta_azul{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.user_dialog{
		width: ${mediumSizes.header.widtDialog};
		height: ${mediumSizes.header.heightDialog};
		left: ${mediumSizes.header.leftDialog};
	}

	.header-avatar {
		width: ${mediumSizes.header.headerAvatar};
		height: ${mediumSizes.header.headerAvatar};
		line-height: ${mediumSizes.header.headerAvatar};
	}

	.right .visible_box{
		width: ${mediumSizes.header.visibleBoxWidth};
		bottom: ${mediumSizes.header.visibleBoxBottom};
	}

	.logout-title{
		font-size: ${mediumSizes.fontSize.fontSize13} ;
	}

	.logout-text{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	height: ${largeSizes.header.heightHeader};

	.text_help{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text_name{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.etiqueta_azul{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.user_dialog{
		width: ${largeSizes.header.widtDialog};
		height: ${largeSizes.header.heightDialog};
		left: ${largeSizes.header.leftDialog};
	}

	.header-avatar {
		width: ${largeSizes.header.headerAvatar};
		height: ${largeSizes.header.headerAvatar};
		line-height: ${largeSizes.header.headerAvatar};
	}

	.right .visible_box{
		width: ${largeSizes.header.visibleBoxWidth};
		bottom: ${largeSizes.header.visibleBoxBottom};
	}

	.logout-title{
		font-size: ${largeSizes.fontSize.fontSize13} ;
	}

	.logout-text{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
