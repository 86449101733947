/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DatePicker, Input, Pagination, Select, Table, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// api
import { myFetch, myFetchGet } from "../../services/services";
import { getDatatables_structColumns } from "../../api/osList";
import { getOs, getOstype, getTechnician } from "../../api/calendar";

// hooks & helper
import UseOperator from "../../hooks/UseOperator";
import { dateFormat, permissionCheck } from "../../helpers/helpers";
import { useFilter } from "../../hooks/useFilter";

// custom
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import NormalText from "../../components/text/NormalText";
import SubtitleBlue from "../../components/text/drawer/subtitleBlue";
import { sorterCallback } from "../../helpers/sorterCallback";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import './serviceOrdersList.scss'
import { selectTab } from "../../store/tab/tabSlice";

export default function ServiceOrdersList() {
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [loadding, setLoadding] = useState(true);
	const [COLUMNS, setTableColumns] = useState([]);
	const [ROWS, setRows] = useState([]);

	const [osList, setOsList] = useState([])
	const [typeOSList, setTypeOSList] = useState([]);
	const [technicians, setTechnicians] = useState([])
	const [dateSelect, setdateSelect] = useState(moment().format('DD/MM/YY'))

	const [orderDirection, setOrderDirection] = useState("desc");
	const { storedOperatorID } = UseOperator()
	const { Option } = Select;
	const { Search } = Input;
	const history = useHistory();
	const dispatch = useDispatch()

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const { RangePicker } = DatePicker
	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Registro de órdenes de servicio del operador.</span>
		</ContainerTooltip>
	)

	// --------
	// --------
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);
	const [columnFilterValue, setColumnFilterValue] = useState([]);

	//Filtro
	const {
		osTypeID,
		technicianID,
		osTypeHandler,
		technicianHandler,
		searchHandler,
		dateHandler,
		startDate,
		endtDate,
		searchData,
		filters,
		setRowsParams,
		rowsParams
	} = useFilter()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getOsDatatables = async () => {

		let newFilters = {
			...filters,
			'operator': storedOperatorID,
			"order_field": "ID",
			"order_type": orderDirection,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["status","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		try {
			const res = await myFetch("api/v1/os/datatables/", newFilters);
			if (res?.size) { setTotalSize(res?.size) }
			if (res?.data) {
				setRows(res?.data)
			}
		} catch (e) { }
	}

	useEffect(() => {
		storedOperatorID && getOs(storedOperatorID).then(res => setOsList(res))
		storedOperatorID && getOstype(storedOperatorID).then(res => setTypeOSList(res))
		storedOperatorID && getTechnician(storedOperatorID).then(res => setTechnicians(res))
		storedOperatorID && getDatatables_structColumns().then(res => setTableColumns(res))

	}, [storedOperatorID])





	useEffect(() => {
		if (storedOperatorID != null) {
			getOsDatatables()
			setLoadding(false)
		}
	}, [
		osTypeID,
		technicianID,
		startDate,
		endtDate,
		searchData,
		rowsParams,
		storedOperatorID,
		orderDirection,
		columnFilterValue
	])

	const handleRow = (record, _) => {
		if (rolPermission("view_os") === true) {
			return {
				onClick: () => {
					// cundo llegue al detalle, comienza en el primer Tab
					dispatch(selectTab({ selectedTab: 0 }))

					history.push("/serviceOrdersDetails", { params: record })
				},
			};
		}
		return {};
	};

	useEffect(() => {
		if (rolPermission("datatable_view_os") === false) {
			history.replace("/dashboard")
		}
	}, [])



	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm} loaddingData={loadding}>
			<ContainerHeader>
				<PanelHeader noButton={true}>
					<span className="title-list-os" >Órdenes de Servicio agendadas</span>
					<Tooltip
						title={tooltipText}
						color='white'
						arrow={false}
						placement="bottom"
						overlayInnerStyle={{
							height: "90px",
							maxHeight: '100px'

						}}
					>
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>
					<span className="sub-tex-list-os">Orden de servicio | Listado de tickets</span>
				</PanelHeader>
			</ContainerHeader>

			<div className="serviceOrdersList-mainPage-container">

				<div className="content-filter-items">
					<SubtitleBlue value="Opciones de filtrado" />
					<div className="row">
						<Select
							style={{ width: 190, marginRight: "12px" }}
							placeholder="Tipo de orden servicio"
							onChange={osTypeHandler}
							allowClear
						>
							{typeOSList?.map(item => <Option key={item?.ID} value={item?.ID}>{item?.name}</Option>)}
						</Select>


						<Select
							onChange={technicianHandler}
							style={{ width: 148, marginRight: "12px" }}
							placeholder="Técnico"
							allowClear
						>

							{technicians?.map(item =>
								<Option key={item?.ID} value={item?.ID}>{item?.name} {item.last_name}</Option>)
							}
						</Select>

						<RangePicker
							style={{ width: 190 }}
							placeholder={["Fecha Inicio", "Fecha Fin"]}
							onChange={dateHandler}
							format="DD/MM/YYYY"
							allowClear
						/>

						<div className="input-search-box-content">
							<Search
								placeholder="Realizar busqueda"
								allowClear
								onSearch={searchHandler}
								className="search"
							/>
						</div>

					</div>
				</div>

				<Table
					columns={COLUMNS}
					dataSource={ROWS}
					pagination={false}
					rowKey={(record) => record?.id_unico}
					onRow={handleRow}
					onChange={
						(pagination, filters, sorter, extra) => {
							setOrderDirection(sorterCallback(pagination, filters, sorter, extra))

							if (filters.status === null) {
								setColumnFilterValue([]);
							} else {
								setColumnFilterValue(filters.status);
							}
						}
					}
				/>

				<PaginationContainer>
					<div className="row space-between align-center">
						<span className="pagination">
							<NormalText
								bold={false}
								value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
							/>
							<NormalText
								bold={true}
								value={`total de ${totalSize} registros`}
							/>

						</span>
						<Pagination
							showSizeChanger
							defaultCurrent={paginationSettings[1]}
							current={paginationSettings[0]}
							onChange={modifyPagination}
							total={totalSize}
						/>
					</div>
				</PaginationContainer>

			</div>
		</Panel>
	);
}

const PaginationContainer = styled.div`
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
.row {
	display: flex !important;
	margin: 0 !important;
	margin-top: 5px;
}
.space-between {
	justify-content: space-between;
	margin: 0 !important;
}
.align-center {
	align-items: center;
}
.pagination {
	color: #001737;
}

.ant-pagination{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){
	
	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	
	.ant-pagination{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	
	.ant-pagination{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
	}
`;

const ContainerHeader = styled.div`
.title-list-os{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize24};
}

.circle-tooltip{
	margin: 0 10px;
	color: #00388B;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.sub-tex-list-os{
	color: #001737;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){

	.title-list-os{
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub-tex-list-os{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.title-list-os{
		font-size: ${mediumSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.sub-tex-list-os{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.title-list-os{
		font-size: ${largeSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.sub-tex-list-os{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`;
