/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Input, Select, Divider, Button, notification } from 'antd';

// api
import { postingColorData } from '../../../api/ostypeCreate';

// hooks & helpers
import UseOperator from '../../../hooks/UseOperator';
import { validarCampos } from '../../../helpers/helpers';

// custom
import Br from "../../../components/br/Br.jsx";
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../../components/text/drawer/label';
import MainTitle from '../../../components/text/drawer/mainTitle';
import BottomSection from '../../../components/form/bottomSection';
import Form from '../../../components/form/index.jsx'
import './index.scss'

export default function FormServiceOrders({ setIsVisibleForm, loginOperadorID }) {

  const onCloseModal = () => setIsVisibleForm(false)
  const { storedOperatorID } = UseOperator()

  const [showColorPicker, setShowColorPicker] = useState(false)

  const [newName, setName] = useState(null)
  const [colorValuePicked, setColorPicked] = useState(null);
  const [tag, setTag] = useState(null)

  // Svg Icon
  const D = "M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"

  const onColorPick = () => {
    setShowColorPicker(!showColorPicker)
  }
  const onColorChange = (e) => {
    setColorPicked(e)
    setShowColorPicker(!showColorPicker)
  }

  const handleNameChange = e => {
    const specialChars = /[^a-z]/gi
    const result = e.target.value.replace(specialChars, "");

    setName(result);
  };

  const data = {
    "name": newName,
    "color": colorValuePicked,
    "tag": tag,
    "operator": loginOperadorID
  }

  const onCretingType = () => {
    if (validarCampos(data)) {
      postingColorData(data)

    } else {
      notification.warning({
        style: { fontWeight: 'bold' },
        message: 'Por favor, completa todos los campos.',
        placement: 'bottomLeft',
        duration: 2.5,
      })
      return;
    }
  }

  return (

    <Form>
      <MainTitle value='Crear orden de servicio' onClick={onCloseModal} />

      <SubtitleBlue value="Datos del tipo orden de servicio" />

      <span className="column__type_order_service">
        <LabelDrawer value="Nombre" />
        <Input
          style={{ height: "40px" }}
          onChange={handleNameChange}
          placeholder="Ingrese el Nombre de la orden de servicio"
        />

        <Br />

        <span className="space-between">
          <div className="left__type_order_service">
            <div className="column__type_order_service">
              <LabelDrawer value="Color" />
              <div className="inputContainer__type_order_service">
                <div className="selected_color align-item-center">
                  {
                    colorValuePicked === "" ? (
                      <p style={{ color: "silver", fontSize: "14px" }}>Seleccionar</p>
                    ) : (
                      <>
                        <div style={{ background: colorValuePicked }} className="circle"></div>
                        <p>{colorValuePicked}</p>
                      </>
                    )
                  }
                </div>
                <div className="colorPickerIcon">
                  <svg viewBox="0 0 512 512" onClick={onColorPick}>
                    <path d={D} />
                  </svg>
                </div>
              </div>
              <div style={{ display: showColorPicker ? "block" : "none" }}>
                <input placeholder='Nombre del color' onChange={e => onColorChange(e.target.value)} type="color" className="inputColor" />
              </div>
            </div>
          </div>

          <div className="right__type_order_service">
            <div className="column__type_order_service">
              <LabelDrawer value="Etiqueta" />
              <Input
                style={{ height: "40px" }}
                className="input"
                type="text"
                onChange={(e) => setTag(e.target.value)}
                placeholder='Nombre del color'
              />
            </div>
          </div>

        </span>
      </span>


      <BottomSection onCancel={onCloseModal} onAcept={onCretingType} />

    </Form>
  )
}