/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Select, DatePicker, Input, TimePicker, notification } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

// api
import { getAbsenceChoices, onAbsenceUpdate } from '../../api/holiday';
import { getTechnician } from '../../api/calendar';

// hooks & helpers
import UseOperator from '../../hooks/UseOperator';
import { disabledHours, format, hourFormat, maxEndTime, maxTime, minStartTime, minTime, validarCampos } from '../../helpers/helpers';

// custom
import CustomModal from '../../components/modal/CustomModal';
import Br from "../../components/br/Br.jsx";
import SubtitleBlue from '../../components/text/drawer/subtitleBlue/index.jsx';
import LabelDrawer from '../../components/text/drawer/label';
import NormalText from '../../components/text/NormalText';
import MainTitle from '../../components/text/drawer/mainTitle';
import BottomSection from '../../components/form/bottomSection';
import Form from '../../components/form/index.jsx'



export default function EditAbsenceForm({ setIsVisibleForm, selectItem }) {
	const [technicians, setTechnicians] = useState([])
	const [technicianID, setTechnicianID] = useState(selectItem?.ID)
	const [technicianName, setTechnicianName] = useState(selectItem?.name)

	const [absenceType, setAbsenceType] = useState(null)
	const [absenceName, setAbsenceName] = useState(null)

	const [statusName, setStatusName] = useState(selectItem?.status)
	const [statusID, setStatusID] = useState(selectItem?.id_status)

	const [allStatus, setAllStatus] = useState(null)

	const [startTime, setStartTime] = useState(selectItem?.date_start)
	const [endTime, setEndTime] = useState(selectItem?.date_end)

	const [startHour, setStartHour] = useState(selectItem?.time_start)
	const [endHour, setEndHour] = useState(selectItem?.time_end)

	const [disableItem, setDisableItem] = useState(false)
	const [coment, setComent] = useState(null)

	const onCloseModal = () => setIsVisibleForm(false)

	const { storedOperatorID } = UseOperator()
	const { Option } = Select;
	const { TextArea } = Input;

	const absenceID = selectItem?.id_unico;

	const absenceData = {
		"status": statusID,
		"time_start": startTime,
		"time_end": endTime,

		"start": startHour,
		"end": endHour,

		"operator": storedOperatorID,
		"id_type": selectItem?.id_type,

		"comentarios": coment,
	}

	// Validar que los campos requeridos no sean nulos, cuando no tenemos la seccion de otros
	const requiredFields = ['status', 'operator', 'id_type', 'time_start', 'time_end', 'start', 'end'];
	const isValid = requiredFields.every(field => absenceData[field] !== null && absenceData[field] !== '');
	// cuando sea typo otros se validara que el comentario no este vacio.
	const shouldRenderComponent = absenceData.id_type === 4 && Object.values(absenceData).every(value => value !== null && value !== '');

	useEffect(() => {
		storedOperatorID && getTechnician(storedOperatorID).then(res => setTechnicians(res))
		storedOperatorID && getAbsenceChoices().then(res => setAllStatus(res?.status))
	}, [storedOperatorID])

	const onStartDateChange = (value) => setStartTime(value)
	const onEndDateChange = (value) => setEndTime(value)


	const onStartHourChange = (_, timeString) => setStartHour(timeString)
	const onEndHourChange = (_, timeString) => setEndHour(timeString)

	const createdAtFormat = (value) => {
		const fechaFormateada = moment(value, 'DD-MM-YYYY').format('DD MMMM YYYY');

		const fechaCapitalizada = fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1);
		return fechaCapitalizada
	}

	const [inputStatus, setInputStatus] = useState({
		input1: '',
		input2: '',
		input3: '',
		input4: '',
		input5: '',
	});

	const createAbsence = () => {

		if (
			startTime === selectItem?.date_start &&
			endTime === selectItem?.date_end &&
			statusID === selectItem?.id_status &&
			(selectItem?.id_type === 4 && coment === selectItem?.comentarios)
		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'No modificaste ningun campo',
				placement: 'bottomLeft',
				duration: 2,
				// onClose: () => window.location.reload()
			})

			return
		}

		if (
			startTime === '' ||
			endTime === '' ||
			startHour === '' ||
			endHour === '' ||
			(coment === '' && selectItem?.id_type === 4)
		) {
			setInputStatus({
				input1: selectItem?.id_type === 4 && coment === '' ? 'error' : '',
				input2: startTime === '' ? 'error' : '',
				input3: endTime === '' ? 'error' : '',
				input4: startHour === '' ? 'error' : '',
				input4: endHour === '' ? 'error' : '',
			})

			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'No puedes dejar los campos vacios',
				placement: 'bottomLeft',
				duration: 2,
			})

			return;
		}

		if ((isValid && absenceData.id_type !== 4) || shouldRenderComponent) {
			onAbsenceUpdate(absenceID, absenceData)
		}
	}
	// cuando se eingrese se valida si los campos estan habilitados o no 
	useEffect(() => {
		if (selectItem?.id_status !== 0) {
			setDisableItem(true)
		} else {
			setDisableItem(false)
		}

		setComent(selectItem?.comentarios)
	}, [])
	console.log(selectItem?.comentarios)

	const onComent = ({ target }) => {
		setComent(target.value)
	}

	console.log(startTime)
	return (
		<CustomModal onClick={onCloseModal}>
			<Form>
				<MainTitle
					value={`Solicitud de ausencia #${selectItem?.ID}`}
					onClick={onCloseModal}
				/>

				<div className="row space-between">
					<SubtitleBlue value="Datos de la solicitud" />

					<span className="row space-between">
						<NormalText value="Fecha de creación:" bold={true} />
						<NormalText value={createdAtFormat(selectItem?.created)} />
					</span>
				</div>


				<span className="column">
					<LabelDrawer value="Nombre del técnico" />
					<Input readOnly value={selectItem?.name} style={{ width: "100%", height: 40 }} disabled={disableItem} className='input_font_form_absences' />
				</span>

				<Br />

				<div className='row space-between' style={{ height: 75, gap: "12px" }}>
					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Tipo de ausencia" />
						<Input readOnly value={selectItem?.type} style={{ width: "100%", height: 40 }} disabled={disableItem} className='input_font_form_absences' />
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Status" />
						<Select
							style={{ width: "100%", height: 40 }}
							onChange={(value) => setStatusID(value)}
							placeholder={statusName}
							disabled={disableItem}
						>
							{allStatus?.map((x) => (
								<Option key={x?.ID} value={x?.ID}>{x?.name}</Option>
							))}
						</Select>
					</span>
				</div>
				<Br />

				{selectItem?.id_type === 4 &&
					<div>
						<LabelDrawer value="Comentario" />
						<TextArea
							className='input_font_form_absences'
							rows={2}
							value={coment}
							onChange={onComent}
							disabled={disableItem}
							status={inputStatus.input1}
						/>
						<Br />
					</div>}



				<SubtitleBlue value="Inicio" />
				<div className='row space-between' style={{ height: 60, gap: "12px" }}>
					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Fecha" />
						<DatePicker
							inputReadOnly
							disabled={disableItem}
							style={{ height: "40px" }}
							format={format}
							placeholder={startTime}
							onChange={(_, dateString) => onStartDateChange(dateString)}
							status={inputStatus.input2}
						/>
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Hora" />
						<TimePicker
							style={{ height: "40px" }}
							inputReadOnly
							format={hourFormat}
							disabledHours={disabledHours}
							onChange={onStartHourChange}
							defaultOpenValue={dayjs('07:00', 'HH:mm')}
							placeholder={startHour}
							status={inputStatus.input4}
							disabled={disableItem}
						/>
					</span>
				</div>

				<Br />

				<SubtitleBlue value="Fin" />
				<div className='row space-between' style={{ height: 60, gap: "12px" }}>
					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Fecha" />
						<DatePicker
							inputReadOnly
							disabled={disableItem}
							style={{ height: "40px" }}
							format={format}
							placeholder={endTime}
							onChange={(_, dateString) => onEndDateChange(dateString)}
							status={inputStatus?.input3}
						/>
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Hora" />
						<TimePicker
							style={{ height: "40px" }}
							inputReadOnly
							format={hourFormat}
							disabledHours={disabledHours}
							onChange={onEndHourChange}
							defaultOpenValue={dayjs('07:00', 'HH:mm')}
							placeholder={endHour}
							status={inputStatus?.input5}
							disabled={disableItem}
						/>
					</span>
				</div>

				<Br />

				<BottomSection onCancel={onCloseModal} onAcept={createAbsence} />
			</Form>
		</CustomModal >
	)
}

