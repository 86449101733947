import React from 'react'
import { myFetch, myFetchGet } from "../services/services";
import NormalText from '../components/text/NormalText';
import { notification } from 'antd';
import moment from 'moment';
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import DatatableRowText from '../components/text/datatable/datatableRowText';

const getAvailability = async (showTechnician, date, category, technicianID) => {
    if (date !== "" && category !== null) {
        const randomUrl = `api/v1/available_hours/?day=${date}&category=${category}`;
        const technicianUrl = `api/v1/available_hours/?day=${date}&category=${category}&technician=${technicianID}`;

        try {
            const res = await myFetchGet(showTechnician ? randomUrl : technicianUrl);
            return res?.list_of_hour_range

        } catch (e) { console.log("error", e) }
    }
}
const getSegmentedHour = async (id_disponibility, start_time, end_time, scheduling_date) => {
    try {
        const res = await myFetchGet(`api/v1/create_segmented_availability/?id_disponibility=${id_disponibility}&end_time=${end_time}&start_time=${start_time}&date=${scheduling_date}`);
        return res

    } catch (e) { console.log("error", e) }
}

// ----Table
const availability_structColumns = async () => {
    try {
        const res = await myFetchGet("api/v1/schedule/datatables_struct");
        const columnsTittles = Object.keys(res?.columns);
        const columns = [];
        columnsTittles.forEach((columnsTittle) => {
            columns.push({
                sorter: columnsTittle === "ID" ? (a, b) => a?.ID - b?.ID : null,
                width: columnsTittle === "ID"? "80px" :
									columnsTittle === "Período" ? "400px":
									columnsTittle === "Duración" ? "250px" : 
									columnsTittle === "Localizacion" ? "200px" :
									"",

                dataIndex: res?.columns[columnsTittle].field,
                key: res?.columns[columnsTittle].field,
                align: 'left',
                title: <DatatableHeaderText value={columnsTittle} />,
                render: columnsTittle === "ID"
                    ? (value) =>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <DatatableRowText value={value} />
                        </span>

                    : columnsTittle === "Período" ?
                        (value) =>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <DatatableRowText value={value} />
                            </span>

                        : columnsTittle === "Duración" ?
                            (value) =>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <DatatableRowText value={value} />
                                </span>

                            : columnsTittle === "Localizacion" ?
                                (value) =>
                                    <span style={{ display: "flex", justifyContent: "center", flexFlow: "column" }}>
                                        {value?.map(item => (
                                            <DatatableRowText
                                                value={item}
                                                color="#2B80FF"
                                                bold={true}
                                            />
                                        ))}
                                    </span>

                                : columnsTittle === "Usuario" ?
                                    (value) =>
                                        <span style={{ display: "flex", alignItems: "center" }}>
                                            <DatatableRowText value={value} />
                                        </span>

                                    : (value) =>
                                        <span style={{ display: "flex", alignItems: "center" }}>
                                            <DatatableRowText value={value} />
                                        </span>
            });
        });
        return columns;

    } catch (e) { console.log("error", e) }
}
const availabilityDataTable = async (technicianID) => {
    const filter = {
        "filters": `[[\"technician\",\"equal\",\"${technicianID}\"]]`,
        "offset": 10,
        "start": 0
    }
    
    try {
        const res = await myFetch(`api/v1/schedule/datatables/`,filter );
        const rows = res?.data?.map((row) => {
            return {
                ...row,
                key: row?.ID,
            };
        });

        return rows

    } catch (e) { }
}

const getAvailabilityDirections = async () => {
    try {
        return await myFetchGet("api/v1/direction/search");
    } catch (e) { }
}
const getBlockEvents = async (technicianID, date) => {
    try {
        const res = await myFetchGet(`api/v1/create_disponibility/?technician=${technicianID}&date=${date}`);
        const getBlocksCreated = res?.availabilities?.map((item) => {
            return {
                title: "Bloque de disponibilidad",
                start: moment(item?.datetime_start, "DD-MM-YYYY HH:mm").toDate(),
                end: moment(item?.datetime_end, "DD-MM-YYYY HH:mm").toDate(),
                ID: item?.ID
            }
        })
        return getBlocksCreated

    } catch (e) { }
}


// zone form
const getDirections = async (inputValue) => {
    if (inputValue.length === 0) {
        return
    }
    try {
        return await myFetchGet(`api/v1/direction/political_division/?generic=${inputValue}`);
    } catch (error) { }
}

const habilitationZoneCreate = async (habilitationData) => {
    try {
        const res = await myFetch("api/v1/create_disponibility/", habilitationData)
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'Se ha creado la nueva habilitación',
            placement: 'bottomLeft',
            duration: 1.5,
            // onClose: () => window.location.reload()
            onClose: () => console.log("res", res)
        })
    }
    catch (e) {
        notification.error({
            style: { fontWeight: 'bold' },
            message: 'No se ha creado la nueva habilitación',
            placement: 'bottomLeft',
            duration: 1.5,
            // onClose: () => window.location.reload()
            onClose: () => console.log("error", e)
        })
    }
}

export {
    getAvailability,
    getSegmentedHour,
    availabilityDataTable,
    availability_structColumns,
    getAvailabilityDirections,
    getDirections,
    habilitationZoneCreate,
    getBlockEvents
}