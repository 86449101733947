/* eslint-disable */
import React, { useState } from "react";
import { Panel, PanelHeader } from "../../components/panel/panel";
import { TabsPanel } from "../../components/tabs/Tabs";
import { useHistory, useLocation } from "react-router-dom";
import OrdersServicieGeneralPanel from "./general/OrdersServicieGeneralPanel.jsx";

import DisplacementPanel from "./components/DisplacementPanel";
import HistoricalPanel from "./components/HistoricalPanel";
import GalleryPanel from "./components/GalleryPanel";
import QuestionsPanel from "./questions/index.jsx";
import styled from "styled-components";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../store/tab/tabSlice";
import { permissionCheck } from "../../helpers/helpers";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";

export default function ServiceOrdersDetails() {
	const [reagendarVisible, setReagendarVisible] = useState(false)
	const location = useLocation();
	const history = useHistory();

	const { ID, fecha } = location?.state?.params;

	const dispatch = useDispatch();

	const reduxState = useSelector((state) => state);

	// Obtenemos el estado de la pestaña seleccionada desde Redux
	const selectedTab = reduxState?.tabs?.selectedTab;
	const handleTabChange = (index) => dispatch(
		selectTab({
			selectedTab: index,
			showTabs: true,
		})
	)

	const showTabsBoolean = reduxState?.tabs?.showTabs

	// const array = useSelector((state) => state?.authState?.permissions)
	const array = reduxState?.authState?.permissions;
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	let tabs = [
		{ name: "General", component: <OrdersServicieGeneralPanel data={location?.state?.params} setReagendarVisible={setReagendarVisible} /> },
		{ name: "Desplazamiento", component: <DisplacementPanel data={location?.state?.params} /> },
		{ name: "Galería", component: <GalleryPanel data={location?.state?.params} /> },
		{ name: "Histórico", component: <HistoricalPanel data={location?.state?.params} /> },
	];

	const questionTab = { name: "Preguntas", component: <QuestionsPanel data={location?.state?.params} /> };

	if (rolPermission("change_responseos") === true) {
		tabs.splice(3, 0, questionTab);
	}

	const goToList = () => history.push("/listServiceOrders")

	const createdStyles = { color: "#001737", fontWeight: "bold", fontSize: "12px", width: "100%", textAlign: "right" }

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Ficha con datos generales asociados al número de orden de servicio.</span>
		</ContainerTooltip>
	)

	return (
		<Panel>
			<ContainerHeader>
				<PanelHeader noButton={true}>
					<span className="title-os">Orden de servicio #{ID}</span>
					<Tooltip
						title={tooltipText}
						color='white'
						arrow={false}
						placement="bottom"
						overlayInnerStyle={{
							height: "95px",
							maxHeight: '100px'
						}}
					>
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>

					<div className="container-sub-text">
						<span className="sub-text-os width-os">Órdenes de servicio </span>
						<span className="sub-text-os width-list" onClick={goToList} style={{ cursor: "pointer", fontWeight: "bold", }}>| Listado de órdenes |</span>
						<span className="sub-text-os"> Detalle</span>
					</div>

					<span className="date-text-os" >Fecha de creación: <span>{fecha}</span> </span>
				</PanelHeader>
			</ContainerHeader>


			<TabsPanel selectedTabClassName="is-selected">
				{reagendarVisible === false ? (
					<span className="TabsList">

						{
							showTabsBoolean === true
								? tabs?.map((tab, index) => (
									<span
										key={index}
										className={`Tab font-size-tab ${selectedTab === index ? "is-selected" : ""}`}
										onClick={() => handleTabChange(index)}
									>{tab?.name}</span>
								))

								: null
						}


					</span>
				) : null}
				{tabs[selectedTab]?.component}
			</TabsPanel>


		</Panel>
	);
}


const ContainerHeader = styled.div`
.title-os{
	color: #001737;
	font-weight: bold;
	min-width: ${smallSizes.detailOrderHeader.widthTitle};
	font-size: ${smallSizes.fontSize.fontSize24};
}

.circle-tooltip{
	margin: 0 10px;
	color: #00388B;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.container-sub-text{
	display: flex;
	min-width: ${smallSizes.detailOrderHeader.widthSubTitle};
}

.sub-text-os{
	color: #001737;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12};
	width: 110px;
}

.date-text-os{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
	width: 100%;
	text-align: right;
}

.width-os{
	min-width: ${smallSizes.detailOrderHeader.widthSubOS}
}

.width-list{
	min-width: ${smallSizes.detailOrderHeader.widthSubList}
}

@media screen and (min-width: ${devices.smallDesk}){
	.title-os{
		font-size: ${smallSizes.fontSize.fontSize24};
		min-width: ${smallSizes.detailOrderHeader.widthTitle};
	}

	.circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	.sub-text-os{;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.date-text-os{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.container-sub-text{
		min-width: ${smallSizes.detailOrderHeader.widthSubTitle};
	}

	.width-os{
		min-width: ${smallSizes.detailOrderHeader.widthSubOS}
	}

	.width-list{
		min-width: ${smallSizes.detailOrderHeader.widthSubList}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.title-os{
		font-size: ${mediumSizes.fontSize.fontSize24};
		min-width: ${mediumSizes.detailOrderHeader.widthTitle};
	}

	.circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
	.sub-text-os{;
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.date-text-os{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.container-sub-text{
		min-width: ${mediumSizes.detailOrderHeader.widthSubTitle};
	}

	.width-os{
		min-width: ${mediumSizes.detailOrderHeader.widthSubOS}
	}

	.width-list{
		min-width: ${mediumSizes.detailOrderHeader.widthSubList}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.title-os{
		font-size: ${largeSizes.fontSize.fontSize24};
		min-width: ${largeSizes.detailOrderHeader.widthTitle};
	}

	.circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
	.sub-text-os{;
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.date-text-os{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.container-sub-text{
		min-width: ${largeSizes.detailOrderHeader.widthSubTitle};
	}

	.width-os{
		min-width: ${largeSizes.detailOrderHeader.widthSubOS}
	}

	.width-list{
		min-width: ${largeSizes.detailOrderHeader.widthSubList}
	}
}
`;
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 195px;
	}
`;