/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, DatePicker, Divider, Input, Select, Space, notification } from 'antd';
import dayjs from 'dayjs';

// api
import { onHolidayUpdate } from '../../api/holiday';

// hooks & helpers
import { format, validarCampos } from '../../helpers/helpers';

// custom
import Br from '../../components/br/Br';
import Form from '../../components/form/index.jsx';
import BottomSection from '../../components/form/bottomSection';
import MainTitle from '../../components/text/drawer/mainTitle';
import LabelDrawer from '../../components/text/drawer/label';
import SubtitleBlue from '../../components/text/drawer/subtitleBlue';


const UpdateHolidayForm = ({ setVisible, selectedRowData }) => {
	const { id_unico, date_format, name, status, holiday_type, holiday_type_ID } = selectedRowData;

	const [updatedate, setUpdatedate] = useState(date_format)
	const [updateName, setUpdateName] = useState(name)
	const [updateType, setUpdateType] = useState(holiday_type_ID)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const dateString = date_format;
		setUpdatedate(dayjs(dateString, format).format(format))
	}, [])

	const onCloseModal = () => setVisible(false)

	let holidayUpdated = {
		"day": updatedate,
		"name": updateName,
		"holiday_type": updateType,
	}

	const updateHoliday = () => {

		if (
			updatedate === date_format &&
			updateName === name &&
			updateType === holiday_type_ID

		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'No has hecho ningun cambio',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		if (validarCampos(holidayUpdated)) {
			onHolidayUpdate(id_unico, holidayUpdated)
		}
	}

	const dateHandler = (_, dateStrings) => setUpdatedate(dateStrings)

	return (
		<Form>
			<MainTitle value="Actualizar Registro de día feriado" onClick={onCloseModal} />

			<SubtitleBlue value='Información de la festividad' />

			<Br />

			<div className="column">
				<LabelDrawer value='Fecha' />
				<DatePicker
					placeholder={updatedate}
					className="select"
					onChange={dateHandler}
					inputReadOnly
					format={format}
				/>
			</div>

			<Br />

			<div className="column">
				<LabelDrawer value='Nombre' />
				<Input

					placeholder={name}
					onChange={e => setUpdateName(e.target.value)}
					className='select input_font_12_holidays'
				/>
			</div>

			<Br />

			{loading === false ? (
				<div className="column">
					<LabelDrawer value='Tipo' />
					<Select
						onChange={value => setUpdateType(value)}
						placeholder={holiday_type}
					>
						<Select.Option key={0} value={0}>Religioso</Select.Option>
						<Select.Option key={1} value={1}>Civil</Select.Option>
					</Select>
				</div>
			) : null}

			<Br />

			<BottomSection onCancel={onCloseModal} onAcept={updateHoliday} />
		</Form>
	)

}

export default UpdateHolidayForm