/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Panel } from "../../../components/panel/panel";
import { getCategoriesColumn, getCategoriesRow } from "../../../api/ostypeCreate";
import { Table } from "antd";
import EditCategoryForm from "./EditCategoryForm";
import { permissionCheck } from "../../../helpers/helpers";
import { useSelector } from "react-redux";
import AddCategoryForm from "../newCategory/index.jsx";
import './panelCategories.scss'
import SingleButton from "../../../components/buttons/singleButton";
import LabelDrawer from "../../../components/text/drawer/label";

export default function PanelCategories({ data }) {

	const os_type = data?.id_unico;

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const [addCategoryForm, setAddCategoryForm] = useState(false)
	const [editCategoryForm, setEditCategoryForm] = useState(false)

	const [editableData, setEditableData] = useState(null)
	const [loadding, setLoadding] = useState(true);

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setROWS] = useState([]);

	useEffect(() => {
		getCategoriesColumn().then(res => setCOLUMNS(res))
		getCategoriesRow(os_type).then(res => {
			setROWS(res)
			setLoadding(false)
		})
	}, []);

	const addCategory = () => setAddCategoryForm(!addCategoryForm)

	return (
		<Panel>
			<div className="typeos-category-mainPage-container">
				<span className="typeos-top-content">
					<LabelDrawer
						value="Listado de categorías registradas asociadas al tipo de orden de servicio."
						required={false}
					/>
					{
						rolPermission("add_category") === true ? (
							<SingleButton
								onClick={addCategory}
								value='Agregar categoría'
								iconClassName="fa-solid fa-plus"
							/>
						) : null
					}
				</span>
				<Table
					columns={COLUMNS}
					dataSource={ROWS}
					pagination={false}
					onRow={(record, rowIndex) => {
						return {
							onClick: (event) => {
								setEditableData(record, rowIndex);
								setEditCategoryForm(rolPermission("add_category") === true ? true : false)
							},
						};
					}}

				/>

				{addCategoryForm && <AddCategoryForm os_type={os_type} addCategoryForm={addCategoryForm} setAddCategoryForm={setAddCategoryForm} recallApi={getCategoriesRow} />}
				{editCategoryForm && <EditCategoryForm editableData={editableData} os_type={os_type} editCategoryForm={editCategoryForm} setEditCategoryForm={setEditCategoryForm} />}
			</div>
		</Panel >
	);
}