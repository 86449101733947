/* eslint-disable */
import React, { useEffect, useState } from 'react'
import NormalText from '../../../components/text/NormalText'
import styled from 'styled-components'
import { Select, Popconfirm, notification, Table } from 'antd'
import { getTechniciansCategoryDatatable, postTechnicianTypeCategory, techniciansCategory_struct } from '../../../api/technician'
import { getCategory } from '../../../api/category'
import { getOstype } from '../../../api/calendar'
import CategoryEditForm from './CategoryEditForm'
import UseOperator from '../../../hooks/UseOperator'
import { myFetch } from '../../../services/services'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../helpers/helpers'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'

export default function TypesServicesOrdensPanel({ data }) {

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const [ROWS, setROWS] = useState(null)
	const [COLUMNS, setCOLUMNS] = useState(null)
	const [selectItem, setSelectItem] = useState(null)
	const [visibleEditForm, setVisibleEditForm] = useState(false)

	const technicianID = data?.technician?.ID

	const [categories, setCategories] = useState(null)
	const [categorySelected, setCategorySelected] = useState(null)

	const [typeOS, setTypeOS] = useState(null)
	const [typeosSelected, setTypeosSelected] = useState(null)

	const { storedOperatorID } = UseOperator()
	const [typeOsError, setTypeOsError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);

	const onTypeosChange = (value) => setTypeosSelected(value)
	const onCategoryChange = (value) => setCategorySelected(value)

	const createData = {
		'technician': technicianID,
		'option': 'agregar', // eliminar - modificar - agregar
		'ostype': typeosSelected,
		'category': categorySelected,
	}

	// -----------EDIT------------
	const onEditForm = (value) => {
		setVisibleEditForm(true)
		setSelectItem(value)
	}

	useEffect(() => {
		technicianID && getTechniciansCategoryDatatable(technicianID).then(res => setROWS(res))
		technicianID && techniciansCategory_struct(renderOptions).then(res => setCOLUMNS(res))
		storedOperatorID && getOstype(storedOperatorID).then(res => setTypeOS(res))
	}, [technicianID, storedOperatorID])

	useEffect(() => {
		typeosSelected && getCategory(typeosSelected).then(res => setCategories(res))
	}, [typeosSelected])

	// -----------DELETE------------
	const onDelete = async (record) => {
		const deleteData = {
			"ostype": record?.os_type?.id,
			"option": "eliminar",
			"technician": technicianID,
		}
		try {
			await myFetch("api/v1/modify_category_technician/", deleteData)
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Registro eliminado con éxito',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => window.location.reload()
			});
		} catch (error) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'No se pudo eliminar el registro',
				placement: 'bottomLeft',
				duration: 1.5,
				// onClose: () => window.location.reload()
				onClose: () => console.log("error", error)
			});
		}
	}

	// ------------------------------------------------------
	// ------------------------------------------------------
	const renderOptions = (_, record) => {
		return (
			<ContainerButton>
				<button
					onClick={() => onEditForm(record)}
					className="btn-datatable-displacement"
					style={{marginRight: "15px"}}
				>
					Modificar
				</button>
				<Popconfirm
					placement="topRight"
					icon={null}
					// title="¿Eliminar registro?"
					title={<span className="my__Popconfirm__color__text__message">¿Eliminar registro?</span>}

					description={() => {
						return (
							<span className='my__Popconfirm__color__text__message'>
								Si eliminas este registro, se eliminará de forma permanente de tu historial
							</span>
						);
					}}
					onConfirm={(e) => {onDelete(record)}}
					onCancel={(e) => { e.stopPropagation() }}
					okText="Eliminar"
					cancelText="Cancelar"
				>
					<button
						onClick={(e) => e.stopPropagation()}
						className="btn-datatable-displacement"
					>
						Eliminar
					</button>
				</Popconfirm>
			</ContainerButton>
		);
	};

	// ------------------------------------------------------
	// ------------------------------------------------------

	return (
		<Container>
			<div style={{ paddingLeft: "20.65px", marginBottom: 7, paddingTop: "35.43px" }}>
				<NormalText color="#2B80FF" value="Tipos de orden servicio" bold={true} />
			</div>

			<div className="custom__Table__row__top" style={{ gap: ".7em" }}>
				<Select
					style={{ height: 40, flex: 1 }}
					placeholder="Órdenes de servicio que realiza"
					onChange={onTypeosChange}
					status={typeOsError ? 'error' : ''}
				>
					{typeOS?.map(item =>
						<Select.Option
							key={item?.ID}
							value={item?.ID}>
							{item?.name}
						</Select.Option>)
					}
				</Select>

				<Select
					style={{ height: 40, flex: 1 }}
					placeholder="Categorías"
					mode="multiple"
					onChange={onCategoryChange}
					status={categoryError ? 'error' : ''}
				>
					{categories?.map(item =>
						<Select.Option
							key={item?.ID}
							value={item?.ID}>
							{item?.name}
						</Select.Option>)}
				</Select>

				<button
					className="btn"
					onClick={() => {
						if (
							createData.ostype !== null &&
							createData.category !== null
						) {
							postTechnicianTypeCategory(createData);
						} else {
							setTypeOsError(true);
							setCategoryError(true);
							// Mostrar mensaje de error o tomar alguna acción cuando hay campos nulos
							notification.warning({
								style: { fontWeight: 'bold' },
								message: 'Todos los campos son obligatorios',
								placement: 'bottomLeft',
								duration: 1.5,
								onClose: () => {
									setTypeOsError(false);
									setCategoryError(false);
								}
							});

							return;
						}
					}}
				>
					Agregar
				</button>
			</div>



			<div style={{ marginBottom: 30 }}></div>

			<Table
				columns={COLUMNS}
				dataSource={ROWS}
			/>

			{
				visibleEditForm &&
				<CategoryEditForm
					selectItem={selectItem}
					technicianID={technicianID}
					setVisibleEditForm={setVisibleEditForm}
					categories={categories}
					typeOS={typeOS}
				/>
			}
		</Container>
	)
}

const Container = styled.div`
  display: flex;
  flex-flow: column;

  .row{
    display: flex;
    height: 40px;
    gap: 6px;
    margin-bottom: 20px;
    padding: 0 1em;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
  }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-height: 40px;
    cursor: pointer;
}
  
  .btn{
    border: none;
    outline: none;
    border: 1px solid #0060FF;
    border-radius: 3px;
    color: #2B80FF;
    font-size: ${smallSizes.fontSize.fontSize12};
    background: #DDE4EB;
    height: ${smallSizes.tipoOS.heightButton};
    width: ${smallSizes.tipoOS.widthButton};
  }
// -------------------------
  // ------custom table-------

.custom__Table{
  height: 40px;
  display: flex;
  align-items: center;
  background:  #F5F6FA;
  font-weight: 400;
  font-size: ${smallSizes.fontSize.fontSize12};
  color: #8898AA;
  padding-left: 25px;
}
.custom__Table .icon__box{
  width: 40px;
  height: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.custom__Table__row{
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 26px;


  // 	primer fila
  :nth-child(odd) {background: white}

  // 	Siguiente fila
  :nth-child(even) {background: #F7FAFC}
}
.custom__Table__row__top{
  display: flex;
  align-items: center;
  padding-left: 20.65px;
  padding-right: 31.94px;
  background: white;
}

.type_os{
    flex: 1;
    display: flex;
    align-items: center;
}
.type_os__row{
    flex: 1;

}
.cetegory{
    flex: 1;
    // padding-left: 16px;
}
.cetegory__row{
    flex: 1;
    padding-left: 14px;
}

.operation{
    width: 250px; 
    cursor: pointer;
    padding-left: 16px;
}

.operation .btnStyle{
    border: none;
    outline: none;
    color: #2B80FF;
    font-size: ${smallSizes.fontSize.fontSize12};
    font-weight: bold;
    background: transparent;
  }

	.name_row{
		font-weight: bold;
		color: #131523;
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.ant-select-selector {
    width: 100%;
    height: auto !important;
	}

	.ant-select-multiple .ant-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
	}

	.ant-select-multiple .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
	}

	.ant-select-dropdown .ant-select-item-option {
    display: flex;
    flex-direction: row;
	}

	//----------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
  .btn{
    font-size: ${smallSizes.fontSize.fontSize12};
    height: ${smallSizes.tipoOS.heightButton};
    width: ${smallSizes.tipoOS.widthButton};
  }

.custom__Table{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${smallSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.mediumDesk}){
  .btn{
    font-size: ${mediumSizes.fontSize.fontSize12};
    height: ${mediumSizes.tipoOS.heightButton};
    width: ${mediumSizes.tipoOS.widthButton};
  }

.custom__Table{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${mediumSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.largeDesk}){
  .btn{
    font-size: ${largeSizes.fontSize.fontSize12};
    height: ${largeSizes.tipoOS.heightButton};
    width: ${largeSizes.tipoOS.widthButton};
  }

	.custom__Table{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${largeSizes.fontSize.fontSize12};
  }
}
`
const ContainerButton = styled.div`
.btn-datatable-displacement{
	color: #2B80FF;
	background: transparent;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-datatable-displacement{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-datatable-displacement{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.btn-datatable-displacement{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
