import styled from 'styled-components';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';

export const TabsPanel = styled.div`
  background: white;
  .TabsList{
    width: 100%;
    display: flex;
    flex:1;
    background: white;
		padding: 28.63px 0 10px 30.25px !important;
    
    @media (max-width: 800px){
      width: 100%;
    }
  }

  .Tab{
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none; 
    color: #007AFF;
    cursor: pointer;
    font-weight: 400;
    padding: 10px 15px;

    &.is-selected{
      color:  #000;
      background: #DDE4EB;
      border: none;
      outline: none;
      border-radius: 3px;
    }
  }

	.font-size-tab{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.font-size-tab{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.font-size-tab{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.font-size-tab{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;