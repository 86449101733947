
import React, { useEffect, useState } from "react";

import { Input, Space, Switch, Popconfirm, DatePicker, Select, notification, Checkbox } from "antd";
import styled from "styled-components";
import { getAvalaibleTechnicians } from "../../../api/calendar";
import Br from "../../../components/br/Br";
import { useHistory } from "react-router-dom";
import '../../../css/Popconfirm.css';
import { getID_type, getTechnicianStatus, onUpdateTecnician } from "../../../api/technician";
import UseOperator from "../../../hooks/UseOperator";
import { useSelector } from "react-redux";
import Row from "../../../components/row";
import LabelDrawer from "../../../components/text/drawer/label";
import SubtitleBlue from "../../../components/text/drawer/subtitleBlue";
import { disabledAfterDate, format, permissionCheck } from "../../../helpers/helpers";

import ReactSelect from 'react-select';
import { validate } from "rut.js";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../helpers/sizes";
import dayjs from "dayjs";

export default function GeneralPanel({ data }) {
	const technician = data?.technician
	const technicianID = data?.technician?.ID

	const device = data?.technician?.dispotivo;
	const deviceVersion = data?.technician?.version;

	const [inputs, setInputs] = useState({
		name: data?.name,
		last_name: technician?.last_name,
		identity_number: technician?.id_number,
		phone: technician?.phone,
		email: data?.user,
		password: data?.password,
	});


	const [identity_type, setIdentity_type] = useState(technician?.id_type)
	const onIdentity_typeChange = (value) => setIdentity_type(value)

	const [newBirthday, setNewBirthday] = useState(technician?.birthday)
	const onBirthDay = (_, datestring) => setNewBirthday(datestring)

	const { storedOperatorID, operators, countryID } = UseOperator();
	const operatorSelected = operators?.filter(op => op?.ID === storedOperatorID)

	const [modify, setModify] = useState(false)

	const [technicians, setTechnicians] = useState([])
	const [showRol, setShowRol] = useState(data?.role === "Supervisor" ? true : false)

	const [switchdefaultChecked, setSwitchdefaultChecked] = useState(data?.role === "Supervisor" ? true : false)

	const [allStatus, setAllStatus] = useState(null)
	const [status, setStatus] = useState(technician?.status)

	const [role, setRol] = useState(data?.role)

	const [scheduleDay, setScheduleDay] = useState(technician?.schedule_same_day)

	const history = useHistory();

	const newStatus = useSelector((state) => state?.authState)
	const array = newStatus?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	useEffect(() => {
		countryID && getID_type(Number(countryID)).then(res => setIdTypes(res))
		setRol(newStatus?.rol)
	}, [storedOperatorID])
	const supervees = data?.supervisados

	const { Option } = Select;
	const mySupervisor = data?.jefe[0]


	const [isChecked, setIsChecked] = useState(switchdefaultChecked)

	const onCheck = (value) => {
		setShowRol(!showRol)
		setIsChecked(value)
	}

	const modifyData = () => {
		setModify(!modify)

		//reiniciar el Switch si se vuelve a presionar
		onCheck(switchdefaultChecked);

		//reiniciar todos los campos si se vuelve a presionar
		returnToInitialFormState()
	}

	const onCancelRequest = () => {
		setModify(false)
		onCheck(switchdefaultChecked);

		// reiniciar, cuando cierre con el toggle button modificar
		returnToInitialFormState()
	}

	const returnToInitialFormState = () => {
		setInputs({
			name: data?.name, //(ok)
			last_name: technician?.last_name, //(ok)
			phone: technician?.phone, //(ok)
			email: data?.user, //(ok)
			password: data?.password, //(ok)
		});

		setNewBirthday(technician?.birthday)
		setIdNumber(technician?.id_number);
		setStatus(technician?.status)
		setSelectedOptions(supervisadosForSelect);
		setIdentity_type(technician?.id_type)

		// si es Supervisor mostrar el Select de tecnicos:
		setShowRol(data?.role === "Supervisor" ? true : false)
		setSwitchdefaultChecked(data?.role === "Supervisor" ? true : false)

	}

	// -------------
	// -------------

	const goToList = () => history.push("/technicians")

	useEffect(() => {
		storedOperatorID && getAvalaibleTechnicians(storedOperatorID)
			.then(res => setTechnicians(res))
		getTechnicianStatus().then(res => setAllStatus(res))
	}, [storedOperatorID])

	// ----------------------------------
	// ----------------------------------
	const [idTypes, setIdTypes] = useState([])

	const [idNumber, setIdNumber] = useState(technician?.id_number);

	const [idNumberError, setIdNumberError] = useState(null);
	const [prefixes, setPrefixes] = useState('')

	// const ISO = "CL"
	const ISO = idTypes?.iso

	// Nombre del tipo de documento
	const typeDocName = technician?.id_type_name;

	// ISO desde el operador
	const operatorISO = operatorSelected[0]?.country_iso

	const isoFlag = ISO?.toLowerCase()

	useEffect(() => {
		if (idTypes?.iso === "PE") {
			setPrefixes("+51")
		} else if (idTypes?.iso === "CL") {
			setPrefixes("+56")
		} else if (idTypes?.iso === "CO") {
			setPrefixes("+57")
		} else if (idTypes?.iso === "VE") {
			setPrefixes("+58")
		}
	}, [idTypes?.iso])

	const onIdentificationNumber = (e) => setIdNumber(e?.target?.value)

	const regexFormats = {
		"CL-Pass": /^[A-Z]{2}[0-9]{7}$/,	// 'pasaporte debe contener 2 MAYUSCULAS + 7 numeros sin puntos

		"PE-DNI": /^[0-9]{8}$/, // DNI de peru debe llevar 8 digitos
		"PE-CE": /^[0-9]{8}$/, // CE de peru debe llevar 8 digitos
		"PE-Pass": /^[A-Z]{2}\d{7}$/, // Pasaporte de peru debe llevar 2letra MAYUSCULA + 7 numeros
		"PE-RUT": /^[0-9]{11}$/, // RUT de peru debe llevar 10 digitos

		"default": /^\d{9}$ ///default
	}

	useEffect(() => {
		switch (operatorISO) {
			case 'CL':
				//  id 6 = RUT     ID 7 = Pass
				if (identity_type === 6) {
					const validationResult = validate(idNumber, { format: false })
					validationResult === false ? setIdNumberError("Formato de RUT incorrecto") : setIdNumberError(null)

				}
				if (identity_type === 7) {
					regexFormats["CL-Pass"].test(idNumber) === false ? setIdNumberError("Formato de Pasaporte incorrecto, ej: AA1234567") : setIdNumberError(null)
				}

			case 'PE':
				if (identity_type === 1) { regexFormats["PE-DNI"].test(idNumber) === false ? setIdNumberError("Formato de DNI incorrecto, ej: 12345678") : setIdNumberError(null) }
				if (identity_type === 2) { regexFormats["PE-CE"].test(idNumber) === false ? setIdNumberError("Formato de Cedula incorrecto, ej: 12345678") : setIdNumberError(null) }
				if (identity_type === 3) { regexFormats["PE-Pass"].test(idNumber) === false ? setIdNumberError("Formato de Pasaporte incorrecto, ej: AB1234567") : setIdNumberError(null) }
				if (identity_type === 4) { regexFormats["PE-RUT"].test(idNumber) === false ? setIdNumberError("Formato de RUT incorrecto, ej: 12345678901") : setIdNumberError(null) }
		}
	}, [idNumber, identity_type, operatorISO])


	// ----------------------------------
	// ----------------------------------

	const handleInputChange = (event) => {
		setInputs({ ...inputs, [event?.target?.name]: event?.target?.value });
	};

	const statusOnChange = (value) => setStatus(value)

	const customStyles = {
		multiValue: (base) => ({
			...base,
			backgroundColor: "white",
			color: '#5A607F',
			border: '1px solid #5A607F',
			borderRadius: "2px",
			fontSize: '12px'
		}),
		multiValueLabel: (base) => ({ ...base, color: '#5A607F' }),
		multiValueRemove: (base, state) => ({
			...base,
			backgroundColor: 'white',
			color: '#5A607F',
			':hover': { backgroundColor: "white" },
		}),
		control: (base, state) => ({
			...base,
			borderColor: state.isFocused ? '#4096ff' : '#d9d9d9', // Cambia el color del borde en foco
			boxShadow: state.isFocused ? 'none' : base.boxShadow, // Cambia el color del sombreado en foco
		}),
	};


	const techniciansForSelect = technicians?.map(item => {
		return {
			value: item?.ID,
			label: item?.name + " " + item?.last_name
		}
	})

	const supervisadosForSelect = supervees?.map(item => {
		return {
			value: item?.ID,
			label: item?.name + " " + item?.last_name
		}
	})

	const combinedTechnicians = techniciansForSelect?.concat(supervisadosForSelect)

	const [selectedOptions, setSelectedOptions] = useState(supervisadosForSelect);
	const handleSelectChange = (selected) => setSelectedOptions(selected);
	const techsID = selectedOptions?.map((option) => option?.value);

	const [inputStatus, setInputStatus] = useState({
		input1: '',
		input2: '',
		input3: '',
		input4: '',
		input5: '',
	});

	let updateData = {
		"birthday": newBirthday,
		"id_type": identity_type,
		"id_number": idNumber,
		"name": inputs?.name,
		"last_name": inputs?.last_name,
		"status": status,
		"phone": inputs?.phone,
		"operator": storedOperatorID,
		"is_supervisor": showRol,
		"supervisados": techsID,
		"email": inputs?.email,
	}

	if (inputs?.password !== "") {
		updateData["password"] = inputs.password
	}


	const sendingUpdate = () => {

		if (
			inputs?.name === "" ||
			inputs?.last_name === "" ||
			identity_type === "" ||
			idNumber === "" ||
			inputs?.phone === "" ||
			inputs?.email === ""
		) {
			setInputStatus({
				input1: inputs?.name === '' ? 'error' : '',
				input2: inputs?.last_name === '' ? 'error' : '',
				input3: identity_type === '' ? 'error' : '',
				input4: idNumber === '' ? 'error' : '',
				input5: inputs?.phone === '' ? 'error' : '',
				input6: inputs?.email === '' ? 'error' : '',
			});

			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Completa todos los campos",
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return
		}

		onUpdateTecnician(technicianID, updateData, () => goToList())
		setInputStatus({ input1: '', input2: '', input3: '', input4: '', input5: '', input6: '' });
	}

	const handlerCheckbox = (obj) => {
		console.log(obj.target.checked)
		setScheduleDay(obj.target.checked)
	}

	return (
		<Container>

			<div className="button_change_technician">
				{
					rolPermission("change_technician") === true ? (
						<button
							onClick={modifyData}
							className="btn">
							<i className="fa-solid fa-pen-to-square"></i>
							<span>Modificar</span>
						</button>
					) : null
				}

			</div>

			<div className="display_flex">
				<div className="column_left">
					<SubtitleBlue value="Datos personales" />

					<div className="container_user_name">
						<Row width="32.6%">
							<LabelDrawer value="Nombre" />
							<Input
								className="input_detail_technicials input_size"
								disabled={modify === true ? false : true}
								value={inputs?.name}
								name="name"
								onChange={handleInputChange}
								status={inputStatus?.input1}
							/>
						</Row>

						<Row width="32.6%">
							<LabelDrawer value="Apellido" />
							<Input
								className="input_detail_technicials input_size"
								disabled={modify === true ? false : true}
								value={inputs?.last_name}
								name="last_name"
								onChange={handleInputChange}
								status={inputStatus?.input2}
							/>
						</Row>
					</div>

					<div className="container_row_detail">
						<Row width="32.6%">
							<LabelDrawer value="Documento de identidad" />
							<div className="subcontainer_row">
								<Select
									className="input_select"
									onChange={onIdentity_typeChange}
									value={typeDocName}
									placeholder={typeDocName}
									disabled={modify === false ? true : false}
									status={inputStatus?.input3}
								>
									{idTypes?.data?.map(item =>
										<Select.Option key={item?.ID} value={item?.ID}>
											{item?.name}
										</Select.Option>)
									}
								</Select>

								<Input
									className="input_detail_technicials"
									id="idNumber"
									onChange={onIdentificationNumber}
									maxLength={12}
									style={{ height: 40 }}
									value={idNumber}
									disabled={modify === false ? true : false}
									status={inputStatus?.input4}
								/>

							</div>
						</Row>

						<Row width="32.6%">
							<LabelDrawer value="Fecha de nacimiento" />
							<DatePicker
								format={format}
								onChange={onBirthDay}
								disabled={modify === true ? false : true}
								style={{ width: "150%" }}
								inputReadOnly
								disabledDate={disabledAfterDate}
								value={dayjs(newBirthday, format)}
							/>
						</Row>
					</div>

					<div className="container_row_detail">
						<Row width="32.6%">
							<LabelDrawer value="Número de teléfono" />
							<div className="subcontainer_row">
								<span className="phoneFlagsSelect">
									{
										isoFlag && (
											<img
												src={`https://flagcdn.com/20x15/${isoFlag}.png`}
												alt=""
												width="15px"
												height="15px"
											/>
										)
									}

									{
										prefixes && (
											<span className="input_detail_technicials" style={{ color: "#868D96", marginLeft: 7 }}>
												{prefixes}
											</span>
										)
									}
								</span>

								<Input
									className="input_detail_technicials"
									name="phone"
									value={inputs?.phone}
									onChange={handleInputChange}
									disabled={modify === true ? false : true}
									style={{ width: "100%" }}
									status={inputStatus?.input5}
								/>
							</div>
						</Row>

						<Row width="32.6%">
							<LabelDrawer value="Status" />
							<Select
								className="input_size"
								value={status}
								onChange={statusOnChange}
								disabled={modify === true ? false : true}
							>
								{allStatus?.map(item =>
									<Option key={item?.ID} value={item?.ID}>
										{item?.name}
									</Option>)}
							</Select>
						</Row>
					</div>

					<Br />

					{
						modify === true ? (
							<div className="row row-align-center">
								<b className="input_detail_technicials" style={{ marginRight: 50 }}>Asignar Rol Supervisor</b>

								<div className="row">
									<span className='switch_box'>
										<b className=" input_detail_technicials">No</b>
										<Switch
											style={{ margin: "0 5px" }}
											onChange={onCheck}
											checked={isChecked}
											defaultChecked={switchdefaultChecked}
											disabled={modify === true ? false : true}
										/>
										<b className="input_detail_technicials">Si</b>
									</span>
								</div>
							</div>
						) : (
							<div className="row row-align-center">
								<b className="input_detail_technicials" style={{ marginRight: 50 }}>Asignar Rol Supervisor</b>

								<div className="row">
									<span className='switch_box'>
										<b className="input_detail_technicials">No</b>
										<Switch
											style={{ margin: "0 5px" }}
											onChange={onCheck}
											checked={isChecked}
											// defaultChecked={data?.role === "Supervisor" ? true : false}
											disabled={modify === true ? false : true}
										/>
										<b className="input_detail_technicials">Si</b>
									</span>
								</div>
							</div>
						)
					}

					<Br />

					{
						showRol === true
							? (
								<>
									<LabelDrawer value="Técnicos" />
									<ReactSelect
										options={combinedTechnicians}
										isMulti
										value={selectedOptions}
										onChange={handleSelectChange}
										styles={customStyles}
										placeholder="Selecciona un técnico"
										isDisabled={modify === true ? false : true}
										className="input_detail_technicials"
									/>
								</>
							) : data?.role === "Técnico"
								?
								<div className="center">
									<b className="input_detail_technicials" style={{ color: "#131523", marginRight: 12 }}>Supervisor asignado</b>
									{
										mySupervisor !== undefined
											? <span className="input_detail_technicials" style={{ border: "1px solid #5A607F", borderRadius: "2px", color: "#5A607F", padding: "2px 12px" }}>
												{mySupervisor?.name} {mySupervisor?.last_name}</span>
											: <label className="input_detail_technicials" style={{ margin: '0px' }} >Sin supervisor</label>
									}
								</div>
								: null
					}

					<Br />
					<div>
						<SubtitleBlue value="Importante" />
						<div style={{ display: 'flex', alignItems: "center" }}>
							<Checkbox
								disabled={modify === true ? false : true}
								onChange={handlerCheckbox}
								checked={scheduleDay}
							/>
							<p className="input_detail_technicials text_permission">Permitir agendar en el mismo día en que se generen o se reagenden ordenes de servicio.</p>
						</div>

					</div>
				</div>

				<div className="column_right">
					<span>
						<SubtitleBlue value="Credenciales aplicación movil" />
					</span>
					<div>
						<Row width="24%">
							<LabelDrawer value="Correo electrónico" />
							<Input
								className="input_detail_technicials"
								name="email"
								value={inputs?.email}
								onChange={handleInputChange}
								disabled={modify === true ? false : true}
								style={{ height: 40 }}
							// status={inputStatus?.input6}
							/>
						</Row>

						<Br />

						<Row width="24%">
							<LabelDrawer value="Contraseña" />
							<Input
								className="input_detail_technicials"
								name="password"
								value={inputs?.password}
								onChange={handleInputChange}
								disabled={modify === true ? false : true}
								style={{ height: 40 }}
							/>
						</Row>

						<Br />
						<SubtitleBlue value="Información importante " />
						<div>
							<p className="font_10_detail">Informacion relacionada a los medios de acceso a la aplicacion del técnico</p>
						</div>

						<Br />

						<div className="container_device_version">
							<Row width="24%">
								<div className="column">
									<span className="title_device_version">Dispositivo</span>
									{device && <span className="description_device_version">{device}</span>}
								</div>
							</Row>

							<Row width="24%">
								<span className="column">
									<span className="title_device_version">Versión</span>
									{deviceVersion && <span className="description_device_version">{deviceVersion}</span>}
								</span>
							</Row>
						</div>
					</div>
				</div>
			</div>

			<Br />

			<div className="row row-end">
				<Space>
					{modify !== false ? (
						<>
							<button
								className='btn_cancelar_question'
								onClick={onCancelRequest}

							>Cancelar</button>

							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Modificar información?"
								description={() => {
									return (
										`Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones.`)
								}}
								onConfirm={() => sendingUpdate()}
								onCancel={(e) => {
									console.log('Cancelar');
								}}
								okText="Guardar"
								cancelText="Cancelar">
								<button className='btn_guardar_question'>Guardar</button>
							</Popconfirm>
						</>
					) : null}
				</Space>
			</div>

			<Br />
		</Container>
	);
}

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-flow: column;
	box-sizing: border-box !important;
  padding: 25px 43.81px  25px 20.19px;

	.row{
		display: flex;
		align-items: flex-end;
	}
	.center{
		display: flex;
		align-items: center;
	}
	.column{
		display: flex;
		flex-flow: column;
		justify-content: center;
		min-height: 40px;
	}
	
	.row-between{
		display: flex;
		justify-content: space-between;
		flex-flow: row !important;
		gap: 6.56px;
	}
	.row-center{
		justify-content: center;
	}
	.row-end{
		justify-content: flex-end;
	}
	.align-center{
		align-items: center;
	}
	.row-align-center{
		align-items: center;
	}

	.btn{
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		outline: none;
		height: ${smallSizes.detailGeneral.heightButton};
		width: ${smallSizes.detailGeneral.widthButton};
		border: 1px solid #0060FF;
		border-radius: 3px;
		background: #DDE4EB;
		font-size: ${smallSizes.fontSize.fontSize12};

		.fa-pen-to-square{
			color: #2B80FF;
			margin-right: 6.04px;
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}
	
	.phoneFlagsSelect{
		width: 70px;
		height: 40px;
		border-radius: 5px;
		border: 1px solid #E0E0E0;
		display: flex;
		align-items: center;
		padding: 1em;
		margin-right: 4%;

	}
	
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	height: 40px;
	padding: 0 11px;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px;
}

.switch_box{
	display: flex;
	align-items: end;
	height: 40px;
}

.device_detail{
	color: #868D96;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.input_detail_technicials{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.btn_cancelar_question {
	background: transparent;
	width: ${smallSizes.detailGeneralTechnicials.widthButton};
	height: ${smallSizes.detailGeneralTechnicials.heightButton};
	border: 1px solid #BFCCDA;
	border-radius: 3px;
	background: white;
	color: #001737;
	font-size: ${smallSizes.fontSize.fontSize12};

	:hover{
		background: silver;
	}
}

.btn_guardar_question {
	color: #001737;
	width: ${smallSizes.detailGeneralTechnicials.widthButton};
	height: ${smallSizes.detailGeneralTechnicials.heightButton};
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	font-size: ${smallSizes.fontSize.fontSize12};

	:hover{
		background: #2B80FF;
		color: white;
	}
}

/ --------------------------------changes---------------------

.button_change_technician{
	display: flex;
	justify-content: end;
}

.display_flex{
	display: flex;
}

.column_left{
	width: 60%;
	background-color: #f6f6f6;
	border: 1px solid #dde4eb;
	padding: 25px 25px 94px 15px;
	margin-top: 30px !important;
}

.container_user_name{
	display :  flex;
	justify-content: space-between;
	margin-right: 14%;
}

.input_size{
	height: 40px;
	width: 150%;
}

.container_row_detail{
	display :  flex;
	justify-content: space-between;
	margin-right: 14%;
	margin-top: 2%;
}

.container_row_detail .subcontainer_row{
	width: 149%;
	display: flex;
}

.subcontainer_row .input_select{
	height: 40px;
	width: 70px;
	margin-right: 4%;
}

//-------------------------------------------------

.column_right {
	width: 40%;
	padding: 25px 20px 90px 25px;
	margin-top: 30px !important;
}
.text_permission{
	margin-left: 5px;
	margin-bottom: 0;
}

.font_10_detail{
	font-size: ${smallSizes.fontSize.fontSize10};
}

.container_device_version{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container_device_version .title_device_version{
	color: #131523;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.container_device_version .description_device_version{
	color: #868D96;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12};
}

//---------------------------------------

@media screen and (min-width: ${devices.smallDesk}){

	.btn{
		height: ${smallSizes.detailGeneral.heightButton};
		width: ${smallSizes.detailGeneral.widthButton};
		font-size: ${smallSizes.fontSize.fontSize12};

		.fa-pen-to-square{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	.input_detail_technicials{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.device_detail{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.btn_cancelar_question {
		width: ${smallSizes.detailGeneralTechnicials.widthButton};
		height: ${smallSizes.detailGeneralTechnicials.heightButton};
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.btn_guardar_question {
		width: ${smallSizes.detailGeneralTechnicials.widthButton};
		height: ${smallSizes.detailGeneralTechnicials.heightButton};
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.font_10_detail{
		font-size: ${smallSizes.fontSize.fontSize10};
	}

	.container_device_version .title_device_version{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.container_device_version .description_device_version{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.btn{
		height: ${mediumSizes.detailGeneral.heightButton};
		width: ${mediumSizes.detailGeneral.widthButton};
		font-size: ${mediumSizes.fontSize.fontSize12};

		.fa-pen-to-square{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	.input_detail_technicials{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.device_detail{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.btn_cancelar_question {
		width: ${mediumSizes.detailGeneralTechnicials.widthButton};
		height: ${mediumSizes.detailGeneralTechnicials.heightButton};
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.btn_guardar_question {
		width: ${mediumSizes.detailGeneralTechnicials.widthButton};
		height: ${mediumSizes.detailGeneralTechnicials.heightButton};
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.font_10_detail{
		font-size: ${mediumSizes.fontSize.fontSize10};
	}

	.container_device_version .title_device_version{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.container_device_version .description_device_version{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.btn{
		height: ${largeSizes.detailGeneral.heightButton};
		width: ${largeSizes.detailGeneral.widthButton};
		font-size: ${largeSizes.fontSize.fontSize12};

		.fa-pen-to-square{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}

	.input_detail_technicials{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.device_detail{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.btn_cancelar_question {
		width: ${largeSizes.detailGeneralTechnicials.widthButton};
		height: ${largeSizes.detailGeneralTechnicials.heightButton};
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.btn_guardar_question {
		width: ${largeSizes.detailGeneralTechnicials.widthButton};
		height: ${largeSizes.detailGeneralTechnicials.heightButton};
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.font_10_detail{
		font-size: ${largeSizes.fontSize.fontSize10};
	}

	.container_device_version .title_device_version{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.container_device_version .description_device_version{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

