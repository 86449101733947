import { Popconfirm } from 'antd'
import React from 'react'

import NoPic from '../../../assets/images/no_pic.png'
import { onDeleteImage } from '../../../api/imageGallery'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../helpers/helpers'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'


const CustomTable = ({ setVisibleUploadForm, row, onEditForm, setSelectedInfo }) => {
	const onUploadForm = (value) => {
		setVisibleUploadForm(true)
		setSelectedInfo(value)
	}

	const onDownloadImage = (value) => window.open(value, '_blank')

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}


	return (
		<div>
			{
				row?.map((item, i) => {
					return (

						<Row key={i}>
							<span className="row__custom__table">
								<div className='row__date'>
									<span className='gallery-date-created'> {item?.photo === null ? "Fecha" : item?.created} </span>
									<span className='text-user'> {item?.photo === null ? "Usuario" : item?.user} </span>
								</div>

								<div className='row__photo'>
									<img src={item?.photo === null ? NoPic : item?.photo} alt="Some desc." />
								</div>

								<div className='row__description'>
									{
										item?.photo === null
											? (
												<div className='row_description_text'>
													<label className='description_name'>{item?.name} </label>

													{
														rolPermission("add_ospic") === true ? (
															<div className='row_icon_upload' onClick={() => onUploadForm(item)}>
																<i className="fa-solid fa-upload size_icon_image" />
																<label className='text_icon_upload'>Subir imagen</label>
															</div>
														) : null
													}


												</div>
											)
											: (
												<div className='row_description_text'>
													<label className='description_name'>{item?.name} </label>

													<div className='row_icon_upload' onClick={() => onDownloadImage(item?.photo)}>
														<i className="fa-solid fa-file-arrow-down size_icon_image" />
														<label className='text_icon_upload'>Descargar</label>
													</div>
												</div>
											)
									}
								</div>

								{
									item?.photo !== null
										? (
											<div className='row_container_buttom'>

												{
													rolPermission("change_ospic") === true ? (
														<button
															onClick={() => onEditForm(item)}
															className='btn_blue_text'
														>Modificar
														</button>
													) : null
												}

												{
													rolPermission("delete_ospic") === true ? (
														<Popconfirm
															placement="topRight"
															icon={""}
															title="¿Eliminar fotografía?"
															description={() => {
																return (
																	`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
															}}
															onConfirm={() => onDeleteImage(item?.ID)}
															okText="Eliminar"
															cancelText="Cancelar"
														>
															<button className='btn_blue_text'>Eliminar</button>
														</Popconfirm>
													) : null
												}
											</div>
										)
										: null
								}


							</span>
						</Row>
					)
				})
			}
		</div>
	)
}

export default CustomTable

const Row = styled.div`
:nth-child(odd) {background: #F7FAFC}
:nth-child(even) {background: white}
margin-top: 32.16px;

.row__custom__table {
	width: 100%;
	height: 105px;
	display: flex;
	margin-bottom: 14.75px;
	align-items: center;
}

.row__date{
	width: 220px;
	height: 76px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	margin-left: 36px;
}

.row__photo img{
	width: ${smallSizes.detailOsGallery.widthImg};
	height: ${smallSizes.detailOsGallery.heightImg};
	margin: 0 32.25px;
}

.row__description{
	width: 100%;
	height: 76px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}
.btn_blue_text{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
}

.row__date .gallery-date-created{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize15};
	font-weight: bold;
}

.row__date .text-user{
	color: #5A607F;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.row__description .row_description_text{
	display: flex;
	flex-flow: column;
	margin-left: 15px;
}

.row_description_text .description_name{
	color: #5A607F;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

.row_icon_upload{
	display: flex;
	align-items: center;
}

.row_icon_upload .size_icon_image{
	color: #2B80FF;
	margin-right: 12px;
	margin-bottom: 3px;
	font-size: ${smallSizes.fontSize.fontSize13};
}

.row_icon_upload .text_icon_upload{
	color: #2B80FF;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.row_container_buttom{
	display: flex;
	margin-right: 84px;
	width: ${smallSizes.detailOsGallery.widthContainerButton};
	justify-content: space-between;
}
//-------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.row__date .gallery-date-created{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.row__date .text-user{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${smallSizes.detailOsGallery.widthImg};
		height: ${smallSizes.detailOsGallery.heightImg};
	}

	.row_description_text .description_name{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	
	.row_icon_upload .text_icon_upload{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.row_icon_upload .size_icon_image{
		font-size: ${smallSizes.fontSize.fontSize13};
	}

	.btn_blue_text{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.row_container_buttom{
		width: ${smallSizes.detailOsGallery.widthContainerButton};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.row__date .gallery-date-created{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.row__date .text-user{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${mediumSizes.detailOsGallery.widthImg};
		height: ${mediumSizes.detailOsGallery.heightImg};
	}

	.row_description_text .description_name{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.row_icon_upload .text_icon_upload{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.row_icon_upload .size_icon_image{
		font-size: ${mediumSizes.fontSize.fontSize13};
	}

	.btn_blue_text{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.row_container_buttom{
		width: ${mediumSizes.detailOsGallery.widthContainerButton};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.row__date .gallery-date-created{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.row__date .text-user{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${largeSizes.detailOsGallery.widthImg};
		height: ${largeSizes.detailOsGallery.heightImg};
	}

	.row_description_text .description_name{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.row_icon_upload .text_icon_upload{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.row_icon_upload .size_icon_image{
		font-size: ${largeSizes.fontSize.fontSize13};
	}

	.btn_blue_text{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.row_container_buttom{
		width: ${largeSizes.detailOsGallery.widthContainerButton};
	}
}
`