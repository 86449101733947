/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Panel, PanelHeader } from '../../components/panel/panel'
import moment from 'moment';
import { myFetchGet } from '../../services/services';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Select, Tooltip, notification } from 'antd';
import getMessagesES from '../../helpers/getMessages';
import { eventStyleGetter, permissionCheck, stringCapitalize } from '../../helpers/helpers';
import { getTechnician } from '../../api/calendar';
import UseOperator from '../../hooks/UseOperator';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import CustomHeader from './components/calendarHeader/CustomHeader';
import TooltipText from './components/tooltip/TooltipText';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';
import { warningMsg } from '../../helpers/antNotifications';

export default function Availability() {
	const [isVisibleForm, setIsVisibleForm] = useState(false)
	const [technicians, setTechnicians] = useState([])
	const [availabilityEvents, setEvents] = useState([])

	// calendar
	const today = moment().format('D');
	const currentMonth = moment().format('MM');
	const currentYear = moment().format('YYYY');


	const [currentView, setCurrentView] = useState("week");

	const [daySelected, setDaySelected] = useState(today)
	const [monthSelected, setMonthSelected] = useState(currentMonth)
	const [yearSelected, setYearSelected] = useState(currentYear)

	const [firstEvent, setFirstEvent] = useState(null)

	const [technicianID, setTechnicianID] = useState(null)
	const [tooltipData, setTooltipInfo] = useState(null)

	const localizer = momentLocalizer(moment)
	const { storedOperatorID } = UseOperator()
	const { Option } = Select;

	const history = useHistory();

	const [firstDayWeekCalendar, setfirstDayWeekCalendar] = useState(null)

	moment.locale('es', {
		months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
		monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
		weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
		weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
		weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
	}
	);

	const hasDisplayedNotification = useRef(false);

	async function getAvaibilityByWeek(storedOperatorID) {
		if (technicianID !== null) {
			try {
				const res = await myFetchGet(`api/v1/calendar_technician/?operator=${storedOperatorID}&technician=${technicianID}&month=${monthSelected}&year=${yearSelected}&week=1&day=${firstDayWeekCalendar}`);
				setEvents(res)
				setFirstEvent(res?.data[0])
				const toolTipInfo = res?.data?.reduce((a, c) =>
					a?.some(x => x?.title === c?.title)
						? a
						: [...a, c], [])
				setTooltipInfo(toolTipInfo)
			} catch (e) {
				setEvents(null)
			}
		}
	}
	async function getAvaibilityByMonth(storedOperatorID) {
		if (technicianID !== null) {
			try {
				const res = await myFetchGet(`api/v1/calendar_technician/?operator=${storedOperatorID}&technician=${technicianID}&month=${monthSelected}&year=${yearSelected}&week=0`);
				setEvents(res)
				setFirstEvent(res?.data[0])
				const toolTipInfo = res?.data?.reduce((a, c) =>
					a?.some(x => x?.title === c?.title)
						? a
						: [...a, c], [])
				setTooltipInfo(toolTipInfo)
			} catch (e) {
				setEvents(null)
			}
		}
	}
	async function getAvaibilityByDay(storedOperatorID) {
		if (technicianID !== null) {
			try {
				const res = await myFetchGet(`api/v1/calendar_technician/?day=${daySelected}&operator=${storedOperatorID}&technician=${technicianID}&month=${monthSelected}&year=${yearSelected}&week=0`);
				setEvents(res)
				setFirstEvent(res?.data[0])
				const toolTipInfo = res?.data?.reduce((a, c) =>
					a?.some(x => x?.title === c?.title)
						? a
						: [...a, c], [])
				setTooltipInfo(toolTipInfo)
			} catch (e) {
				setEvents(null)
			}
		}
	}

	useEffect(() => {
		if (currentView === "week") {
			storedOperatorID && getAvaibilityByWeek(storedOperatorID)
		}
		if (currentView === "day") {
			storedOperatorID && getAvaibilityByDay(storedOperatorID)
		}
		if (currentView === "month") {
			storedOperatorID && getAvaibilityByMonth(storedOperatorID)
		}
	}, [currentView, storedOperatorID, technicianID, daySelected, monthSelected, yearSelected])

	useEffect(() => {
		storedOperatorID && getTechnician(storedOperatorID).then(res => setTechnicians(res))
	}, [storedOperatorID])

	const events = availabilityEvents?.data?.map(item => {
		return {
			title: item?.title,
			start: moment(item?.start, "DD-MM-YYYY HH:mm").toDate(),
			end: moment(item?.end, "DD-MM-YYYY HH:mm").toDate(),
			color: item?.color,
		}
	})

	const onTechnicianChange = (ID) => setTechnicianID(ID)

	const handleNavigate = (date) => {
		const formatedDate = moment(date).format("DD-MM-YYYY")
		const headerDate = moment(formatedDate, "DD-MM-YYYY");

		const month = headerDate.format('MM');
		const day = headerDate.format('DD');
		const year = headerDate.format('YYYY');


		setDaySelected(day)
		setMonthSelected(month)
		setYearSelected(year)
	};

	const handleViewChange = (view) => setCurrentView(view)

	const minTime = new Date();
	minTime.setHours(7, 0, 0); // set minimum time to 7am

	const maxTime = new Date();
	maxTime.setHours(19, 0, 0); // set maximum time to 7pm

	const array = useSelector((state) => state?.authState?.permissions)

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	useEffect(() => {
		if (rolPermission("datatable_view_schedule") === false) {
			history.replace("/dashboard")
		}
	}, [])

	useEffect(() => {
		const date = new Date(); // La fecha actual
		let firstDay = new Date(date);
		let dayOfWeek = date.getDay();
		dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
		firstDay.setDate(firstDay.getDate() - dayOfWeek);

		const firstDayWeek = firstDay.getDate()
		setfirstDayWeekCalendar(firstDayWeek)
	}, []);

	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm}>
			<ContainerHeader>
				<PanelHeader
					noButton={false}
					typeButton='none'
					render={
						<Select
							style={{ width: 190, height: 40 }}
							onChange={onTechnicianChange}
							placeholder="Técnico"
							allowClear
						>
							{technicians?.map(item =>
								<Option key={item?.ID} value={item?.ID}>{item?.name} {item?.last_name}</Option>)
							}
						</Select>
					}
				>
					<span className='title-calendar'>Detalle de jornada laboral</span>
					<Tooltip
						color='white'
						arrow={false}
						placement="bottomLeft"
						overlay={<TooltipText tooltipData={tooltipData} />}
					>
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>

					<span className="sub-tex-calendar">Disponibilidad | jornada laboral</span>
				</PanelHeader>
			</ContainerHeader>

			<Container>
				{technicianID === null && !hasDisplayedNotification.current ? (
					<>
						{warningMsg({ msg: "Por favor, selecciona un técnico" })}
						{hasDisplayedNotification.current = true}
					</>
				) : null}

				<Calendar
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					min={minTime}
					max={maxTime}
					eventPropGetter={eventStyleGetter}
					defaultView='week'
					views={["day", "week", "month"]}
					culture='es'

					style={{ height: "100vh" }}

					messages={getMessagesES()}
					formats={{
						// columna izq. hora
						timeGutterFormat: 'h:mm a',

						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),

						// Dic 13 - Ene 19
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(moment(start).format('MMM D'))} - ${stringCapitalize(moment(end).format('MMM D'))}`
						),

						// Lun. 13/03
						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),

						monthHeaderFormat: (date) =>
							stringCapitalize(moment(date).format("MMMM YYYY")),
					}}
					toolbar={true}
					components={{
						toolbar: (props) => <CustomHeader {...props} technicianID={technicianID} availabilityEvents={availabilityEvents} />
					}}
					onNavigate={(date, view) => {
						if (view === 'week') {
							let firstDay = new Date(date);
							let dayOfWeek = date.getDay();
							dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
							firstDay.setDate(firstDay.getDate() - dayOfWeek);

							const firstDayWeek = firstDay.getDate()
							setfirstDayWeekCalendar(firstDayWeek)
						}
						handleNavigate(date, view);
					}}
					onView={handleViewChange}

					tooltipAccessor=""
					popup
				/>
			</Container>
		</Panel>
	)
}

const Container = styled.div`
width: 100%;

// -------CALENDAR--------

.rbc-time-slot {
    display: flex;
    justify-content: center;
    align-items: end;
    color: #8898AA;
    text-transform: uppercase;
}

.rbc-time-header-cell .rbc-header{
  padding-bottom: 0;
}

// ------VISTA DE MES/OFF------

.rbc-month-header {
  background: #F7FAFC;
  font-weigth: bold;
  color: #5A607F;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  outline: none !important;
  height: 45px;
  display: flex;
  align-items: center;
}
.rbc-month-header .rbc-header {
    padding-bottom: 0;
    border: none !important;
    outline: none !important;
}

.rbc-month-header .rbc-header {
  font-size: ${smallSizes.fontSize.fontSize15};
  color: #8898AA;
}
.rbc-off-range-bg {
    background: white;
    height: 135px;
}
.rbc-day-bg {
    height: 135px;
}
.rbc-button-link {
    color: #8898AA;
    font-size: 15px;
    margin-top: 0;
    margin-right: 22.65px;
}

// ------VISTA DE MES/OFF------

.rbc-current-time-indicator {
    border: 1px solid #11CDEF;
}

.rbc-day-slot .rbc-event-content {
    width: auto; 
	  height: auto; 
    flex: none; 
    word-wrap: break-word;
	  border-radius: 4px !important;
}

.rbc-current-time-indicator::after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: -2px;
    bottom: -4px;
    background: #11CDEF;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 0;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
}

.rbc-day-slot .rbc-event-content {
    width: 100%;
    min-height: 100vh !important;
    
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    word-wrap: break-word;
    border-radius: 4px !important;
    font-size: 12px;
    text-align: left;
    padding-right: 0;
    // padding-left: 27px;
}

.rbc-month-view {
    border: none;
}

// ---------------- Media Querys :D ---------------- 
@media screen and (min-width: ${devices.smallDesk}){
	.rbc-button-link{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

  .rbc-month-header .rbc-header{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}


@media screen and (min-width: ${devices.mediumDesk}){
	.rbc-button-link{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}
  .rbc-month-header .rbc-header{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.rbc-button-link{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

  .rbc-month-header .rbc-header{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerHeader = styled.div`
	.title-calendar{
		color: #001737;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		margin: 0 10px;
		color: #00388B;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub-tex-calendar{
		color: #001737;
		font-weight: 400;
		font-size: ${smallSizes.fontSize.fontSize12}
	}


	@media screen and (min-width: ${devices.smallDesk}){
		.title-calendar{
			font-size: ${smallSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}


	@media screen and (min-width: ${devices.mediumDesk}){
		.title-calendar{
			font-size: ${mediumSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}


	@media screen and (min-width: ${devices.largeDesk}){
		.title-calendar{
			font-size: ${largeSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;