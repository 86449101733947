import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import styled from 'styled-components';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { myFetchGet } from '../../services/services';
import { DatePicker, Tooltip, notification } from 'antd';

import Br from '../../components/br/Br';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { getTechnicians } from '../../api/technician';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { permissionCheck } from '../../helpers/helpers';
import UseOperator from '../../hooks/UseOperator';

import 'moment/locale/es';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';

const fechaActual = moment();

// Formatea la fecha en el formato "Agosto 9, 2023"
const today = fechaActual.format('D MMMM YYYY');

const RoutesReport = () => {
    const [technicians, setTechnicians] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [technicianID, setTechnicianID] = useState(null)
    const [selected, setSelect] = useState(null);

    const [data, setData] = useState(null)
    const [sort, setSort] = useState(false);
    const [dateSelected, setDateSelected] = useState("")
    const [dateSelectedValue, setDateSelectedValue] = useState("")

    const notRouteReportMsg = () => {
        notification.warning({
            style: { fontWeight: 'bold' },
            message: 'El Técnico no posee registros de rutas en la fecha seleccionada',
            placement: 'bottomLeft',
            duration: 2.5,
        })
    }


    const history = useHistory()
    const { storedOperatorID } = UseOperator()

    const onSelected = (value) => {
        setTechnicianID(value?.ID)
        setSelect(value?.name + " " + value?.last_name)

        setIsOpen(false);
    };

    const onDateChange = (value, dateString) => {
        setDateSelected(dateString)
        setDateSelectedValue(value)
    };
    const onSort = () => setSort(!sort)

    const getRouteReportHistory = async () => {
        try {
            const res = await myFetchGet(`api/v1/trip_summary/?technician=${technicianID}&day=${dateSelected}`)
            setData(res)

        } catch (error) {
            if (error?.response?.status === 500) {
                setData(null)
                notRouteReportMsg()
            }
        }
    }

    const clearFilter = () => {
        setTechnicianID(null)
        setSelect(null)
        setDateSelected('')
        setDateSelectedValue(null)
        setData(null)
    }

    useEffect(() => {
        technicianID && dateSelected && getRouteReportHistory()
    }, [technicianID, dateSelected])

    useEffect(() => {
        storedOperatorID && getTechnicians(storedOperatorID)
            .then(res => setTechnicians(res))
    }, [storedOperatorID])

    const tooltipText = (
        <ContainerTooltip>
            <span className="tittle">Información de ayuda</span>
            <span className="description">Registro de linea de tiempo de actividades del técnico.</span>
        </ContainerTooltip>
    )

    function generatePDF() {
        const selectedArea = document.getElementById('selected_pdf_area');
        html2canvas(selectedArea)
            .then((canvas) => {
                // A4 mide 210 x 290
                const imgData = canvas.toDataURL('image/png');

                const pdf = new jsPDF('l', 'mm', [210, 290]);
                pdf.addImage(imgData, 'PNG', 10, 10);
                pdf.save("Registro-de-actividad-laboral.pdf");
            });
    }

    const array = useSelector((state) => state?.authState?.permissions)
    const rolPermission = (value) => {
        return permissionCheck(array, value)
    }

    useEffect(() => {
        if (rolPermission("datatable_view_tripsummary") === false) {
            history.replace("/dashboard")
        }
    }, [])

    return (
        <Panel>
            <ContainerHeader>
                <PanelHeader noButton={true}>
                    <span className='title-route-report'>Registro de actividad laboral</span>
                    <Tooltip
                        title={tooltipText}
                        color='white'
                        arrow={false}
                        placement="bottom"
                        overlayInnerStyle={{
                            height: "95px",
                            maxHeight: '100px'
                        }}
                    >
                        <i className="fa-solid fa-circle-info circle-tooltip"></i>
                    </Tooltip>
                    <span className="sub-text-route-report">Estadísticas | Reporte de rutas</span>

                    <span className="date-compare-route-report">Comparar respecto al {today} </span>
                </PanelHeader>
            </ContainerHeader>

            <Container>
                <div className="row__ space-between">
                    <span className="title_blue">Opciones de filtrado</span>
                    {/* {
                                data && (
                                    <button
                                        className="button_pdf"
                                        style={{ background, color }}
                                        onClick={generatePDF}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleHover}
                                    >

                                        <svg className="pdf_icon" width="10.5" height="14" viewBox="0 0 10.5 14">
                                            <path d="M4.974,7A2.638,2.638,0,0,1,4.919,5.72C5.149,5.72,5.127,6.729,4.974,7ZM4.927,8.293a12.617,12.617,0,0,1-.777,1.714,10.069,10.069,0,0,1,1.72-.6,3.542,3.542,0,0,1-.943-1.116ZM2.354,11.706c0,.022.361-.148.954-1.1A3.779,3.779,0,0,0,2.354,11.706ZM6.781,4.375H10.5v8.969A.655.655,0,0,1,9.844,14H.656A.655.655,0,0,1,0,13.344V.656A.655.655,0,0,1,.656,0H6.125V3.719A.658.658,0,0,0,6.781,4.375Zm-.219,4.7A2.744,2.744,0,0,1,5.395,7.6a3.93,3.93,0,0,0,.17-1.755A.685.685,0,0,0,4.257,5.66a4.547,4.547,0,0,0,.221,2.105,25.677,25.677,0,0,1-1.116,2.346s0,0-.005,0c-.741.38-2.013,1.217-1.49,1.859a.85.85,0,0,0,.588.273c.489,0,.976-.492,1.671-1.69a15.586,15.586,0,0,1,2.16-.634,4.144,4.144,0,0,0,1.75.533A.708.708,0,0,0,8.575,9.27C8.195,8.9,7.09,9,6.563,9.073Zm3.746-6.2L7.629.191A.656.656,0,0,0,7.164,0H7V3.5h3.5V3.333A.654.654,0,0,0,10.309,2.871ZM8.282,9.852c.112-.074-.068-.325-1.17-.246C8.127,10.038,8.282,9.852,8.282,9.852Z"
                                                fill={color}
                                            />
                                        </svg>
                                        Exportar data
                                    </button>
                                )
                            } */}

                </div>

                {/* -------------------------- */}

                <div className="row__">
                    <Selecter onClick={() => setIsOpen(!isOpen)}>
                        {selected !== null ? <span style={{ color: "#868D96" }}>{selected}</span> : "Selecciona"}
                        {isOpen ? <UpOutlined color='#343A40' /> : <DownOutlined color='#343A40' />}
                        {isOpen && (
                            <span className="DropDownList">
                                {technicians?.map(item => (
                                    <span className="ListItem" key={Math.random()} onClick={() => onSelected(item)}>
                                        <p style={{ color: "black" }}>{item?.name} {" "} {item?.last_name} </p>
                                    </span>
                                ))}
                            </span>
                        )}
                    </Selecter>

                    <DatePicker
                        onChange={onDateChange}
                        style={{ width: 207 }}
                        className="ant-picker"
                        format="DD-MM-YYYY"
                        inputReadOnly
                        allowClear={false}
                        value={dateSelectedValue}
                    />

                    <Button
                        className='filter__button__route__report'
                        onClick={clearFilter}>Limpiar filtros</Button>
                </div>

                {/* -------------------------- */}

                {
                    data && (
                        <div className="row__ row__end" onClick={onSort} style={{ cursor: "pointer" }}>

                            {
                                sort === true ? (
                                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                                ) : (
                                    <i className="fa-solid fa-arrow-down-short-wide" style={{ fontSize: "12px", color: "#2B80FF" }}></i>
                                )
                            }
                        </div>
                    )
                }

                <Br />
                {
                    data && (
                        <div id="selected_pdf_area">
                            <div className="row__">
                                <div className="time_span">
                                    <div className="left">
                                        <i className="fa-solid fa-car"></i>
                                    </div>
                                    <div className="right">
                                        <span className='title_blue'>{data?.distance_traveled} km</span>
                                        <span className='distance_text'>Distancia recorrida</span>
                                    </div>
                                </div>

                                {/* --------------------------- */}

                                <div className="time_span">
                                    <div className="left">
                                        <i className="fa-solid fa-couch"></i>
                                    </div>
                                    <div className="right">
                                        <span className='title_blue'>{data?.idle_time}</span>
                                        <span className='distance_text'>Tiempo sin actividad</span>
                                    </div>
                                </div>

                                {/* --------------------------- */}

                                <div className="time_span">
                                    <div className="left">
                                        <i className="fa-regular fa-clock"></i>
                                    </div>
                                    <div className="right">
                                        <span className='title_blue'>{data?.route_time}</span>
                                        <span className='distance_text'>Tiempo de ruta</span>
                                    </div>
                                </div>
                            </div>

                            <div className="column__ margin_top">
                                {
                                    sort === false ?
                                        data?.changes?.map((item, i) => {
                                            return (
                                                <div className="timeline__box" key={i}>
                                                    <div className="date">{item?.date}</div>
                                                    <div className="mid_line"></div>
                                                    <div className="text">{item?.change}</div>
                                                </div>
                                            )
                                        })

                                        :

                                        data?.changes?.map((item, i) => {
                                            return (
                                                <div className="timeline__box" key={i}>
                                                    <div className="date">{item?.date}</div>
                                                    <div className="mid_line"></div>
                                                    <div className="text">{item?.change}</div>
                                                </div>
                                            )

                                        })?.reverse()
                                }
                            </div>
                        </div>
                    )
                }
            </Container>
        </Panel>

    )
}

export default RoutesReport

const Container = styled.div`
width: 100%;
padding-top: 24.43px;
padding-left: 42.19px;
padding-right: 44.81px;

.row__{
    width: 100%;
    display: flex;
    align-items: center;
}
.column__{
    width: 100%;
    display: flex;
    flex-flow: column;
}
.space-between{
    justify-content: space-between;
}
.row__end{
    justify-content: flex-end;
}
.margin_top{
    margin-top: 50px;
}
.title_blue{
    color: #2B80FF;
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: bold;
    margin-bottom: 10px; 
}

.button_pdf{
    width: 121px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border: 1px solid #0060FF;
    border-radius: 3px;
    font-weight: 400;
    color: black;
}
.pdf_icon{
    font-size: 14px;
    margin-right: 8.65px;
}

.ant-select{
    height: 40px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-right: 23.44px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}

.ant-picker {
    width: ${smallSizes.calendar.widthSelect} !important;
    color: #868D96;
    font-size: ${smallSizes.fontSize.fontSize12} !important;
    font-weight: 400;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: border 0.3s, box-shadow 0.3s;
    height: 40px;
}

.filter__button__route__report{
    font-size: ${smallSizes.fontSize.fontSize12} !important;
}
// ------------------------------

.time_span{
    display: flex;
    margin-right: 1em;
}
.time_span .left{
    width: 20px;
    height: 20px;
    margin-right: .5em;

    .fa-car, .fa-couch, .fa-clock{
        font-size: 14.06px;
        color: #959595;
        margin-top: 5px;
        margin-right: 6px;
       
    }
}
.time_span .right{
    display: flex;
    flex-flow: column;

    .distance_text{
        color: #172B4D;
        font-size: ${smallSizes.fontSize.fontSize12} !important;
        font-weight: 400;
    }
}

// ---------------------------------

.timeline__box{
    width: 100%;
    height: 61px;
    display: flex;
}
.timeline__box .date{
    color: #131523;
    fontWeight: bold;
    font-size: ${smallSizes.fontSize.fontSize12};
}
.timeline__box .text{
    color: #172B4D;
    fontWeight: 400;
    font-size: ${smallSizes.fontSize.fontSize12};
}
.timeline__box .mid_line{
    width: 12px;
    height: 12px;
    margin: 0 17.65px;
    background: white;
    border: 1px solid #00388B;
    border-radius: 100%;
    position: relative;

    :before{
        position: absolute;
        content: "";
        left: 50%;
        bottom: -50px;
        width: 1px;
        height: 50px;
        background: #00388B;
    }
}

// ------------ Media Querys ------------
// ------------ ------------ ------------

@media screen and (min-width: ${devices.smallDesk}){
	.title_blue{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

    .ant-picker {
        width: ${smallSizes.calendar.widthSelect};
        font-size: ${smallSizes.fontSize.fontSize12};
    }
    .filter__button__route__report,
    .timeline__box .date,
    .distance_text,
    .fa-arrow-up-wide-short,
    .fa-arrow-down-short-wide,
    .timeline__box .text
    {
        font-size: ${smallSizes.fontSize.fontSize12};
    }
}

@media screen and (min-width: ${devices.mediumDesk}){
	.title_blue{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}
    .ant-picker {
        width: ${mediumSizes.calendar.widthSelect};
        font-size: ${mediumSizes.fontSize.fontSize12};
    }
    .filter__button__route__report,
    .timeline__box .date,
    .distance_text,
    .fa-arrow-up-wide-short,
    .fa-arrow-down-short-wide,
    .timeline__box .text
    {
        font-size: ${smallSizes.fontSize.fontSize12};
    }
}

@media screen and (min-width: ${devices.largeDesk}){
	.title_blue{
		font-size: ${largeSizes.fontSize.fontSize14};
	}
    .ant-picker {
        width: ${largeSizes.calendar.widthSelect};
        font-size: ${largeSizes.fontSize.fontSize12};
    }
    .filter__button__route__report,
    .timeline__box .date,
    .distance_text,
    .fa-arrow-up-wide-short,
    .fa-arrow-down-short-wide,
    .timeline__box .text{
        font-size: ${largeSizes.fontSize.fontSize12};
    }
}

`
const Selecter = styled.span`
     width: ${smallSizes.calendar.widthSelect};
     height: 40px;
     font-size: ${smallSizes.fontSize.fontSize12};
     background-image: white;
     color: #868D96;
     display: flex;
     justify-content: space-between;
     align-items: center;
     position: relative;
     cursor: pointer;
     padding: 11.05px;
     border: 1px solid #E0E0E0;
     border-radius: 5px;
     margin-right: 10px;

// ------------ Media Querys ------------
// ------------ ------------ ------------

@media screen and (min-width: ${devices.smallDesk}){
	width: ${smallSizes.calendar.widthSelect};
    font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.mediumDesk}){
	width: ${mediumSizes.calendar.widthSelect};
    font-size: ${mediumSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.largeDesk}){
	width: ${largeSizes.calendar.widthSelect};
    font-size: ${largeSizes.fontSize.fontSize12};
}

 .DropDownList {
    width: 100%;
    padding: 1em 1em 0 1em;
    background: white;
    color: grey;
    font-size: 16px;
 	border-bottom-left-radius: 3px;
 	border-bottom-right-radius: 3px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 10;
 }

 .ListItem {
     width: 100%;
     display: flex;
     justify-content: space-between;
     margin-bottom: .3em;
     color: #131523;
     font-size: 12px
 }

 `
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 160px;
	}
`;

const ContainerHeader = styled.div`
	.title-route-report{
		color: #001737;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		margin: 0 10px;
		color: #00388B;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub-text-route-report{
		color: #001737;
		font-weight: 400;
		font-size: ${smallSizes.fontSize.fontSize12};
        
	}
	.date-compare-route-report{
		color: #001737;
		font-weight: 400;
		font-size: ${smallSizes.fontSize.fontSize12};
        flex: 1; 
        text-align: right;
	}


	@media screen and (min-width: ${devices.smallDesk}){
		.title-route-report{
			font-size: ${smallSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.sub-text-route-report,
        .date-compare-route-report
        {
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}


	@media screen and (min-width: ${devices.mediumDesk}){
		.title-route-report{
			font-size: ${mediumSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.sub-text-route-report,
        .date-compare-route-report
        {
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}


	@media screen and (min-width: ${devices.largeDesk}){
		.title-route-report{
			font-size: ${largeSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.sub-text-route-report,
        .date-compare-route-report
        {
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;

const Button = styled.button`
    height: 39px;
    padding: 0 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001737;
    background: #dde4eb;
    font-weight: bold;
    border: 1px solid #0060ff;
    font-size: 12px;
    border-radius: 3px;
    margin-left: 25px;

    :hover {
    background: #0060ff;
    color: white;
    .icon {
        color: white;
    }
    }
    .icon {
    font-size: 18px;
    margin-right: 6px;
    color: #0060ff;
    }
`;