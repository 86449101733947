import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import SidebarNavList from "./sidebar-nav-list.jsx";
import menus from "./menu.jsx";
import { useSelector } from "react-redux";

const SidebarNav = () => {
	const [state, setState] = useState({
		active: -1,
		clicked: -1,
	});
	const [menuRol, setMenuRol] = useState([]);

	function handleExpand(e, i, match) {
		e.preventDefault();

		if (state.clicked === -1 && match) {
			setState((state) => ({
				active: -1,
				clicked: 1,
			}));
		} else {
			setState((state) => ({
				active: state.active === i ? -1 : i,
				clicked: 1,
			}));
		}
	}
	const dataState = useSelector((state) => state.authState);

	useEffect(() => {
		const getRol = () => {
			switch (dataState.rol) {
				case 'client_service_isp':
					return [menus[0], menus[1], menus[2], menus[3], menus[4]];
				case 'multifiber':
					return [menus[0], menus[1], menus[2], menus[3], menus[4]];
				case 'admin':
					return [menus[0], menus[1], menus[2], menus[3], menus[4], menus[5], menus[6]];
				case 'admin_isp':
					return [menus[0], menus[1], menus[2], menus[3], menus[4], menus[5], menus[6]];
				case 'supervisor_isp':
					return [menus[0], menus[1], menus[2], menus[3], menus[4], menus[5], menus[7]];
				default:
					return [];
			}
		};

		setMenuRol(getRol());
	}, [dataState.rol]);

	return (
		<ul className="nav" style={{ width: "100%" }}>
			{menuRol.map((menu, i) => {

				return (
					<Route
						path={menu.path}
						exact={menu.exact}
						key={i}
						children={({ match }) => (
							<SidebarNavList
								data={menu}
								key={i}
								expand={(e) => handleExpand(e, i, match)}
								active={i === state.active}
								clicked={state.clicked}
							/>
						)}
					/>
				);
			})}
		</ul>
	);
};

export default SidebarNav;


