/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Pagination, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// api & helpers
import { myFetch } from "../../services/services";
import UseOperator from "../../hooks/UseOperator";
import { useFilter } from "../../hooks/useFilter";
import { permissionCheck } from "../../helpers/helpers";
import { technicianDatatables_struct } from "../../api/technician";

// custom
import NormalText from "../../components/text/NormalText";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import NewTechnicianForm from "./newTechnician/index.jsx";
import Dayjs from "dayjs";
import { sorterCallback } from "../../helpers/sorterCallback";
import './technicians.scss'
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { selectTab } from "../../store/tab/tabSlice";

export default function Technicians() {
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [selectItem, setSelectItem] = useState();
	const [loadding, setLoadding] = useState(true);
	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setRows] = useState([]);
	const [stateButton, setStateButton] = useState(true)

	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [orderDirection, setOrderDirection] = useState("desc");

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Técnicos asociados al operador.</span>
		</ContainerTooltip>
	)

	const dataState = useSelector((state) => state.authState)
	const handleRol = () => {
		if (dataState.rol === 'admin') {
			setStateButton(false)
		} else if (dataState.rol === 'admin_isp') {
			setStateButton(false)
		} else if (dataState.rol === 'multifiber') {
			setStateButton(true)
		} else if (dataState.rol === 'supervisor_isp') {
			setStateButton(false)
		} else if (dataState.rol === 'client_service_isp') {
			setStateButton(true)
		}
	}

	const { storedOperatorID } = UseOperator()
	const history = useHistory();
	const dispatch = useDispatch();

	//Filtro
	const { filters, setRowsParams, rowsParams } = useFilter()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getTechnicianDataTables = async (storedOperatorID) => {
		let newFilters = {
			...filters,
			'operator': storedOperatorID,
			"order_field": "ID",
			"order_type": orderDirection,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["status","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		try {
			const res = await myFetch("api/v1/technician/datatables/", newFilters);

			if (res?.size) { setTotalSize(res?.size) }
			if (res?.data) {
				const rows = res?.data?.map((row) => {
					const createdDateFormat = Dayjs(row.created).format("DD-MM-YYYY");
					const dateFormat = Dayjs(row.date).format("DD-MM-YYYY");
					return {
						...row,
						key: row.ID,
						created: createdDateFormat,
						date: dateFormat,
					}
				});
				setRows(rows)
			}
		} catch (e) { }
	}

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const onRow = (record, _) => {
		if (rolPermission("view_technician") === true) {
			return {
				onClick: () => {
					dispatch(selectTab({ selectedTab: 0 }))
					history.push("/techniciansDetails", { params: record })
				},
			};
		}
		return {};
	};

	useEffect(() => {
		if (rolPermission("datatable_view_technician") === false) {
			history.replace("/dashboard")
		}
	}, [])

	useEffect(() => {
		handleRol();
		storedOperatorID && technicianDatatables_struct().then(res => setCOLUMNS(res))

	}, [rowsParams, storedOperatorID]);

	useEffect(() => {
		setLoadding(false)
	}, [rowsParams])

	useEffect(() => {
		storedOperatorID && getTechnicianDataTables(storedOperatorID);
	}, [
		rowsParams,
		storedOperatorID,
		orderDirection,
		columnFilterValue,
	])


	return (
		<Panel
			value={isVisibleForm}
			setValue={setIsVisibleForm}
			loaddingData={loadding}>
			<ContainerHeader>
				<PanelHeader
					noButton={
						rolPermission("add_technician") === true ? false : true}
					typeButton="more"
					buttonText="Agregar Técnico"
				>
					<span className="title-technicials">Listado de técnicos</span>
					<Tooltip
						title={tooltipText}
						color='white'
						arrow={false}
						placement="bottom"
						overlayInnerStyle={{
							height: "75px",
							maxHeight: '100px'
						}}
					>
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>
					<span className="sub-tex-technicials">Disponibilidad | Listado de técnicos</span>
				</PanelHeader>
			</ContainerHeader>

			<span className="technician-mainPage-container">
				<p className="single-pText">Listado consolidado de técnicos asociados al operador.</p>
				<Table
					columns={COLUMNS}
					dataSource={ROWS}
					pagination={false}
					onRow={onRow}
					rowKey={(record) => record?.id_unico}

					onChange={
						(pagination, filters, sorter, extra) => {
							setOrderDirection(sorterCallback(pagination, filters, sorter, extra))

							if (filters.status === null) {
								setColumnFilterValue([]);
							} else {
								setColumnFilterValue(filters.status);
							}
						}
					}
				/>
				{isVisibleForm && <NewTechnicianForm setIsVisibleForm={setIsVisibleForm} />}

				<PaginationContainer>
					<div className="row space-between align-center">
						<span className="pagination">
							<NormalText
								bold={false}
								value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
							/>
							<NormalText
								bold={true}
								value={`total de ${totalSize} registros`}
							/>
						</span>

						<Pagination
							showSizeChanger
							defaultCurrent={paginationSettings[1]}
							current={paginationSettings[0]}
							onChange={modifyPagination}
							total={totalSize}
						/>
					</div>
				</PaginationContainer>

			</span>
		</Panel>
	);
}

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
	}
`;

const PaginationContainer = styled.div`
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
	
	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	
	.ant-pagination{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	
	.ant-pagination{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerHeader = styled.div`
.title-technicials{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize24};
}

.circle-tooltip{
	margin: 0 10px;
	color: #00388B;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.sub-tex-technicials{
	color: #001737;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12}
}

//-------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.title-technicials{
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub-tex-technicials{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.title-technicials{
		font-size: ${mediumSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.sub-tex-technicials{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.title-technicials{
		font-size: ${largeSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.sub-tex-technicials{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`;