import dayjs from "dayjs";
import { myFetch, myFetchGet } from "../services/services";
import { notification } from "antd";

const getOs = async () => {
	try {
		const res = await myFetchGet("api/v1/os/")
		const resultFilter = res.filter(obj => !obj.deleted)
		return resultFilter;
	} catch (error) { }
}

const getOstype = async (storedOperatorID) => {
	try {
		const res = await myFetchGet(`api/v1/ostype/`);
		const filterByOperator = res?.filter(item => item?.operator === storedOperatorID)

		const filterNoDeleted = filterByOperator?.filter(x => x?.deleted === false)
		return filterNoDeleted;
	} catch (e) { }
}

const getTechnician = async (storedOperatorID) => {
	try {
		const res = await myFetchGet("api/v1/technician/");
		const filterByOperator = res?.filter(item => item.operator === storedOperatorID)
		return filterByOperator
	} catch (e) { }
}

const getAvalaibleTechnicians = async (operatorID) => {
	try {
		const res = await myFetchGet(`api/v1/technician/?is_supervisor=false&supervised_by__none=1&operator=${operatorID}`);
		return res
	} catch (e) { }
}

async function getAvailableTechnicians(storedOperatorID, created, categoryID) {
	try {
		const res = await myFetchGet(`api/v1/available_technicians/?operator=${storedOperatorID}&day=${created}&category=${categoryID} `);
		return res?.list_of_technicians_availables
	} catch (e) { }
}

async function getAvailableStatus(ID) {
	try {
		const response = await myFetchGet(`api/v1/os/${ID}/valid_status/`);
		return response.status
	} catch (e) { }
}



async function getReagendarEvents(operatorID, osID, date) {
	const day = dayjs(date, 'DD-MM-YYYY').date();
	const month = dayjs(date, 'DD-MM-YYYY').month() + 1;
	const year = dayjs(date, 'DD-MM-YYYY').year();
	try {
		const res = await myFetchGet(`api/v1/reschedule_os/?operator=${operatorID}&day=${day}&os=${osID}&month=${month}&year=${year}`);
		return res?.data
	} catch (e) { }
}




async function getReagendarEventsByWeek(operatorID, osID, date) {
	const month = dayjs(date).format('MM')
	const year = dayjs(date).format('YYYY')
	const week = dayjs(date).week() - dayjs(date).startOf('month').week();

	try {
		const res = await myFetchGet(`api/v1/reschedule_os/?operator=${operatorID}&os=${osID}&month=${month}&year=${year}&week=0${week}`);
		return res?.data
	} catch (e) { }
}
async function patchReagendarEvents(operator, day, osID, technicianID, disponibilidad, startHour, endHour) {
	try {
		await myFetch(`api/v1/reschedule_os/?operator=${operator}&day=${day}&os=${osID}&technician=${technicianID}&id_disponibility=${disponibilidad}&start=${startHour}&end=${endHour}`);
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Orden de servicio reagendada satisfactoriamente',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		})

	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se ha reagendado la orden de Servicio',
			placement: 'bottomLeft',
			duration: 2.5,
			// onClose: () => window.location.reload()
		})
	}
}

async function patchStatusAndTechnicianOS(ID, updateData) {
	try {
		const res = await myFetch(`api/v1/os/${ID}/`, updateData, "PATCH");
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Orden de servicio actualizada satisfactoriamente',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
		console.log(res)
	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se ha actualizado la orden de servicio',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
		return "error"
	}
}

const getOsHistory = async (ID) => {
	try {
		return await myFetchGet(`api/v1/os/${ID}/history/`)
	} catch (error) { }
}

const checkIfUserGetOraculo = async (storedOperatorID) => {
	const config = "operator_oraculo_plans_url"
	try {
		const res = await myFetchGet(`api/v2/operator-setting/${storedOperatorID}/${config}/`)
		return res
	} catch (error) {
		return error?.response?.status
	}
}

const getOScalendarByWeek = async (storedOperatorID, monthSelected, yearSelected) => {
	try {
		const res = await myFetchGet(`api/v1/calendar_os/?operator=${storedOperatorID}&month=${monthSelected}&year=${yearSelected}`)
		return res?.data
	} catch (error) { }
}

const postNewServiceOrder = async (data) => {
	try {
		await myFetch('api/v1/os/', data)
		notification.success({
			className: "notification",
			message: "Nueva orden de servicio creada!",
			placement: "bottomLeft",
			duration: 2.5,
			onClose: () => window.location.reload()
		});
	}
	catch (e) {
		notification.error({
			className: "notification",
			message: "No se ha podido crear la order de servicio",
			placement: "bottomLeft",
			duration: 2.5,
		});
		return "error"
	}

}

const getAssistant = async (id_unico) => {
	try {
		const response = await myFetchGet(`api/v1/os/valid_auxiliaries/?id_unico=${id_unico}&especial_case=False`)
		return response.data
	} catch (error) {
		return error?.response
	}
}

export {
	getOScalendarByWeek,
	getOs,
	getOstype,
	getTechnician,
	getAvailableStatus,
	getAvailableTechnicians,
	getReagendarEvents,
	patchStatusAndTechnicianOS,
	getOsHistory,
	checkIfUserGetOraculo,
	getAvalaibleTechnicians,
	patchReagendarEvents,
	getReagendarEventsByWeek,
	postNewServiceOrder,
	getAssistant
}