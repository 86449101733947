import React from 'react'
import styled from 'styled-components';

const BlueResumeUI = ({ children, height = 166 }) => {
	return (
		<Component>
			<div className='BlueResumeUI' style={{ minHeight: height }}>{children}</div>
		</Component>
	)
};

export default BlueResumeUI
const Component = styled.div`
	.BlueResumeUI{
		color: #004085;
		background: #E9F4FF;
		width: 100%;
		border: 1px solid #B8DAFF;
		border-radius: 4px;
		padding: 2em;
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
`
