/* eslint-disable */
import React from 'react'
import { Panel } from '../../../components/panel/panel'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { deleteDocument, getDocuments } from '../../../api/technician'
import CustomModal from '../../../components/modal/CustomModal'
import CreateDocumentForm from './createDocumentForm'
import { Popconfirm } from 'antd'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../helpers/helpers'
import EditDocumentForm from './editDocumentForm'
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue'
import LabelDrawer from '../../../components/text/drawer/label'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'


export default function Documentation({ data }) {
	const [documents, setDocuments] = useState(null)
	const [showDocumentForm, setShowDocumentForm] = useState(false)
	const [showEditDocumentForm, setShowEditDocumentForm] = useState(false)

	const [selected, setSelected] = useState(null)

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const technicianID = data?.technician?.ID
	const downloadIcon = { color: "#2B80FF", fontSize: 14, marginRight: 12, marginBottom: 3 }

	useEffect(() => {
		technicianID && getDocuments(technicianID).then(res => setDocuments(res))
	}, [])

	const openEditDocumentForm = (value) => {
		setShowEditDocumentForm(true)
		setSelected(value)

	}
	const closeEditDocumentForm = () => setShowEditDocumentForm(false)

	const openDocumentFormCreate = () => setShowDocumentForm(true)
	const closeDocumentFormCreate = () => setShowDocumentForm(false)
	const onDownloadImage = (value) => window.open(value, '_blank')

	return (
		<Panel>
			<Content>
				<div className="row__documentation space-between">
					<span className='text_documentation_help'>Listado de documentos adjuntos relacionados a la venta.</span>

					{
						rolPermission("change_technicianpic") === true ? (
							<button className='add_document' onClick={openDocumentFormCreate}>
								<i className="fa-solid fa-plus"></i>
								Agregar documento</button>
						) : null
					}
				</div>

				<div className='documents__content'>
					{
						documents?.map((item, i) => {
							return (

								<Row key={i}>
									<span className="row__custom__table">
										<div className='row__date'>
											<span className='date_text'> {item?.updated} </span>
											<span className='name_date'> {item?.full_name} </span>
										</div>

										<div className='row__photo'>
											<img src={item?.photo} alt="Some desc." />
										</div>

										<div className='row__description'>
											<div style={{ display: "flex", flexFlow: "column", marginLeft: 15 }}>
												<span className='description_document'>{item?.caption} </span>

												<div style={{ display: "flex", alignItems: "center" }} onClick={() => onDownloadImage(item?.photo)}>
													<i className="fa-solid fa-file-arrow-down icon_style" />
													<b className='option_download'>Descargar</b>
												</div>
											</div>

										</div>

										<div className='row_container_buttom'>

											{
												rolPermission("change_ospic") === true ? (
													<button
														onClick={() => openEditDocumentForm(item)}
														className='btn_blue_text'
													>Modificar
													</button>
												) : null
											}

											{
												rolPermission("delete_ospic") === true ? (
													<Popconfirm
														placement="topRight"
														icon={""}
														title="¿Eliminar fotografía?"
														description={() => {
															return (
																`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
														}}
														onConfirm={() => deleteDocument(item?.ID)}
														okText="Eliminar"
														cancelText="Cancelar"
													>
														<button className='btn_blue_text'>Eliminar</button>
													</Popconfirm>
												) : null
											}
										</div>
									</span>
								</Row>
							)
						})
					}
				</div>

				{
					showDocumentForm && (
						<CustomModal onClick={closeDocumentFormCreate}>
							<CreateDocumentForm
								closeDocumentFormCreate={closeDocumentFormCreate}
								technicianID={technicianID}
							/>
						</CustomModal>
					)
				}

				{
					showEditDocumentForm && (
						<CustomModal onClick={closeEditDocumentForm}>
							<EditDocumentForm
								closeEditDocumentForm={closeEditDocumentForm}
								selected={selected}
							/>
						</CustomModal>
					)
				}
			</Content>
		</Panel>
	)
}

const Content = styled.div`
background: white;

.add_document{
    width: ${smallSizes.documentTechnicials.widtButton};
    height: ${smallSizes.documentTechnicials.heightButton};
    background: #DDE4EB;
    border: 1px solid #0060FF;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 23.66px;
		font-size: ${smallSizes.fontSize.fontSize12};
}
.fa-plus{
    color: #2B80FF;
    margin-right: 10px;
}
.row__documentation{
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 75.4px;
		padding-top: 47.97px;
}
.space-between{
    display: flex;
    justify-content: space-between;
}

.text_documentation_help{
	font-size: ${smallSizes.fontSize.fontSize14};
	color: #5A607F;
}

@media screen and (min-width: ${devices.smallDesk}){
	.text_documentation_help{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.add_document{
    width: ${smallSizes.documentTechnicials.widtButton};
    height: ${smallSizes.documentTechnicials.heightButton};
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.text_documentation_help{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.add_document{
    width: ${mediumSizes.documentTechnicials.widtButton};
    height: ${mediumSizes.documentTechnicials.heightButton};
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.text_documentation_help{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.add_document{
    width: ${largeSizes.documentTechnicials.widtButton};
    height: ${largeSizes.documentTechnicials.heightButton};
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`
const Row = styled.div`
:nth-child(odd) {background: #F7FAFC}
:nth-child(even) {background: white}

.row__custom__table {
	width: 100%;
	height: 105px;
	display: flex;
	margin-bottom: 14.75px;
	align-items: center;
	padding-left: 36.15px;
	padding-right:  75.4px;
}

.row__date{
	width: ${smallSizes.documentTechnicials.widthDate};
	height: 76px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.row__date .date_text{
	color: #2B80FF;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize15}; 
}

.row__date .name_date{
	color: #5A607F;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.row__photo{
	height: 76px;
	margin: 0 32.25px;
}

.row__photo img{
	width: ${smallSizes.documentTechnicials.widthImg};
	height: ${smallSizes.documentTechnicials.heightImg};
}

.row__description{
	width: 100%;
	height: 76px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.row__description .description_document{
	color: #5A607F;
	font-weight: 500;
	margin-bottom: 5px;
	font-size: ${smallSizes.fontSize.fontSize12};
	width: 74%;
} 

.btn_blue_text{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
}

.option_download{
	color: #2B80FF;
	font-weight: bold;
	cursor: pointer;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.icon_style{
	margin-right: 12px;
	margin-bottom: 3px;
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize14};
}

.row_container_buttom{
	display: flex;
	margin-right: 5px;
	width: ${smallSizes.detailOsGallery.widthContainerButton};
	justify-content: space-between;
}
//--------------------------------------------------
@media screen and (min-width: ${devices.smallDesk}){
	.row__date .date_text{
		font-size: ${smallSizes.fontSize.fontSize15}; 
	}

	.row__date{
		width: ${smallSizes.documentTechnicials.widthDate};
	}

	.row__date .name_date{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${smallSizes.documentTechnicials.widthImg};
		height: ${smallSizes.documentTechnicials.heightImg};
	}

	.row__description .description_document{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.option_download{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.icon_style{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.row_container_buttom{
		width: ${smallSizes.detailOsGallery.widthContainerButton};
	}

	.btn_blue_text{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.row__date .date_text{
		font-size: ${mediumSizes.fontSize.fontSize15}; 
	}

	.row__date{
		width: ${mediumSizes.documentTechnicials.widthDate};
	}

	.row__date .name_date{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${mediumSizes.documentTechnicials.widthImg};
		height: ${mediumSizes.documentTechnicials.heightImg};
	}

	.row__description .description_document{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.option_download{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.icon_style{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.row_container_buttom{
		width: ${mediumSizes.detailOsGallery.widthContainerButton};
	}

	.btn_blue_text{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.row__date .date_text{
		font-size: ${largeSizes.fontSize.fontSize15}; 
	}

	.row__date{
		width: ${largeSizes.documentTechnicials.widthDate};
	}

	.row__date .name_date{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.row__photo img{
		width: ${largeSizes.documentTechnicials.widthImg};
		height: ${largeSizes.documentTechnicials.heightImg};
	}

	.row__description .description_document{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.option_download{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.icon_style{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.row_container_buttom{
		width: ${largeSizes.detailOsGallery.widthContainerButton};
	}

	.btn_blue_text{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`