/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import FormServiceOrders from "./components/FormServiceOrders";
import styled from "styled-components";

import { CheckOutlined, FormOutlined } from '@ant-design/icons'

import UseOperator from "../../hooks/UseOperator";
import TableData from "../../components/table/TableData";
import { ostype_datatables_struct, deleteOstype, getcurrentAwaitTime } from "../../api/ostypeCreate";
import { InputNumber, Popconfirm, notification, Tooltip, Pagination } from "antd";
import { myFetch } from "../../services/services";
import { useSelector } from "react-redux";
import { useFilter } from "../../hooks/useFilter";
import moment from "moment";
import NormalText from "../../components/text/NormalText";
import { permissionCheck } from "../../helpers/helpers";
import CustomModal from "../../components/modal/CustomModal";
import { useHistory } from "react-router";
import { sorterCallback } from "../../helpers/sorterCallback";

import './typesServiceOrders.scss'

function TypesServiceOrders() {
	const { storedOperatorID } = UseOperator()
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [selectItem, setSelectItem] = useState();
	const [orderDirection, setOrderDirection] = useState("desc");

	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const array = reduxState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setRows] = useState([]);

	const [editMode, setEditMode] = useState(false)
	const [minutes, setMinutes] = useState(0)
	const [currentAwaitSeconds, setCurrentAwaitTime] = useState(0)

	const history = useHistory();

	const onCloseModal = () => setIsVisibleForm(false)

	useEffect(() => {
		ostype_datatables_struct().then(res => setCOLUMNS(res))
		loginOperadorID && getcurrentAwaitTime(loginOperadorID)
			.then(res => {
				if (res !== undefined) {
					setCurrentAwaitTime(res)
				}
			})
	}, [loginOperadorID]);

	const updateTime = async () => {
		const seconds = minutes * 60;
		const secondsToString = seconds?.toString()

		const data = {
			"valor": secondsToString
		};

		try {
			const res = await myFetch(`api/v2/operator-setting/${storedOperatorID}/operator_waiting_time_os_int/`, data, "PATCH")
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Tiempo de espera Actualizado',
				placement: 'bottomLeft',
				duration: 0.5,
				onClose: () => window.location.reload()
			})
		} catch (error) {
			console.log("error", error)
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: 'No se pudo actualizar el tiempo de espera',
				placement: 'bottomLeft',
				duration: 1,
				// onClose: () => window.location.reload()
			})
		}
	}

	const onChange = (value) => setMinutes(value);

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Tipos de órdenes habilitadas por el operador.</span>
		</ContainerTooltip>
	)



	// --------
	// --------
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	//Filtro
	const { filters, setRowsParams, rowsParams } = useFilter()
	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const ostype_datatables = async () => {

		const filter = {
			...filters,
			operator: loginOperadorID,
			order_field: "ID",
			order_type: orderDirection,
		}
		try {
			const res = await myFetch("api/v1/ostype/datatables/", filter);
			if (res?.size) { setTotalSize(res?.size) }

			if (res?.data) {
				const rows = res?.data.map((row) => {
					const dateFormat = moment(row?.created).format("DD-MM-YYYY");
					return {
						...row,
						key: row.ID,
						created: dateFormat,
					};
				});
				setRows(rows)
			}
		} catch (e) { console.log("error", e) }
	}

	useEffect(() => {
		ostype_datatables()
	}, [rowsParams, orderDirection])

	useEffect(() => {
		if (rolPermission("datatable_view_ostype") === false) {
			history.replace("/dashboard")
		}
	}, [])

	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm}>
			<PanelHeader
				noButton={rolPermission("add_ostype") === true ? false : true}
				typeButton="more"
				buttonText="Crear tipo de orden de servicio"
			>
				<span className="main__title__type__Service__orders">Tipos de Órdenes de Servicios</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>
				<span className="sub__text__header__type__Service__orders">Configuración | Tipos de órdenes de servicio</span>
			</PanelHeader>
			<div className="type-services-mainPage-container">

				{
					rolPermission("change_ostype") === true ? (
						<span className="time-await-content">
							<p className="time-await-text">Tiempo de espera al cliente</p>

							{editMode === false ? (
								<button className="button">
									<b style={{ color: "white" }}>{currentAwaitSeconds / 60} : 00 </b>
									<FormOutlined
										style={{ color: "white", paddingLeft: "7px", marginLeft: "7px", borderLeft: "2px solid white" }}
										onClick={() => setEditMode(!editMode)}
									/>
								</button>

							) : (
								<button className="button">

									<InputNumber
										size="small"
										min={1}
										max={60}
										defaultValue={currentAwaitSeconds / 60}
										onChange={onChange}
									/>
									<span style={{ color: "white", fontSize: 16, fontWeight: "500" }}>:00</span>
									<Popconfirm
										placement="topRight"
										icon={""}
										title="Actualizar la espera?"
										description={() => {
											return (
												`Si desea Actualizar el tiempo de espera.`)
										}}
										onConfirm={updateTime}
										okText="Guardar"
										cancelText="Cancelar"
									>
										<CheckOutlined
											style={{ color: "white", paddingLeft: "7px", borderLeft: "2px solid white" }}
										// onClick={updateTime}
										/>
									</Popconfirm>

								</button>
							)}
						</span>
					) : null
				}


				<p className="single-pText">Listado de tipos de Órdenes de servicio registradas por el operador.</p>

				<TableData
					rows={ROWS}
					columns={COLUMNS}
					option={true}
					setValue={setSelectItem}
					setValue2={setIsVisibleForm}
					searchActive={true}
					selectPath={"/TypeDetallesOS"}
					deleteItem={(e) => deleteOstype(e?.id_unico)}
					onChange={
						(pagination, filters, sorter, extra) => setOrderDirection(sorterCallback(pagination, filters, sorter, extra))
					}
				/>

				{isVisibleForm &&
					<CustomModal onClick={onCloseModal}>
						<FormServiceOrders
							setIsVisibleForm={setIsVisibleForm}
							loginOperadorID={loginOperadorID}
						/>
					</CustomModal>
				}


				<PaginationContainer>
					<div className="row space-between align-center">
						<span className="pagination">
							<NormalText
								bold={false}
								value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
							/>
							<NormalText
								bold={true}
								value={`total de ${totalSize} registros`}
							/>

						</span>
						<Pagination
							showSizeChanger
							defaultCurrent={paginationSettings[1]}
							current={paginationSettings[0]}
							onChange={modifyPagination}
							total={totalSize}
						/>
					</div>
				</PaginationContainer>

			</div>
		</Panel>
	);
}

export default TypesServiceOrders;

const PaginationContainer = styled.div`
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

`;

const Row = styled.span`
	display: flex;
	width: 100%;

	div, b{
		margin: inherit;
	}
	.edit_input{
		margin: inherit;
		width: 40px;
		height: 23px;
		border: none;
		outline: none;
		background: #0058FF;
		color: white;
		font-weight: 500;
	}
	.minutes_input{
		margin: inherit;
		width: 40px;
		height: 23px;
		border: none;
		outline: none;
		background: #0058FF;
		color: white;
		font-weight: 500;
		font-size: 16px;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	padding: 0;
	}	

	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}

	.button{
		height: 23px;
		padding: 10px;
		background: #0058FF;
		border: none;
		outline: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 155px;
	}
`;
