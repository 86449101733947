import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment/moment';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Divider, TimePicker, notification } from 'antd';

import getMessagesES from '../../../../helpers/getMessages'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import NormalText from '../../../../components/text/NormalText';

import CustomModal from '../../../../components/modal/CustomModal.jsx';
import ZoneAvilitationForm from '../../availability/zoneAvilitationForm/index.jsx'
import { maxEndTime, minStartTime, stringCapitalize } from '../../../../helpers/helpers.js';
import { getAvailabilityDirections, getBlockEvents } from '../../../../api/available_hours';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes';

// Formats
const hourFormat = 'HH:mm';
const localizer = momentLocalizer(moment);
const minTime = new Date();
minTime.setHours(7, 0, 0); // set minimum time to 7am

const maxTime = new Date();
maxTime.setHours(19, 0, 0); // set maximum time to 7pm
const disabledHours = () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		if (i < minStartTime.hour() || i > maxEndTime.hour()) {
			hours.push(i);
		}
	}
	return hours;
};

const AvailableCalendar = ({ close, technicianID }) => {
	const [zoneForm, setZoneForm] = useState(false);
	const [directions, setDirections] = useState(null);

	const [currentDate, setCurrentDate] = useState(new Date());
	const [firstDayOfWeek, setFirstDayOfWeek] = useState(null);
	// semana

	const handleNavigate = date => {
		const firstDay = moment(date).startOf('week').toDate();
		setCurrentDate(date);

		const formattedDate = moment(firstDay).format("DD-MM-YYYY");
		setFirstDayOfWeek(formattedDate);
	};

	useEffect(() => {
		handleNavigate()
	}, [setCurrentDate])

	// virtual
	const [sendToForm, setSendToForm] = useState([]);
	const [disponibilityArray, setDisponibilityArray] = useState([]);

	// ----------Star event----------------
	const [virtualEvent, setVirtualEvent] = useState(null)
	const [isVisibleForm, setIsVisibleForm] = useState(false);

	useEffect(() => {
		technicianID && getAvailabilityDirections().then(res => setDirections(res))
		firstDayOfWeek && getBlockEvents(technicianID, firstDayOfWeek)
			.then(res => {
				if (res?.length === 0) {
					return
				}
				setDisponibilityArray(res)
			})
	}, [technicianID, firstDayOfWeek])

	const onModalZoneClose = () => setZoneForm(false)
	const onModalZoneOpen = () => setZoneForm(true)

	const onModalClose = () => setIsVisibleForm(false)
	const onModalOpen = () => setIsVisibleForm(true)

	const handleSelectSlot = useCallback(
		(event) => {
			onModalOpen()
			const selectedDate = moment(event?.start, "ddd MMM DD YYYY HH:mm:ss").format("DD-MM-YYYY");
			const selectedStart = moment(event?.start, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");
			const selectedEnd = moment(event?.end, "ddd MMM DD YYYY HH:mm:ss").format("HH:mm");

			setVirtualEvent({
				date: selectedDate,
				start: selectedStart,
				end: selectedEnd
			})
		},
		[]
	)

	const onStartChange = (_, timeString) => setVirtualEvent({ ...virtualEvent, "start": timeString })
	const onEndChange = (_, timeString) => setVirtualEvent({ ...virtualEvent, "end": timeString })


	const startEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.start}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
	const endEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.end}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')


	const toDateEndEvent = new Date(Date.parse(endEvent))
	const toDateStart = new Date(Date.parse(startEvent))

	const newEventToCalendar = {
		title: "Bloque de disponibilidad",
		end: toDateEndEvent,
		start: toDateStart,
	}

	// añade bloques creados al Array de Disponibilidades.
	const addBlockTimeArray = () => {
		setDisponibilityArray(prevDisponibility => [...prevDisponibility, newEventToCalendar]);
		setSendToForm(prevDisponibility => [...prevDisponibility, {
			"date": virtualEvent?.date,
			"start": virtualEvent?.start,
			"end": virtualEvent?.end,
		}]);
		onModalClose()
	}

	const notificationCalendar = () => {
		notification.warning({
			style: { fontWeight: 'bold' },
			message: "Selecciona una fecha para continuar",
			placement: 'bottomLeft',
			duration: 2.5,
		})
	}



	const CustomHeader = ({ label, onNavigate }) => {
		return (
			<CustomHeaderCalendar>
				<div className="left">
					<button onClick={close} className='arrow_back'>
						<i className="fa-solid fa-chevron-left" style={{ color: "#2B80FF", marginRight: 10 }}></i>
						<SubtitleBlue marginBottom="3px" value="Regresar" />
					</button>
				</div>

				<span className="mid">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</span>

				<div className="right">
					<button
						className="right__bn"
						onClick={sendToForm.length === 0 ? notificationCalendar : onModalZoneOpen}
					>
						<span>Continuar Habilitación</span>
					</button>
				</div>
			</CustomHeaderCalendar >
		)
	}

	const eventStyleGetter = (event) => {
		let style = {
			backgroundColor: "#0060F8",
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
		};
		if (event.type === 'current-date-line') {
			style = {
				...style,
				className: "custom__line",
			};
		}
		return style

	};

	// -------------Calendar Events-----------------
	const events = disponibilityArray?.map((item) => {
		return {
			title: "Bloque de disponibilidad",
			start: item?.start,
			end: item?.end
		};
	})


	return (
		<Container>
			<Calendar
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				eventPropGetter={eventStyleGetter}
				style={{ height: "100%" }}
				min={minTime}
				max={maxTime}
				views={["", "week", ""]}
				culture='es'
				messages={getMessagesES()}
				defaultView='week'
				components={{
					toolbar: CustomHeader,
				}}
				tooltipAccessor=""
				onSelectSlot={handleSelectSlot}
				selectable
				defaultDate={currentDate}
				onNavigate={handleNavigate}
				formats={{
					timeGutterFormat: "h:mm a",
						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(moment(start).format('MMM D'))} - ${stringCapitalize(moment(end).format('MMM D'))}`
						),

						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),
				}}
			/>

			{
				isVisibleForm && (
					<CustomModal>
						<CardEvent>
							<div className="card">
								<NormalText value="Confirmación" />
								<div style={{ marginBottom: 7 }}></div>

								<NormalText
									size="12px"
									bold={true}
									value="Registro de hora de disponibilidad"
									color="#2B80FF"
								/>

								<div className="row space-between">
									<div className="left__picker">
										<NormalText size="12px" value="Inicio" color="#131523" />
										<TimePicker
											format={hourFormat}
											inputReadOnly
											placeholder={dayjs(virtualEvent?.start, 'HH:mm')}
											style={{ width: "100%" }}
											disabledHours={disabledHours}
											onChange={onStartChange}
											defaultValue={dayjs(virtualEvent?.start, 'HH:mm')}
										/>

									</div>
									<div className="right__picker">
										<NormalText size="12px" value="Fin" color="#131523" />
										<TimePicker
											format={hourFormat}
											inputReadOnly
											placeholder={dayjs(virtualEvent?.end, 'HH:mm')}
											style={{ width: "100%" }}
											disabledHours={disabledHours}
											onChange={onEndChange}
											defaultValue={dayjs(virtualEvent?.end, 'HH:mm')}
										/>
									</div>
								</div>

								<Divider />

								<div className="row flex-end">
									<button className="btn_normal" onClick={onModalClose}>Cancelar</button>
									<button className="btn_silver" onClick={addBlockTimeArray}>Continuar</button>
								</div>
							</div>

						</CardEvent>
					</CustomModal>
				)
			}

			{
				zoneForm && (
					<CustomModal onClick={onModalZoneClose}>
						<ZoneAvilitationForm
							onModalZoneClose={onModalZoneClose}
							directions={directions}
							technicianID={technicianID}
							sendToForm={sendToForm}
							firstDayOfWeek={firstDayOfWeek}
						/>
					</CustomModal>
				)
			}
		</Container>
	)
}

export default AvailableCalendar

const Container = styled.div`
width: "100%";

.rbc-event{
      background: #0060F8;
	  padding-left: 10px;
}

.rbc-button-link {
	font-size: ${smallSizes.fontSize.fontSize15};
}

.rbc-day-slot .rbc-event-label {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12}
}

@media screen and (min-width: ${devices.smallDesk}){
	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`

const CardEvent = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card{
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding: 20px;
    display : flex;
    flex-flow: column;
    background: white;
  }

  .row{
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
  }
  .flex-end{
    justify-content: flex-end;
  }
  .space-between{
    display: flex;
    justify-content: space-between;
  }

  .left__picker{
    width: 47%;
    background: white;
  }
  .right__picker{
    width: 47%;
    background: white;
  }
  button{
    border: none;
    outline: none;
  }

 .ant-picker-suffix {display: none}

	.btn_normal{
    background: transparent;
    font-size: ${smallSizes.fontSize.fontSize12};
    color: #5A607F;
    z-index:10;
    margin-right: 14px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;
  }

  .btn_silver{
    background: #DDE4EB;
		font-size: ${smallSizes.fontSize.fontSize12};
    color: #001737;
    z-index:10;
    border-radius: 3px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;

    :hover{
      background: #2B80FF;
      color: white;
    }
  }


  .fa-trash{
    color: white;
    margin: 0;
    padding: 0;
    font-size: 11px
  }
  
	@media screen and (min-width: ${devices.smallDesk}){

		.btn_normal{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){

		.btn_normal{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.input_card_ui{
			font-size: ${largeSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}
	}
`


const CustomHeaderCalendar = styled.div`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
    padding-left: 33.41px; 
    padding-right: 54px; 

	button{
		border: none;
		outline: none;
		background: white;
	}
	.arrow_back{
		display: flex;
		align-items: center;
		background: white;
	}

	.left,
	.mid,
	.right{
		flex: 1;
	}
	.left{
		background: white;
	}
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mid .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}

	.mid button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	

	// ----------------------------------

	.right{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.right .right__bn{
		width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
		border: none;
		outline: none;
		background: #DDE4EB;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #0060FF;
		border-radius: 3px;
		font-size: ${smallSizes.fontSize.fontSize12};
		color: #001737;
	}

	//-------------------------------------------

	@media screen and (min-width: ${devices.smallDesk}){
		.mid .label{
			font-size: ${smallSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${smallSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.mid .label{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${mediumSizes.availability.widtButton};
			height: ${mediumSizes.availability.heightButton};
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.mid .label{
			font-size: ${largeSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${largeSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${largeSizes.availability.widtButton};
			height: ${largeSizes.availability.heightButton};
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;