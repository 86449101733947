/* eslint-disable */
import React, { useState } from 'react'
import { DatePicker, Input, Select, Space, notification } from 'antd';

// api
import { onHolidayCreate } from '../../api/holiday';

// hooks & helpers
import UseOperator from '../../hooks/UseOperator';

// custom
import Br from '../../components/br/Br';
import LabelDrawer from '../../components/text/drawer/label';
import MainTitle from '../../components/text/drawer/mainTitle';
import SubtitleBlue from '../../components/text/drawer/subtitleBlue';
import Form from '../../components/form/index.jsx';
import BottomSection from '../../components/form/bottomSection';
import { validarCampos } from '../../helpers/helpers';
import ErrorMsg from '../../components/text/notificationText/ErrorMsg';
import './formStyle.scss'

const { Option } = Select;

const NewHolidayForm = ({ setVisible, loginOperadorID }) => {
	const [date, setDate] = useState(null)
	const [holidayName, setHolidayName] = useState(null)
	const [holidayStatus, setHolidayStatus] = useState(0)
	const [holidayType, setHolidayType] = useState(null)
	const [errorRegex, setErrorRegex] = useState(false)

	const { storedOperatorID } = UseOperator()

	const onCloseModal = () => setVisible(false)

	const handleChange = ({ target }) => {
		const inputValidation = /^[a-zA-ZáéíóúÁÉÍÓÚ]+(?:\s[a-zA-ZáéíóúÁÉÍÓÚ]+)*$/;
		const isValidationInput = inputValidation.test(target.value.trim());

		if (isValidationInput) {
			setHolidayName(target.value.trim());
			setErrorRegex(false);
		} else {
			setErrorRegex(true);
		}
	};

	const [inputStatus, setInputStatus] = useState({ input1: '', input2: '', input3: '' });

	const onHolidayChange = (value) => setHolidayType(value)
	let holidayData = {
		"day": date,
		"name": holidayName,
		"status": holidayStatus,
		"holiday_type": holidayType,
		"operator": loginOperadorID
	}

	const NewHoliday = () => {
		if (
			date === null ||
			holidayName === null ||
			holidayType === null
		) {
			setInputStatus({
				input1: date === null ? 'error' : '',
				input2: holidayName === null ? 'error' : '',
				input3: holidayType === null ? 'error' : '',
			});
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Completa todos los campos',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		if (validarCampos(holidayData)) {
			onHolidayCreate(holidayData)
			setInputStatus({ input1: "", input2: "", input3: "", });
		}
	}

	const dateHandler = (dates, dateStrings) => dateStrings === "" ? setDate("") : setDate(dateStrings)

	return (
		<Form>
			<MainTitle value='Registro de día feriado' onClick={onCloseModal} />

			<SubtitleBlue value='Información de la festividad' />

			<div className="column">
				<LabelDrawer value='Fecha' />
				<DatePicker
					placeholder='Seleccione una fecha'
					onChange={dateHandler}
					format='DD-MM-YYYY'
					style={{ height: 40 }}
					inputReadOnly
					status={inputStatus?.input1}
				/>

			</div>

			<Br />

			<div className="column">
				<LabelDrawer value='Nombre' />
				<Input
					className='input_font_12_holidays'
					placeholder='Ingrese el nombre de la festividad'
					onChange={handleChange}
					style={{ height: 40 }}
					status={inputStatus?.input2}
				/>
				{errorRegex && <ErrorMsg value="Solo se admiten letras" />}
			</div>

			<Br />

			<div className="column">
				<LabelDrawer value='Tipo' />
				<Select
					onChange={onHolidayChange}
					placeholder="Seleccionar"
					status={inputStatus?.input3}
				>
					<Option key={0} value={1}>Civil</Option>
					<Option key={1} value={0}>Religioso</Option>
				</Select>
			</div>

			<BottomSection onAcept={NewHoliday} onCancel={onCloseModal} />
		</Form>
	)

}

export default NewHolidayForm