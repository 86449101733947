/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import moment from "moment/moment";
import { myFetch, myFetchGet } from "../../../services/services";
import Br from "../../../components/br/Br";
import styled from "styled-components";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../helpers/sizes";
import DatatableHeaderText from "../../../components/text/datatable/datatableHeaderText";
import DatatableRowText from "../../../components/text/datatable/datatableRowText";
import { Popconfirm, Table } from "antd";
import { permissionCheck } from "../../../helpers/helpers";
import { useSelector } from "react-redux";

export default function DisplacementPanel({ data }) {
	const [loadding, setLoadding] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalSize, setTotalSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);

	const { id_unico } = data
	const filter = { "filters": `[["os","equal", "${id_unico}"]]` }

	async function initial() {
		try {
			const res = await myFetch("api/v1/displacement/datatables/", filter);
			if (res) {
				if (res.size) {
					setTotalSize(res.size);
				}
				if (res.data) {
					const rows = res.data.map((row) => {
						const dateFormat = moment(row.date).format("DD-MM-YYYY");
						return {
							...row,
							key: row.ID,
							created: row.created,
							date: dateFormat,
						};
					});
					setTableRows(rows);
				}
			}
		} catch (e) {
			console.log("error", e);
		} finally {
			setLoadding(false);
		}
	}

	async function getColumns() {
		try {
			const data = await myFetchGet("api/v1/displacement/datatables_struct");
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					title: <DatatableHeaderText value={columnsTittle} />,
					width: columnsTittle === 'Fecha' ? '150px' : "",
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					sorter: columnsTittle === 'Fecha' ? true : null,
					render: columnsTittle === 'Fecha'
						? (value) => <DatatableRowText value={value} />
						: columnsTittle === 'Origen' ?
							(value) =>
								<DatatableRowText value={value} />
							: columnsTittle === 'Destino' ?
								(value) =>
									<DatatableRowText value={value} />
								: columnsTittle === 'Medio' ?
									(value) =>
										<DatatableRowText value={value} />
									: columnsTittle === 'Tiempo' ?
										(value) =>
											<DatatableRowText value={value} />
										: (value) => <DatatableRowText value={value} />

				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	}

	useEffect(() => {
		initial();
		getColumns();
	}, []);

	// --------------------
	// --------------------
	const dataState = useSelector((state) => state.authState)
	const array = dataState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const renderOptions = (_, record) => {
		if (rolPermission("delete_ostype")) {
			return (
				<ContainerButton>
					<Popconfirm
						placement="topRight"
						icon={null}
						title="¿Eliminar registro?"
						description={() => {
							return (
								<span>
									Si eliminas este registro, se eliminará de forma permanente de tu historial
								</span>
							);
						}}
						onConfirm={(e) => {
							// e.stopPropagation();
							// deleteItem(record);
							console.log("not delete, await api")
						}}
						onCancel={(e) => { e.stopPropagation() }}
						okText="Eliminar"
						cancelText="Cancelar"
					>
						<button
							onClick={(e) => e.stopPropagation()}
							className="btn-datatable-displacement"
						>
							Eliminar
						</button>
					</Popconfirm>
				</ContainerButton>
			);
		}
		return null; // Si no se cumple la condición, no se renderiza nada
	};

	const columnsOptions = [
		...tableColumns,
		{
			title: <DatatableHeaderText value="Acción" />,
			dataIndex: "option",
			key: "option",
			width: "150px",
			className: "rowOptios",
			render: renderOptions,
		}
	];
	// --------------------
	// --------------------

	return (

		<Panel loaddingData={loadding}>
			<ContainerHeader>
				<PanelSubHeader noButton={true} typeButton={"more"}>
					<p className="description-displacement">Listado de trayectos recorridos registrados desde la aplicación asociado al atendimiento de la orden de servicio.</p>
					<Br />

				</PanelSubHeader>
			</ContainerHeader>

			<Table
				columns={rolPermission("delete_ostype") ? columnsOptions : tableColumns}
				dataSource={tableRows}
			/>
		</Panel>

	);
}

const ContainerHeader = styled.div`
	margin-top: 20px;
	margin-bottom: 26px;

.description-displacement{
	color: #5A607F;
    margin-bottom: 30.16px !important;
    padding-left: 42px !important;
	font-size: ${smallSizes.fontSize.fontSize14};
}

@media screen and (min-width: ${devices.smallDesk}){
	.description-displacement{
		font-size: ${smallSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.description-displacement{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.description-displacement{
		font-size: ${largeSizes.fontSize.fontSize14};
	}
}
`;

const ContainerButton = styled.div`

.btn-datatable-displacement{
	color: #2B80FF;
	background: transparent;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-datatable-displacement{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-datatable-displacement{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.btn-datatable-displacement{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
