/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Login from './pages/login.jsx';
import Layout from './layout/index.jsx';
import moment from 'moment';

import 'moment/locale/es';
import { useSelector } from 'react-redux';
moment.locale('es');

const App = () => {
	const [isLogged, setIsLogged] = useState(false)
	// const token = localStorage.getItem('tokenUser')

	const { token } = useSelector((state) => state.authState)

	useEffect(() => {
		if (token !== null || token !== undefined) {
			setIsLogged(true)
		}
	}, [token])

	return (
		token?.length === undefined ? <Login /> : <Layout />
	)
}

export default App