import React, { useEffect, useState } from 'react'

import { Checkbox, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { Panel, PanelBody } from '../../../components/panel/panel'
import { myFetchGet } from '../../../services/services';
import historyDataRegister from '../../../helpers/historyDataRegister';
import { History } from '../../../components/history/History';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';

const btnStyles = { width: "24px", height: "24px", borderRadius: "3px", background: "#DDE4EB", fontSize: "8px", display: "flex", justifyContent: "center", alignItems: "center", outline: "none", border: "none" }

export default function HistoricalPanel({ data }) {
	const IDdeOS = data?.id_unico

	const [historyData, setHistoryData] = useState(null)
	const [checked, setChecked] = useState(1);

	const getHistory = async () => {
		const historyUrl = `api/v1/os/${IDdeOS}/history/`
		const statusUrl = `api/v1/os/${IDdeOS}/status_history/`
		try {
			const res = await myFetchGet(checked === 1 ? historyUrl : statusUrl)
			setHistoryData(historyDataRegister(res?.changes))
		} catch (error) {
			setHistoryData(null)
		}
	}

	const onChange = ({ target }) => {
		const value = target?.value
		setChecked(value)
	}

	useEffect(() => {
		getHistory()
	}, [checked, IDdeOS])

	const items = [
		{
			label: <Checkbox checked={checked === 1 ? true : false} value={1} onChange={onChange}>Registro de actividad</Checkbox>,
			key: '0',
		},
		{
			label: <Checkbox checked={checked === 2 ? true : false} value={2} onChange={onChange}>Histórico de estado</Checkbox>,
			key: '1',
		},
		{
			type: 'divider',
		},
		{
			label:
				<span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<span style={{ color: "#5A607F", fontSize: "12px" }}>Limpiar</span>
					<button style={btnStyles}><span>Ok</span></button>
				</span>,
			key: '3',
		},
	];

	return (
		<Panel>
			<Container>
				<Dropdown menu={{ items }} trigger={['click']} className="drop_styling">
					<Space>
						<span className="title_blue">
							{checked === 1 ? "Registro de actividad" : "Histórico de estado"}
						</span>
						<DownOutlined />
					</Space>
				</Dropdown>
				{/* -------------- */}
				<span className='description_historical_os'>
					Histórico asociado a los cambios registrados de la orden de servicio.
				</span>

				<History data={historyData} />

			</Container>
		</Panel >
	)
}

const Container = styled.div`

width: 100%;
display: flex;
flex-flow: column;
background: white;
max-height: 670px;
overflow-y: auto;
padding-left: 45.69px;
padding-top: 32.78px;

.row__{
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 43px;
}
.space-between{
	justify-content: space-between;
}
.row__end{
	justify-content: flex-end;
}

.title_blue{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize14};
	font-weight: bold;
}

.time_span{
	display: flex;
	margin-right: 1em;
}
.time_span .left{
	.fa-car, .fa-couch, .fa-clock{
		font-size: 14.06px;
		color: #959595;
		margin-top: 5px;
		margin-right: 6px;
	}
}
.time_span .right{
	display: flex;
	flex-flow: column;
	
	.distance_text{
		color: #172B4D;
		font-size: 12px;
		font-weight: 400;
	}
}
.drop_styling{
  width: 170px;
// height: 32px;
}

// ---------------------------------

.timeline__box{
	width: 100%;
	height: 75px;
	display: flex;
}
.timeline__box .date{
	display: flex;
	flex-flow: column;
}

.timeline__box .mid_line{
    width: 12px;
    height: 12px;
    margin: 0 17.65px;
    background: white;
    border: 1px solid #00388B;
    border-radius: 100%;
    position: relative;

    :before{
        position: absolute;
        content: "";
        left: 50%;
        bottom: -65px;
        width: 1px;
        height: 64px;
        background: #00388B;
    }
}

.description_historical_os{
	color: #5A607F;
	margin-top: 5px;
	margin-bottom: 34.16px;
	font-size: ${smallSizes.fontSize.fontSize14};
}


//----------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.title_blue{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.description_historical_os{
		font-size: ${smallSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.title_blue{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.description_historical_os{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.title_blue{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.description_historical_os{
		font-size: ${largeSizes.fontSize.fontSize14};
	}
}
`
