import { useState } from "react"

export const useFilter = () => {
    const [osTypeID, setOsTypeID] = useState()
    const [technicianID, setTechnicianID] = useState()
    const [startDate, setStartDate] = useState()
    const [endtDate, setEndDate] = useState()
    const [searchData, setSearchData] = useState()

    const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 })

    const ostype = `["ostype","equal", "${osTypeID}"]`
    const technician = `["technician","equal", "${technicianID}"]`
    const date = `["created","gt","${startDate} 00:00"], ["created","lte","${endtDate} 23:59"]`
    const head = "["
    const tail = "]"
    let filters = {}

    if (
        osTypeID !== undefined &&
        technicianID === undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData === undefined

    ) {
        filters = {
            filters: head + ostype + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID !== undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + technician + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID === undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + date + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID === undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData !== undefined
    ) {
        filters = {
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID !== undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + ostype + ', ' + technician + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID === undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + ostype + ', ' + date + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID === undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + ostype + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID !== undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + technician + ', ' + date + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID !== undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + technician + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID === undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + date + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID !== undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData === undefined
    ) {
        filters = {
            filters: head + ostype + ', ' + technician + ', ' + date + tail,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID !== undefined &&
        startDate === undefined &&
        endtDate === undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + ostype + ', ' + technician + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID === undefined &&
        technicianID !== undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + technician + ', ' + date + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else if (
        osTypeID !== undefined &&
        technicianID !== undefined &&
        startDate !== undefined &&
        endtDate !== undefined &&
        searchData !== undefined
    ) {
        filters = {
            filters: head + ostype + ', ' + technician + ', ' + date + tail,
            search: searchData,
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    } else {

        filters = {
            offset: rowsParams.offset,
            start: rowsParams.start,
        }
    }

    const osTypeHandler = (value) => {
        setOsTypeID(value)
    }

    const technicianHandler = (value) => {
        setTechnicianID(value)
    }

    const dateHandler = (dates, dateStrings) => {
        if (dateStrings[0] === "" && dateStrings[1] === "") {
            setStartDate(undefined)
            setEndDate(undefined)
        } else {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        }
    }

    const searchHandler = (value) => {
        if (value === "") {
            setSearchData(undefined)
        } else {
            setSearchData(value)
        }

    }


    return {
        osTypeID,
        osTypeHandler,
        technicianID,
        technicianHandler,
        startDate,
        endtDate,
        dateHandler,
        searchData,
        searchHandler,
        filters,
        setRowsParams,
        rowsParams
    }
}
