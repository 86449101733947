import React from 'react'
import './index.scss'

const Form = ({ children }) => {
	return (
		<div className='form_style_background'>
			<span className='form animate__animated animate__fadeInRight custom_scroll'>{children}</span>
		</div>
	)
}
export default Form