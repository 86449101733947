/* eslint-disable */

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import bgLogin from "../assets/images/login-bg/login_new.png";
import LOGO from "../assets/images/logo/logo.png";

import { Button, Input, notification } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router";
import { signIn } from "../services/services";
import { useDispatch } from "react-redux";
import { onLogin } from "../store/auth/authSlice";

export default function Login() {
	const [loadding, setLoadding] = useState(false);

	const [formValues, setFormValues] = useState({email: "",password: ""})
	const [errors, setErrors] = useState({ email: "", password: "" });
	const [errorStatus, setErrorStatus] = useState({ email: false, password: false })
	const [showPass, setShowPass] = useState(true)

	const history = useHistory()
	const dispatch = useDispatch()

	const onChange = (event) => {
		const { name, value } = event.target;
		setFormValues({ ...formValues, [name]: value });
	};

	useEffect(() => {
		const { email, password } = formValues;
		let emailError = "";
		let passwordError = "";
		if (!email) {
			emailError = "Ingresa el Correo";
		}
		if (!password) {
			passwordError = "Ingresa la Contraseña";
		}
		setErrors({ email: emailError, password: passwordError });
	}, [formValues, errorStatus]);

	async function onSubmit(value) {
		setLoadding(true);

		if (errors.email && errors.password) {
			setErrorStatus({ email: true, password: true })
			notification.warning({
				description: "Por favor, completa todos los campos",
				style: { fontWeight: 'bold' },
				placement: 'bottomLeft',
				duration: 1.5,
			});
			return;
		}
		if (errors.email) {
			setErrorStatus({ email: true, password: false })
			notification.warning({
				description: errors?.email,
				style: { fontWeight: 'bold' },
				placement: 'bottomLeft',
				duration: 1.5,
			});

			return;
		}
		if (errors.password) {
			setErrorStatus({ email: false, password: true })
			notification.warning({
				description: errors?.password,
				style: { fontWeight: 'bold' },
				placement: 'bottomLeft',
				duration: 1.5,
			});
			return;
		}

		setErrorStatus({ email: false, password: false })


		try {
			const res = await signIn(value?.email, value?.password);
			localStorage.setItem("tokenUser", res?.token);

			dispatch(
				onLogin({
					email: formValues?.email,
					token: res?.token,
					rol: res?.rol,
					permissions: res?.permissions,
					operator_detail: res?.operator_detail,
					user_detail: res?.user_detail,
				}))

			setTimeout(() => {
				history.replace("/dashboard");
				// window.location.reload()
			}, 1000);

		} catch (e) {
			setLoadding(false)
			notification.error({
				style: { fontWeight: 'bold' },
				message: e?.response?.data?.message,
				placement: 'bottomLeft',
				duration: 1.5,
				// onClose: () => {
				// 	setUser({})
				// }
			})

		}
	};

	return (
		<Container>
			<div className="left">
				<div className="content__">
					<div className="logo">
						<img src={LOGO} alt="" />
					</div>
					<h3>Bienvenido</h3>
					<p>Sistema dedicado a la administración de órdenes de servicio que manejan los ISP. Cuyo objetivo principal es el tratamiento de datos e información, ayudando a incrementar la efectividad en la operación de las empresas.</p>
				</div>
			</div>

			<div className="right">
				<div className="content__">
					<span className="textLogin">Inicio de sesión</span>
					<p>Registre los datos de su cuenta de Usuario, para ingresar al Sistema.</p>
					<b style={{ marginBottom: "11px" }}>Correo electrónico <span className="blue">*</span></b>
					<Input
						onChange={onChange}
						className="input"
						name="email"
						value={formValues?.email}
						status={errorStatus?.email === true ? "error" : ""}
						style={{
							border: errorStatus?.email === true ? "1px solid red" : "none"
						}}
					/>

					<b style={{ marginBottom: "11px", marginTop: "19px" }}>Contraseña <span className="blue">*</span> </b>
					<Input
						onChange={onChange}
						className="input"
						name="password"
						value={formValues?.password}
						status={errorStatus?.password === true ? "error" : ""}

						type={showPass ? "password" : "text"}
						addonAfter={
							showPass
								? <EyeInvisibleOutlined color='#959595' onClick={() => setShowPass(!showPass)} />
								: <EyeOutlined color='#959595' onClick={() => setShowPass(!showPass)} />
						}

						style={{
							border: errorStatus?.password === true ? "1px solid red" : "none"
						}}
					/>

					<Button
						size="large"
						type="primary"
						onClick={() => onSubmit(formValues)}
						className="button__"
						style={{ marginTop: "30.74px" }}
					>
						Ingresar
					</Button>
				</div>

			</div>
		</Container >
	);
}

const Container = styled.div`
	width: 100vw;
	min-height: 100vh;
	position: absolute;
	top:0;
	left:0;
	display: flex;
	box-sizing: border-box;
	overflow: hidden;

	.left {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		background-image: url(${bgLogin}), linear-gradient(#0060FF, #0e2441);
		background-repeat: no-repeat;
		background-size: cover;
	}
	.left .content__ {
		max-width: 600px;
		height: 800px;
		margin-left: 0;
    	padding: 0 0; 
	}

	.left .content__ .logo {
		height: 250px;
		display:flex;

	}
	.left .content__ .logo img{
		width: 300px;
		object-fit: contain;
	}

	.left .content__  h3 {
		color: white;
		font-size: 50px;
		font-weight: 400;
		margin-top: 25px;
		width: 100%;
	}

	.left .content__ p {
		color: white;
		padding: 0;
		margin: 0;
		font-size: 12px;
		font-weight: 300;
		width: 420px;
	}
	// ----------------------------
	// ----------------------------
	.right {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #F3F3F3;
	}
	.right .content__ {
		width: 418px;
		height: 600px;
		margin-left: 0;
    	padding: 0 0; 
		display: flex;
		justify-content: center;
		flex-flow: column;
	}
	
	.right .content__ .textLogin {
		font-size: 25px;
		color: #001737;
		margin-bottom: 12.93px;
		font-weight: bold;
	}
	.right .content__ p {
		display: inline;
		color: #5A607F;
		font-size: 12px;
	}
	.right .content__ .input{
		height:40px;
		width: 418px;
		border: none !important;
		outline: none !important;
		border-radius: 5px;
		color: #868D96;
		font-size: 12px;
	}
	.button__{
		width: 418px;
		height: 40px;
		border-radius: 3px;
		color: #FFFFFF !important;
		background: #2B80FF !important;
		font-size: 12px;
	}
	.blue{
		color: #2B80FF;
		font-size: 12px;
	}
	.error_input{
		color: grey;
		font-size: 11px;
	}

	// ---------ANT INPUT---------

	.ant-input-group .ant-input-group-addon {
		color: rgba(0, 0, 0, 0.88);
		font-weight: normal;
		font-size: 14px;
		background-color: white;
		border: none;
		outline: none;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		transition: all 0.3s;
		heigth: 40px;
}
.ant-input-group-wrapper {
    display: flex;
	justify-content: center;
	align-items: center;
    width: 100%;
	heigth: 40px;
	background-color: white;
	border: none !important;
	outline: none !important;
	padding: 0;
	margin: 0;
}

.ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 40px;
	border: none !important;
	outline: none !important;
}

.ant-input-group .ant-input {
    width: 100%;
    margin-bottom: 0;
    height: 40px;
	border: none !important;
	outline: none !important;
}
	
	
	// ---------MEDIA QUERYS---------

		@media (max-width: 1337px) {
			flex-flow: column;

			.left .content,.right .content {
				min-width: 100vw;
				padding: 2em !important;
			}
		}

		@media (max-width: 350px) {
			.left .content .logo img{
				width: 100% !important;
				height: 200px !important;
			}
		}

		@media (max-width: 330px) {
			.left .content  h3 {
				font-size: 30px !important;
				margin-top: 0 !important;
			}
			.left .content p {
					font-size: 18px !important;
			}
		}
	`;

