import moment from 'moment';
import React from 'react'
import { notification } from 'antd'
import { myFetch, myFetchGet } from "../services/services"
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import DatatableRowText from '../components/text/datatable/datatableRowText';
import ColorCircle from '../components/colorCircle';
import BlueButton from '../components/buttons/blueButton';


const ostype_datatables_struct = async () => {
    try {
        const data = await myFetchGet("api/v1/ostype/datatables_struct");
        const columnsTittles = Object.keys(data.columns);

        const columns = [];
        columnsTittles.forEach((columnsTittle) => {
            columns.push({
                sorter: columnsTittle === "ID" ? true : null,
                width: columnsTittle === "ID" ? "50px" : "",
                title: <DatatableHeaderText value={columnsTittle} />,
                dataIndex: data.columns[columnsTittle].field,
                align: "left",
                key: data.columns[columnsTittle].field,
                render: columnsTittle === "ID"
                    ? (value) =>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <DatatableRowText value={value} />
                        </span>

                    : columnsTittle === "Nombre de la OS" ?
                        (value) =>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <DatatableRowText color="#2B80FF" value={value} />
                            </span>

                        : columnsTittle === "Color" ?
                            (value) =>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <ColorCircle color={value} />
                                    <DatatableRowText value={value} />
                                </span>

                            : (value) =>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <DatatableRowText value={value} />
                                </span>

            }
            );
        });
        return columns
    } catch (e) { console.log("error", e) }
}

const postingColorData = async (data) => {
    try {
        await myFetch("api/v1/ostype/", data)
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'Tipo de Orden de Servicio creada',
            placement: 'bottomLeft',
            onClose: () => window.location.reload()
        })
    } catch (error) {
        notification.error({
            style: { fontWeight: 'bold' },
            message: 'No se pudo crear el tipo de Orden de Servicio',
            placement: 'bottomLeft',
        })
    }
}
const patchColorData = async (ID, data, goToList) => {
    try {
        await myFetch(`api/v1/ostype/${ID}/`, data, "PATCH");
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'Editado con Exito!',
            placement: 'bottomLeft',

            // aqui realizar goBack
            onClose: () => goToList()
        });
    } catch (e) { console.log("error", e) }
}

const deleteOstype = async (ID) => {
    try {
        await myFetch(`api/v1/ostype/${ID}/`, "", "DELETE");
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'El registro se ha borrado con éxito',
            placement: 'bottomLeft',
            onClose: () => window.location.reload()
        });

    } catch (error) {

    }
}

const getcurrentAwaitTime = async (storedOperatorID) => {
    try {
        const res = await myFetchGet(`api/v2/operator-setting/${storedOperatorID}/operator_waiting_time_os_int/`)
        return res?.valor
    } catch (error) { console.log(error) }
}

const getOstypeHistory = async (ID, date) => {
    try {
        return await myFetchGet(`api/v1/ostype/${ID}/history/`)
    } catch (error) { }
}

async function getCategoriesColumn() {
    try {
        const data = await myFetchGet("api/v1/category/datatables_struct");

        const columnsTittles = Object.keys(data.columns);
        const columns = [];
        columnsTittles.forEach((columnsTittle) => {

            columns.push({
                title: <DatatableHeaderText value={columnsTittle} />,
                dataIndex: data.columns[columnsTittle].field,
                align: "left",
                key: data.columns[columnsTittle].field,
                ...((columnsTittle) => {
                    if (columnsTittle === 'Nombre') {
                        return {
                            sorter: (a, b) => a.name.localeCompare(b.name),
                        };
                    }
                    return {};
                    
                })(columnsTittle),
                // sorterTooltip: columnsTittle === "ID" ? 'Haz clic para ordenar por nombre' : null,
                sorterTooltip: 'Haz clic para ordenar por nombre',
                width:
                    columnsTittle === "ID" ? "80px"
                        : columnsTittle === "Nombre" ? "200px"
                            : columnsTittle === "Duración" ? "100px"
                                : "",

                render: columnsTittle === "ID"
                    ? (value) => <DatatableRowText value={value} />

                    : columnsTittle === "Nombre" ?
                        (value) =>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <DatatableRowText color='#2B80FF' bold={true} value={value} />
                            </span>

                        : columnsTittle === "Duración" ?
                            (value) =>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <DatatableRowText value={value} />
                                    <DatatableRowText value="min." />
                                </span>

                            : columnsTittle === "Imágenes a solicitar" ?
                                (value) => value.map(item => {
                                    return (
                                        <span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
                                            <DatatableRowText value={item.img_name} />
                                        </span>
                                    )
                                })

                                : columnsTittle === "Preguntas" ?
                                    (value) => value.map(item => {
                                        return (
                                            <span key={Math.random()} style={{ height: 30, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <DatatableRowText value={item.question} />
                                                <BlueButton value={item.kind === 1 ? "Si o No" : "Abierta"} />
                                            </span>
                                        )
                                    })


                                    : (value) =>

                                        <span style={{ display: "flex", alignItems: "center" }}>
                                            <DatatableRowText value={value} />
                                        </span>
            });
        });
        return columns
    } catch (e) { }
}

async function getCategoriesRow(os_type) {

    const filter = {
        "os_type": os_type,
        "offset": 10,
        "start": 0
    }


    try {
        const res = await myFetch("api/v1/category/datatables/", filter);
        if (res) {
            if (res.data) {
                const rows = res.data.map((row) => {
                    const imgsData = row.imgs.value;
                    const questions = row.questions.value;
                    const dateFormat = moment(row.created).format("DD-MM-YYYY");
                    return {
                        ...row,
                        key: row.ID,
                        imgs: imgsData,
                        questions: questions,
                        created: dateFormat,
                    };
                });
                return rows
            }
        }
    } catch (e) {
        console.log("error", e);
    }
}

const addNewCategory = async (data) => {
    try {
        await myFetch("api/v1/category/", data)
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'creado con éxito',
            placement: 'bottomLeft',
            onClose: () => window.location.reload()
        });
    } catch (error) { }
}

export {
    // ostype_datatables,
    ostype_datatables_struct,
    postingColorData,
    patchColorData,
    deleteOstype,
    getcurrentAwaitTime,
    getOstypeHistory,
    getCategoriesColumn,
    getCategoriesRow,
    addNewCategory
}