/* eslint-disable */
import { Button, Space, Switch, Popconfirm, notification, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { FormOutlined } from "@ant-design/icons";

import { myFetch, myFetchGet } from '../../../services/services'
import Br from '../../../components/br/Br';
import { permissionCheck } from '../../../helpers/helpers';
import { useSelector } from 'react-redux';
import './index.scss'

const QuestionsPanel = (props) => {
	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const [value, setValue] = useState('');
	const [showButtons, setShowButtons] = useState(false)
	const [loadding, setLoadding] = useState(false)

	const [preguntas, setPreguntas] = useState([])
	const [questionsSelect, setQuestionsSelect] = useState([])
	const [questionData, setQuestionData] = useState([])
	const [request, setRequest] = useState(false)
	const [idResponse, setIdResponse] = useState(false)
	const [inputQuestion, setInputQuestion] = useState('')
	const [valueSwitch, setValueSwitch] = useState(false)

	const { id_unico, category_questions } = props?.data;

	const sendRequestFormat = {
		questions: {
			value: questionsSelect,
		},
		ref_os: id_unico
	}

	const getData = async () => {
		setLoadding(true)
		try {
			setPreguntas(category_questions.value)
			const responses = await myFetchGet(`api/v1/responseos/?ref_os=${id_unico}`);
			if (responses.length === 0) {
				// si el elemto esta vacio se realizara peticion post
				const responseQs = category_questions.value.map((item) => {
					return {
						...item,
						value: item.kind !== 2 ? false : ''
					};
				});
				setQuestionsSelect(responseQs)
				setRequest(true)

				const inputResponse = responseQs.map(item => {
					if (item.kind === 2 && item.question !== "Comentarios adicionales") {
						return item.value
					}
				})

				setInputQuestion(inputResponse)

				const responsesTextArea = responseQs.map(item => {
					if (item.kind === 2 && item.question === "Comentarios adicionales") {
						setValue(item.value)
					}
				})

			} else {
				setRequest(false)
				const responsesQuestion = responses.map((item) => {
					setIdResponse(item.ID)
					return item.questions.value
				})

				setQuestionsSelect(responsesQuestion[0])

				const inputResponse = responsesQuestion[0].map(item => {
					if (item.kind === 2 && item.question !== "Comentarios adicionales") {
						return item.value
					}

					if (item.kind === 1) {
						setValueSwitch(item.value)
					}
				})

				setInputQuestion(inputResponse)

				const responsesTextArea = responsesQuestion[0].map((item) => item.value)
				setValue(responsesTextArea[responsesTextArea.length - 1])
			}
			setLoadding(false)
		} catch (e) {
			setLoadding(false)
		}
	}

	const onChange = (id, value) => {
		const response = questionsSelect?.map((item, index) =>
			item.kind === 1 &&
				id === index
				? { ...item, value: value }
				: item
		)

		setQuestionsSelect(response)
	};

	const handleText = () => {
		const response = questionsSelect?.map((item) =>
			item.kind === 2 &&
				item.question === "Comentarios adicionales"
				? { ...item, value: value }
				: item
		)
		setQuestionsSelect(response)
	};


	useEffect(() => {
		handleText()
	}, [value])

	useEffect(() => {
		getData()
	}, [])


	const clearToInitialState = () => {
		// setValue("")
		setShowButtons(!showButtons)
		getData()
		// handleText()
	}

	const showHideButtons = () => {
		setShowButtons(!showButtons)

		if (showButtons !== false) {
			// setValue("")
			getData()
			// handleText()
		}

	}

	const btnDinamicStyle = {
		background: showButtons ? "#0060FF" : "#DDE4EB",
		color: showButtons ? "white" : "black",
		border: "2px solid #0060FF"
	};

	const dinamicColorIcon = {
		color: showButtons ? "white" : "#0060FF",
	};

	const updateData = async () => {
		try {
			if (request === true) {
				const create = await myFetch(`api/v1/responseos/`, sendRequestFormat)
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Respuestas Creadas',
					placement: 'bottomLeft',
					duration: 2,
					onClose: () => {
						window.location.reload()
					}
				})

			} else {
				const update = await myFetch(`api/v1/responseos/${idResponse}/`, sendRequestFormat, 'PATCH')
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Respuestas actualizadas',
					placement: 'bottomLeft',
					duration: 2,
					onClose: () => {
						window.location.reload()
					}
				})
			}

		} catch (error) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'ha ocurrido un error inesperado',
				placement: 'bottomLeft',
				duration: 2,
				onClose: () => {
					window.location.reload()
				}
			})
			console.log(error)
		}
	}

	const handlerInputText = (index, { target }) => {
		const updateData = [...inputQuestion];
		updateData[index] = target.value;
		setInputQuestion(updateData);
	}

	const handleSubmit = () => {
		const updatedQuestions = questionsSelect.map((question, index) => {
			if (question.kind === 2) {
				return { ...question, value: inputQuestion[index] }
			} else {
				return question;
			}
		})

		// actualizar questionsSelect 
		setQuestionsSelect(updatedQuestions);
	}
	useEffect(() => {
		handleSubmit();
	}, [inputQuestion])


	useEffect(() => {
		const initialValues = new Array(questionsSelect.length).fill('');
		setInputQuestion(initialValues);
	}, [])


	return (
		<span className="container__questions">
			<div className='question_tab'>
				<span className="question_row">
					<p className='single__text__description'>
						Listado de preguntas asociadas a la categoría de la orden de servicio.
					</p>

					{
						rolPermission("change_responseos") === true ? (
							<Button style={btnDinamicStyle}
								className="btn__questions"
								onClick={showHideButtons}
								icon={<FormOutlined style={dinamicColorIcon} />}
							>Modificar
							</Button>
						) : null
					}

				</span>

				{
					questionsSelect?.map((item, i) => {
						return (
							<div key={i}
								className={i % 2 === 0 ? 'columna__questions intervalo__question'
									: 'columna__questions'}

							>
								<div className='content__box__questions'>


									{
										item.kind === 2
											? <div className='question-kind'>
												{
													item.question === "Comentarios adicionales"

														? <span className='flow__column'>
															<Br />
															<b className='item__question__margin font_size_14'>{item.question}</b>
															{item.question === "Comentarios adicionales" &&
																<TextArea
																	className='font_size_14'
																	value={value}
																	onChange={(e) => setValue(e.target.value)}
																	autoSize={{ minRows: 6, maxRows: 8 }}
																	disabled={showButtons === false ? true : false}
																/>
															}
															<Br />
														</span>


														: <div className='flow__column'>
															<Br />
															<span className='item__question__margin'>{item.question}</span>
															{item.question !== "Comentarios adicionales" &&
																<Input
																	className='font_size_14'
																	onChange={(value) => handlerInputText(i, value)}
																	value={inputQuestion[i]}
																	disabled={!showButtons}
																/>
															}
															<Br />
														</div>

												}
											</div>

											: <div className='question-class'>
												{
													item.question === "Comentarios adicionales"
														? <b className='item__question__margin'>{item.question}</b>
														: <label className='lable_font_size'>{item.question}</label>
												}
											</div>}

								</div>

								<div className="row__questions">
									{

										item.kind === 1 ? (

											<span className='switch__box__content'>
												<span className='text_switch'>No</span>
												<Switch
													checked={item.value}
													onChange={(value) => onChange(i, value)}
													disabled={!showButtons}
												/>
												<span className='text_switch'>Si</span>
											</span>
										) : null}
								</div>
							</div>
						)
					})
				}

				{
					showButtons && (
						<div className="buttons_row">
							<div className='button_section_detail'>
								{
									rolPermission("change_responseos") === true ? (
										<>
											<button className='btn_cancelar_question'
												onClick={clearToInitialState}
											>
												Cancelar
											</button>
											<Popconfirm
												placement="topRight"
												icon={""}
												title="¿Modificar información?"
												description={() => {
													return (
														`Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones.`)
												}}
												onConfirm={updateData}
												okText="Guardar"
												cancelText="Cancelar"
											>
												<button className='btn_guardar_question'>
													Guardar
												</button>
											</Popconfirm>
										</>
									) : null
								}
							</div>
						</div>
					)
				}
			</div>
		</span>
	)
}

export default QuestionsPanel