/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomModal from "../../components/modal/CustomModal";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import FormularioOS from "./components/FormularioOS";
import UseOperator from "../../hooks/UseOperator";
import { checkIfUserGetOraculo, getOs, getOScalendarByWeek, getOstype, getTechnician } from "../../api/calendar";
import { useHistory } from "react-router-dom";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

import getMessagesES from "../../helpers/getMessages";
import moment from "moment";
import { notification, Select, Tooltip } from "antd";
import { permissionCheck, stringCapitalize } from "../../helpers/helpers";
import { myFetch, myFetchGet } from "../../services/services";
import ColorCircle from "../../components/colorCircle";
import { useDispatch, useSelector } from "react-redux";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { selectTab } from "../../store/tab/tabSlice";
import FormOverflow from "./components/FormOverflow";

// import './ServiceOrdersCalendar.scss'

export default function ServiceOrdersCalendar() {

	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const array = reduxState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const history = useHistory();

	const currentMonth = moment().format('MM');

	const [loadding, setLoadding] = useState(true)
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [selectdFiltre, setSelectdFiltre] = useState("name");

	const [osList, setOsList] = useState([])
	const [typeOSList, setTypeOSList] = useState([]);
	const [technicians, setTechnicians] = useState([])

	const [checkIfOraculo, setCheckIfOraculo] = useState(null)
	const [showSpecification, setShowSpecification] = useState(false)

	const [technicianID, setTechnicianID] = useState(null)
	const [osID, setOsID] = useState(null)

	// calendar
	const [currentView, setCurrentView] = useState("week");
	const [monthSelected, setMonthSelected] = useState(currentMonth)
	const [yearSelected, setYearSelected] = useState("2023")

	const [OScalendarWeek, setOScalendarWeek] = useState(null)
	const [tooltipData, setTooltipInfo] = useState(null)
	const [daySelected, setDaySelected] = useState("")

	// form overflow
	const [visibleFormOverflow, setVisibleFormOverflow] = useState(false);

	// data formOs a Form Overflow
	const [nameTechnican, setNameTechnican] = useState("");
	const [technicianIDForm, setTechnicianIDForm] = useState(null);
	const [randomTechnican, setRandomTechnican] = useState(false);
	const [dateSelectedForm, setDateSelectedForm] = useState(null);
	const [categorySelected, setCategorySelected] = useState(null);


	const [selectedHoursOverflow, setSelectedHoursOverflow] = useState(null);
	const [disponibilidadIDOverflow, setDisponibilidadIDOverflow] = useState(null);
	const [technicianOverflow, setTechnicianOverflow] = useState(null);






	const localizer = momentLocalizer(moment);
	const { storedOperatorID } = UseOperator()
	const dispatch = useDispatch()
	// const { Option } = Select;

	const onModalClose = () => setIsVisibleForm(!isVisibleForm)
	const onViewChange = view => setCurrentView(view);

	const getCalendarData = () => {
		storedOperatorID && getOScalendarByWeek(storedOperatorID, monthSelected, yearSelected).then(res => {
			setOScalendarWeek(res)
			const toolTipInfo = res?.reduce((a, c) =>
				a?.some(x => x?.title === c?.title)
					? a
					: [...a, c], [])
			setTooltipInfo(toolTipInfo)
		})
	}

	useEffect(() => {
		loginOperadorID && getOs(loginOperadorID).then(res => setOsList(res))
		loginOperadorID && getOstype(loginOperadorID).then(res => setTypeOSList(res))
		loginOperadorID && getTechnician(loginOperadorID).then(res => setTechnicians(res))
		loginOperadorID && checkIfUserGetOraculo(loginOperadorID).then(res => setCheckIfOraculo(res))
		getCalendarData()

		setLoadding(false)
	}, [loginOperadorID, monthSelected,])

	const filterByOStypeAndTecnician = async () => {
		setOScalendarWeek(null)
		try {
			const res = await myFetchGet(`api/v1/calendar_os/?day=${daySelected}&operator=${loginOperadorID}&technician=${technicianID}&month=${monthSelected}&year=${yearSelected}&os_type=${osID}`)
			setOScalendarWeek(res?.data)
		} catch (error) {
			if (error.response.status === 400) {
				setOScalendarWeek(null)
				// getCalendarData()
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'No hay resultado con esa combinación Orden de servicio y Técnico',
					placement: 'bottomLeft',
					duration: 2.5,
					onClose: () => {
						setOScalendarWeek(null)
						getCalendarData()
					}
				})
				// notification.success()
			}
		}
	}

	const filterByOStype = async () => {
		setOScalendarWeek(null)
		try {
			const res = await myFetchGet(`api/v1/calendar_os/?operator=${loginOperadorID}&month=${monthSelected}&year=${yearSelected}&os_type=${osID}`)
			setOScalendarWeek(res?.data)
		} catch (error) {
			if (error?.response?.status === 400) {
				setOScalendarWeek(null)
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'No hay resultado con esa Orden de servicio',
					placement: 'bottomLeft',
					duration: 2.5,
					onClose: () => {
						setOScalendarWeek(null)
						getCalendarData()
					}
				})
			}
		}
	}

	const filterByTecnician = async () => {
		setOScalendarWeek(null)
		try {
			const res = await myFetchGet(`api/v1/calendar_os/?operator=${loginOperadorID}&technician=${technicianID}&month=${monthSelected}&year=${yearSelected}`)
			setOScalendarWeek(res?.data)
		} catch (error) {
			if (error.response.status === 400) {
				setOScalendarWeek(null)
				// getCalendarData()
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'No hay resultado con ese Técnico',
					placement: 'bottomLeft',
					duration: 2.5,
					onClose: () => {
						setOScalendarWeek(null)
						getCalendarData()
					}
				})
			}

		}
	}

	useEffect(() => {
		if (loginOperadorID !== null) {
			if (osID !== null) {
				filterByOStype()
			}
			if (technicianID !== null) {
				filterByTecnician()
			}
			if (technicianID !== null && osID !== null) {
				filterByOStypeAndTecnician()
			}

		} else {
			return;
		}

	}, [osID, technicianID])


	useEffect(() => {
		checkIfOraculo && setShowSpecification(checkIfOraculo === 404 ? false : true)
	}, [checkIfOraculo])

	const events = OScalendarWeek?.map((item) => {
		return {
			id: item?.os_sequential_id,
			id_unico: item?.os_unique_id,
			title: item?.title,
			start: moment(item?.start, "DD-MM-YYYY HH:mm").toDate(),
			end: moment(item?.end, "DD-MM-YYYY HH:mm").toDate(),
			color: item?.color,
		}
	})

	const eventStyleGetter = (event) => {
		const style = {
			backgroundColor: event.color,
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
		};
		if (event.type === 'current-date-line') {
			style = {
				...style,
				className: "custom__line",
			};
		}
		return {
			style,
		};
	};

	const onOStypeChange = (value) => setOsID(value);
	const onTechnicianChange = (value) => setTechnicianID(value);

	useEffect(() => {
		handleNavigate();
	}, []);

	const handleNavigate = (date) => {
		const formatedDate = moment(date).format("DD-MM-YYYY")

		const momentObj = moment(formatedDate, "DD-MM-YYYY");
		const day = momentObj.format("DD");
		setDaySelected(day)
	};

	const CustomHeader = ({ label, onNavigate, onView, view }) => {
		return (
			<HeaderCalendar>
				<span className="left__calendar">
					<Select
						className="select-calendar select-calendar-margin"
						placeholder="Orden de servicio"
						onChange={onOStypeChange}
						allowClear
					>
						{typeOSList?.map(item => <Select.Option key={item?.ID} value={item?.ID}>{item?.name}</Select.Option>)}
					</Select>

					<Select
						className="select-calendar"
						onChange={onTechnicianChange}
						placeholder="Técnico"
						allowClear
					>

						{technicians?.map(item =>
							<Select.Option key={item?.ID} value={item?.ID}>{item?.name} {item.last_name}</Select.Option>)
						}

					</Select>
				</span>

				<div className="mid__calendar">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</div>

				<div className="right__calendar">
					<span className={view === "day" ? "date__selected" : "date__btn"} onClick={() => onView("day")}>Dia</span>
					<span className={view === "week" ? "date__selected" : "date__btn"} onClick={() => onView("week")}>Semana</span>
					<span className={view === "month" ? "date__selected" : "date__btn"} onClick={() => onView("month")}>Mes</span>
				</div>

			</HeaderCalendar>
		)
	}

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			{tooltipData?.map((item, i) => {
				return (
					<div className="div-align" key={i}>
						<ColorCircle color={item?.color} />
						<span className="description">{item?.title}</span>
					</div>
				)
			})}
		</ContainerTooltip>
	)

	const minTime = new Date();
	minTime.setHours(7, 0, 0); // set minimum time to 7am

	const maxTime = new Date();
	maxTime.setHours(19, 0, 0); // set maximum time to 7pm

	const dataState = useSelector((state) => state.authState)

	useEffect(() => {
		if (rolPermission("datatable_view_os") === false) {
			history.replace("/dashboard")
		}
	}, [])

	// funcion para agregar orden de datos en los eventos
	const eventContent = ({ event }) => (
		<div>
			<label className="id_orden_services">#{event?.id} <b>{event.title}</b> </label><br />
			{/* <label className="orden_service_name">{event.title}</label><br /> */}
			<label className="orden_service_name">{moment(event.start).format('h:mm a')} - {moment(event.end).format('h:mm a')}</label> <br />
		</div>
	);

	// Funcion de data para datatable y direccionamiento a vista general de datatable
	const getOneOS = async (id) => {
		// variable de data enviada para el filtro de la peticion
		const dataFilter = {
			filters: `[[\"ID\",\"equal\", \"${id}\"]]`,
			operator: storedOperatorID,
		}
		const res = await myFetch("api/v1/os/datatables/", dataFilter)

		// cundo llegue al detalle, comienza en el primer Tab
		dispatch(selectTab({ selectedTab: 0 }))

		// funcion de react-router-dom para direccionar a vista general de Os
		history.push("/serviceOrdersDetails", { params: res.data[0] })
	}

	// funcion para recolectar el id del evento
	const handleSelectEvent = (event) => {
		getOneOS(event.id_unico)	// llamado funcion de filtrado por datatable por id
	}

	const onModalCloseOverflow = () => setVisibleFormOverflow(false)

	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm} loaddingData={loadding}>

			{/* permission = add_os */}
			<ContainerHeader>
				<PanelHeader
					noButton={rolPermission("add_os") === true ? false : true}
					typeButton="more"
					buttonText="Agregar orden de servicio"
				>
					<span className="title-calendar">Calendario de agendamiento</span>
					<Tooltip
						title={tooltipText}
						color='white'
						arrow={false}
						placement="bottomLeft"
					>
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>
					<span className="sub-tex-calendar">Orden de servicio | Calendario</span>
				</PanelHeader>
			</ContainerHeader>

			<Container>
				<Calendar
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					eventPropGetter={eventStyleGetter}
					defaultView={currentView}
					style={{ minHeight: "100vh" }}
					min={minTime}
					max={maxTime}
					views={["day", "week", "month"]}
					culture='es'
					popup
					messages={getMessagesES()}
					formats={{
						// columna izq. hora
						timeGutterFormat: 'h:mm a',

						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),

						// Dic 13 - Ene 19
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(moment(start).format('MMM D'))} - ${stringCapitalize(moment(end).format('MMM D'))}`
						),

						// Lun. 13/03
						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),

						monthHeaderFormat: (date) =>
							stringCapitalize(moment(date).format("MMMM YYYY")),

						eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
							localizer.format(start, "h:mm a", culture) +
							" - " +
							localizer.format(end, "h:mm a", culture),
					}}
					toolbar={true}

					components={{
						event: eventContent, // se renderiza el componente de estilos para el evento
						toolbar: CustomHeader
					}}
					tooltipAccessor={event => `\nID: ${event.id}\n${event.title}`}

					onNavigate={handleNavigate}
					onView={onViewChange}
					onSelectEvent={handleSelectEvent}
					even

				/>
				{
					isVisibleForm && (
						<CustomModal onClick={onModalClose}>
							<FormularioOS
								visible={isVisibleForm}
								setVisible={setIsVisibleForm}
								setLoadding={setLoadding}

								setFiltreValue={setSelectdFiltre}
								filtreValue={selectdFiltre}

								typeOSList={typeOSList}
								technicians={technicians}
								loginOperadorID={loginOperadorID}
								showSpecification={showSpecification}

								setVisibleFormOverflow={setVisibleFormOverflow}
								visibleFormOverflow={visibleFormOverflow}

								setNameTechnican={setNameTechnican}
								setRandomTechnican={setRandomTechnican}
								setDateSelectedForm={setDateSelectedForm}
								setCategorySelected={setCategorySelected}
								setTechnicianIDForm={setTechnicianIDForm}

								selectedHoursOverflow={selectedHoursOverflow}
								disponibilidadIDOverflow={disponibilidadIDOverflow}
								technicianOverflow={technicianOverflow}

								setSelectedHoursOverflow={setSelectedHoursOverflow}
								setDisponibilidadIDOverflow={setDisponibilidadIDOverflow}
								setTechnicianOverflow={setTechnicianOverflow}
							/>
						</CustomModal>
					)
				}
				{visibleFormOverflow && (
					<CustomModal onClick={onModalCloseOverflow}>
						<FormOverflow
							setVisibleFormOverflow={setVisibleFormOverflow}
							nameTechnican={nameTechnican}
							randomTechnican={randomTechnican}
							dateSelectedForm={dateSelectedForm}
							categorySelected={categorySelected}
							technicianIDForm={technicianIDForm}

							setSelectedHoursOverflow={setSelectedHoursOverflow}
							setDisponibilidadIDOverflow={setDisponibilidadIDOverflow}
							setTechnicianOverflow={setTechnicianOverflow}
						/>
					</CustomModal>
				)}


			</Container>
		</Panel>
	);
}

const Container = styled.div`
width: 100%;


// -----React-Big-Calendar------
.rbc-header {
    border: none !important;
}
.rbc-time-header-gutter {
	width: 75.9375px;
    min-width: 75.9375px;
    max-width: 75.9375px;
	background: #F7FAFC;
}


// ------VISTA DE MES/OFF------
.rbc-off-range-bg {
    background: white;
}
.rbc-day-bg {
    height: 135px;
}
.rbc-button-link {
  color: #8898AA;
	font-size: ${smallSizes.fontSize.fontSize15};
	margin-top: 8px;
	margin-right: 9px;
}
.rbc-row-segment .rbc-event-content{
	width: 100%;
}

// ------VISTA DE MES/OFF------

.rbc-current-time-indicator {
	border: 1px solid #11CDEF;
	// position: relative;
}
.rbc-event {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	height: 27px;
}

.rbc-current-time-indicator::after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 100%;
	left: -2px;
	bottom: -4px;
	background: #11CDEF;
	margin: 0;
	padding: 0;
	z-index: 10;
}
.rbc-day-slot .rbc-event-label {
	display: none;
    flex: none;
    padding-right: 0;
    width: 100%;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	width: 100%;
	height: 100%;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none;
	word-wrap: break-word;
	border-radius: 4px !important;
	font-size: ${smallSizes.fontSize.fontSize12};
	text-align: left;
	padding-right: 0;
	cursor: pointer;
	margin-top: 11px;

}

.rbc-day-slot .rbc-event-content label{
	cursor: pointer;
}

.rbc-day-slot .rbc-event-content .id_orden_services{
	// margin-bottom: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	margin-bottom: 6px;
	
}

.rbc-day-slot .rbc-event-content .orden_service_name{
	margin-bottom: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12};
	padding: 0 12px;
	color: #8898AA;
}

.rbc-time-column{
	min-height: 148%;
}

.rbc-month-header .rbc-header {
  font-size: ${smallSizes.fontSize.fontSize15};
  color: #8898AA;
}


@media screen and (min-width: ${devices.smallDesk}){

	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${smallSizes.fontSize.fontSize15};
		color: #8898AA;
	}

}

@media screen and (min-width: ${devices.mediumDesk}){

	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${mediumSizes.fontSize.fontSize15};
		color: #8898AA;
	}

}

@media screen and (min-width: ${devices.largeDesk}){

	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${largeSizes.fontSize.fontSize15};
		color: #8898AA;
	}
}
`;
const HeaderCalendar = styled.div`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	padding: 30px 20px 40px 20px;
	justify-content: space-between;

	button{
		border: none;
		outline: none;
	}

	.left__calendar{
		background: white;
	}
	
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid__calendar{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 17%;
	}
	.mid__calendar .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	.mid__calendar button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: 18px;
	}
	

	// ----------------------------------

	.right__calendar{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.right__calendar .date__btn{
		background: white;
		border-radius: 3px;
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize12};
		padding: 10px 17px;
		cursor: pointer;
	}
	.date__selected{
		background: #DDE4EB;
		border-radius: 3px;
		color: #131523;
		font-size: ${smallSizes.fontSize.fontSize12};
		padding: 10px 17px;
		cursor: pointer;
	}

	//-----------------------------------------

	.select-calendar{
		width: ${smallSizes.calendar.widthSelect};
	}

	.select-calendar-margin{
		margin-right: 8px;
	}

	.ant-select-selector {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	//--------------------------------------------------

	@media screen and (min-width: ${devices.smallDesk}){

	.mid__calendar .label{
		font-size: ${smallSizes.fontSize.fontSize18};
	}

	//-----------------------------------------------------------------

	.right__calendar .date__btn{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.date__selected{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	//-----------------------------------------------------------

	.select-calendar{
		width: ${smallSizes.calendar.widthSelect};
	}

	.ant-select-selector {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	}

	@media screen and (min-width: ${devices.mediumDesk}){

	.mid__calendar .label{
		font-size: ${mediumSizes.fontSize.fontSize18};
	}

	//-----------------------------------------------------------------

	.right__calendar .date__btn{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.date__selected{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	//-----------------------------------------------------------

	.select-calendar{
		width: ${mediumSizes.calendar.widthSelect};
	}

	.ant-select-selector {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	}

	@media screen and (min-width: ${devices.largeDesk}){

	.mid__calendar .label{
		font-size: ${largeSizes.fontSize.fontSize18};
	}

	//-----------------------------------------------------------------

	.right__calendar .date__btn{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.date__selected{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	//-----------------------------------------------------------

	.select-calendar{
		width: ${largeSizes.calendar.widthSelect};
	}

	.ant-select-selector {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	}
`;

const ContainerTooltip = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	// width: 180px;

	.tittle{
		color: #868E96;
		font-size: 14px;
		padding: 6px 0 12px 6px;
		// width: 100%;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
	}

	.div-align{
		display: flex;
		align-items: center;
		width: 100%;
		padding-left: 5px
	}

	.div-end{
		display: flex;
		align-items: center;
		width: 100%;
		padding-bottom: 10px;
		padding-left: 5px
	}

	.circle-technical-visit-without{
		background: #FD7D48;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-technical-visit-with{
		background: #F5C43C;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-migration{
		background: #003CB9;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-migration{
		background: #003CB9;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-installation{
		background: #8FD14F;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-retreat{
		background: #6C6C6B;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}

	.circle-transfer{
		background: #860AC5;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}
`;

const ContainerHeader = styled.div`

	.title-calendar{
		color: #001737;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle-tooltip{
		margin: 0 10px;
		color: #00388B;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub-tex-calendar{
		color: #001737;
		font-weight: 400;
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	@media screen and (min-width: ${devices.smallDesk}){

		.title-calendar{
			font-size: ${smallSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${smallSizes.fontSize.fontSize12}
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){

		.title-calendar{
			font-size: ${mediumSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${mediumSizes.fontSize.fontSize12}
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){

		.title-calendar{
			font-size: ${largeSizes.fontSize.fontSize24};
		}

		.circle-tooltip{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.sub-tex-calendar{
			font-size: ${largeSizes.fontSize.fontSize12}
		}
	}
`;