import React from 'react'
import './ViewPlanSelect.scss'
import Br from '../../../../components/br/Br.jsx'
import { useState } from 'react'

const ViewPlanSelect = ({ data, showSpecification,  }) => {
	const [selectButton, setSelectButton] = useState(1)

	const services = {
		1: 'Internet',
		2: 'Televisión',
		3: 'Telefonía'
	}

	return (
		<>
		{!showSpecification ? (
			<div className='view_plan_container'>
				<span className='container_text_plan'>
					<label className='text_plan'>Plan contratado</label>
				</span>

				<span className='container_specification'>
					<label className='title_specification'>Especificaciones técnicas del plan</label>
					<label className='text_characteristics'>{ data.plan_name || "Sin plan"}</label>		
				</span>
			</div>
		):
		(
			<div className='view_plan_container'>
				<span className='container_text_plan'>
					<label className='text_plan'>Plan contratado</label>
					<label className='text_plan_14'>{data?.plan_name}</label>
					<label className='text_plan_blue'>{data?.plan_technology}</label>
				</span>

				<span className='container_specification'>
					<label className='title_specification'>Especificaciones técnicas del plan</label>
					<label className='text_characteristics'>Características técnicas asociadas al plan de servicio a constratar.</label>

					<span className='container_button' style={{ width: data.plan_type_integer === 2 ? `${data.plan_type_integer * 20}%` : `${data.plan_type_integer * 25}%` }}>
						{Object.keys(services).filter(key => key <= data.plan_type_integer).map((key) => (
							<button
								className={selectButton === parseInt(key) ? 'button_selected' : 'button_text'}
								onClick={() => setSelectButton(parseInt(key))}
								key={key}
							>
								{services[key]}
							</button>
						))}
					</span>

					{selectButton === 1 ? (
						<div className='container_plan_specification'>
							<span className='distribution_subcontainer'>
								<i className='fa-solid fa-arrow-up arrow_center' />
								<label className='text_plan_specification'>{data?.plan_megas_upload} mb</label>
							</span>
							<span className='distribution_subcontainer'>
								<i className='fa-solid fa-arrow-down arrow_center' />
								<label className='text_plan_specification'>{data?.plan_megas_download} mb</label>
							</span>
						</div>
					) : selectButton === 2 ? (
						<div className='container_television'>
							<label className='text_television'>{data?.plan_number_channels} <b>SD</b></label>
							<label className='text_television'>10 <b>HD</b></label>
						</div>
					) : selectButton === 3 ? (
						<div className='container_telephony'>
							<label className='text_telephony'>{data?.plan_phone_minutes} min llamadas locales</label>
						</div>
					) : null}
				</span>
			</div>
		)}
			<Br />
		</>
	)
}

export default ViewPlanSelect