import React, { useEffect, useState } from 'react'
import CustomModal from '../../../components/modal/CustomModal'
import styled from 'styled-components'
import { Select, notification } from 'antd';
import Br from '../../../components/br/Br'
import { myFetch, myFetchGet } from '../../../services/services'
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue'
import LabelDrawer from '../../../components/text/drawer/label'
import MainTitle from '../../../components/text/drawer/mainTitle'
import Form from '../../../components/form/index.jsx';
import BottomSection from '../../../components/form/bottomSection';
import './categoryEditForm.scss'

const CategoryEditForm = (
	{ selectItem, technicianID, setVisibleEditForm, typeOS }) => {

	// Extracting only the id values
	const idArray = selectItem?.categories_name?.map(item => item.id);
	const [newCategories, setNewCategories] = useState(idArray)
	const [allCategories, setAllCategories] = useState(null)

	const { Option } = Select;
	const newTypeOS = selectItem?.os_type?.id

	const closeForm = () => setVisibleEditForm()

	async function getCategory() {
		try {
			const res = await myFetchGet(`api/v1/category/?os_type=${newTypeOS}`)
			setAllCategories(res)
		} catch (error) { }
	}

	useEffect(() => {
		getCategory()
	}, [newTypeOS])

	const onCategoryChange = (value) => setNewCategories(value)

	const editData = {
		'technician': technicianID,
		'option': 'modificar', // eliminar - modificar - agregar
		'category': newCategories,
		'ostype': newTypeOS
	}

	const onEdit = async () => {
		try {
			await myFetch(`api/v1/modify_category_technician/`, editData);
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Registro editado con éxito',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => window.location.reload()
			});
		} catch (e) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'No se pudo editar el registro',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => window.location.reload(),
			});
		}
	}

	const titleForm = "Modificar tipo de orden de servicio"

	return (
		<CustomModal onClick={closeForm}>
			<Form>
				<MainTitle value={titleForm} onClick={closeForm} />
				<SubtitleBlue value="Información general" />

				<LabelDrawer value="Orden de servicio" />

				<Select
					style={{ height: 40 }}
					defaultValue={newTypeOS}
					disabled
				>
					{typeOS?.map(item =>
						<Option
							key={item?.ID}
							value={item?.ID}>
							{item?.name}
						</Option>)
					}
				</Select>

				<Br />

				<LabelDrawer value="Categorías" />
				
				<div className="form__edit__category">
					<Select
						placeholder="Categorías"
						mode="multiple"
						onChange={onCategoryChange}
						defaultValue={newCategories}
						className='select__custom__style'
					>
						{allCategories?.map(item =>
							<Select.Option
								key={item?.ID}
								value={item?.ID}>
								{item?.name}
							</Select.Option>)}
					</Select>
				</div>

				<BottomSection onAcept={onEdit} onCancel={closeForm} />
			</Form>
		</CustomModal >
	)
}

export default CategoryEditForm

const Container = styled.div`

.row{
	width: 100%;
	display: flex;
	align-items: center;
    margin: 0 !important;
	padding: 0 !important;
}
.column{
	width: 100%;
	display: flex;
    flex-flow: column;
}
.row_center{
    justify-content: center;
  }
.flex-end{
    justify-content: flex-end;
}
.space-between{
	justify-content: space-between;
}
.column-between{
	align-items: space-between;
}


.bottom_section{
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  .btn_normal{
    background: transparent;
     z-index:10;
    :hover{
      background: silver;
    }
  }
  .btn_silver{
    background: #2B80FF;
    color: white;
     z-index:10;
    :hover{
      background: #2B80FF;
    }
  }

// ----------------------------------------


`