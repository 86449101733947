
import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { Panel } from "../../../components/panel/panel";
import { Button, Input, Popconfirm } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import Br from "../../../components/br/Br.jsx";
import UseOperator from "../../../hooks/UseOperator";
import { patchColorData } from "../../../api/ostypeCreate";
import SubtitleBlue from "../../../components/text/drawer/subtitleBlue";
import LabelDrawer from "../../../components/text/drawer/label";
import { permissionCheck } from "../../../helpers/helpers";
import { useSelector } from "react-redux";
import SingleButton from "../../../components/buttons/singleButton";


export default function OverallDetailsScreen({ data }) {
	const history = useHistory();
	const [showColorPicker, setShowColorPicker] = useState(false)
	const [newName, setName] = useState(data?.name)
	const [colorValuePicked, setColorPicked] = useState(data?.color);
	const [tag, setTag] = useState(data?.tag)

	const [editMode, setEditMode] = useState(false)

	const { storedOperatorID } = UseOperator()

	// Svg Icon
	const D = "M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"

	const goToList = () => history.replace("/TypesOS");


	const onColorPick = () => setShowColorPicker(!showColorPicker)

	const onEditMode = () => {
		setEditMode(!editMode)
		setShowColorPicker(false)
		setName(data?.name)
		setColorPicked(data?.color);
		setTag(data?.tag)
	}

	const onColorChange = (e) => {
		setColorPicked(e)
		setShowColorPicker(!showColorPicker)
	}

	const newType = {
		"name": newName,
		"color": colorValuePicked,
		"tag": tag,
		"operator": storedOperatorID
	}

	const updateColor = () => patchColorData(data?.id_unico, newType, goToList)

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const onCancelEdit = () => {
		setEditMode(false)
		setShowColorPicker(false)
		setName(data?.name)
		setColorPicked(data?.color);
		setTag(data?.tag)
	}

	return (
		<Panel>
			<SubCont1>
				<span style={{ display: "flex", justifyContent: "flex-end", marginTop:"-20px" }}>
					{
						rolPermission("change_ostype") === true ? (
							<SingleButton
								onClick={onEditMode}
								value='Modificar'
								iconClassName="fa-solid fa-pen-to-square"
							/>
						) : null
					}
				</span>

				<span className="column">
					<SubtitleBlue value="Información General" />

					<LabelDrawer value="Nombre" />
					<Input
						style={{ height: "40px" }}
						disabled={editMode ? false : true}
						onChange={(e) => setName(e.target.value)}
						value={newName}

					/>

					<Br />

					<span className="row space-between">

						<div className="left">
							<LabelDrawer value="Color" />

							<div className="inputContainer"
								style={{
									background: editMode === true ? "white" : "rgba(0, 0, 0, 0.04)",
									cursor: editMode === true ? "default" : "not-allowed"
								}}
							>
								<div className="selected_color">
									<div
										className="circle"
										onClick={editMode === true ? onColorPick : () => { }}
										style={{
											background: colorValuePicked,
											cursor: editMode === true ? "pointer" : "default"
										}}
									></div>

									<p>{colorValuePicked}</p>
								</div>

								<div className="colorPickerIcon"
									style={{
										cursor: editMode === true ? "pointer" : "default"
									}}
								>
									<svg
										viewBox="0 0 512 512"
										onClick={editMode === true ? onColorPick : () => { }}
									>
										<path d={D} />
									</svg>
								</div>
							</div>

							<div style={{ display: showColorPicker ? "block" : "none" }}>
								<input
									onChange={e => onColorChange(e.target.value)}
									type="color"
									className="inputColor"
									defaultValue={data?.color}
								/>
							</div>

						</div>

						<div className="right">
							<LabelDrawer value="Etiqueta" />
							<Input
								disabled={editMode === true ? false : true}
								style={{ height: "40px" }}
								className="input"
								onChange={(e) => setTag(e.target.value)}
								value={tag}
							/>
						</div>
					</span>
				</span>

				<Br />
				<Br />

				<div className="row flex-end">

					{editMode ? (
						<div className="row space-between" style={{ width: "230px" }}>
							<SingleButton
								value='Cancelar'
								onClick={onCancelEdit}
								background="white"
								hoverBackground="white"
								hoverColor="#001737"
								hoverBorder="1px solid #BFCCDA"
							/>
							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Modificar información?"
								description={() => {
									return (
										`Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones.`)
								}}
								onConfirm={updateColor}
								okText="Guardar"
								cancelText="Cancelar"
							>
								<SingleButton value='Guardar' />
							</Popconfirm>

						</div>
					) : (
						<div className="row space-between" style={{ width: "180px", height: "40px" }}></div>
					)

					}

				</div>

			</SubCont1>
		</Panel>
	);
}

const SubCont1 = styled.div`
	background-color: white;
	display: flex;
	flex-flow: column;
	padding: 0 35px 20.19px 20.19px;

	p,h3, span, div, b{
		margin: inherit;
		display: inline;
	}
	p{
		display: inline;
	}
	
	.row {
		margin: inherit;
		display: flex;
		align-items: center;
	}
	.column {
		display: flex;
		flex-flow: column;
	}
	
	.space-between {
		display: flex;
		justify-content: space-between;
	}
	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.flex-end {
		display: flex;
		justify-content: end;
	}
	.title {
		color: #2B80FF;
		font-size: 14px; 
		font-weight: bold; 
	}
	.b {
		color: #2B80FF;
	}
	.left, .right {
		width: 49%
	}
	.inputContainer {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 5px;
		border: 1px solid #E0E0E0;
		position: relative;
	}
	.inputContainer .selected_color {
		width: calc(99% - 40px);
		height: 40px;
		padding: 1em;
		display: flex;
		align-items: center;
	}

	.inputContainer .selected_color .circle{
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}
	.inputContainer .colorPickerIcon, .inputContainer .colorPickerIcon svg {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.inputContainer .colorPickerIcon svg {
		width: 15px;
		height: 15px;
	}
	.inputColor {
		position: absolute;
	}
	.btn__ {
		width: 83px;
		height: 40px;
	}
	
	.btn__edit {
		width: 101px;
		height: 40px;
		border: 2px solid #0060FF;
	}
	
`;
