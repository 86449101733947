import { useEffect, useState } from 'react'
import { myFetchGet } from '../services/services'
import { useSelector } from 'react-redux'

const UseOperator = () => {
    const [operators, setOperators] = useState([])
    const reduxState = useSelector(state => state?.authState)
    const reduxOperatorId = reduxState?.operator_detail?.ID
    const reduxCountryId = reduxState?.operator_detail?.country

    const getOperator = async () => {
        try {
            const res = await myFetchGet("api/v1/userapp/list_operators/");
            setOperators(res)
            localStorage.setItem('operatorID', reduxOperatorId)
            localStorage.setItem('countryID', reduxCountryId)

        } catch (error) { }
    }

    useEffect(() => {
        getOperator()
    }, [reduxState])

    return {
        storedOperatorID: reduxOperatorId,
        operators,
        countryID: reduxCountryId
    }

}

export default UseOperator