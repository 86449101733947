import React from 'react'
import { myFetch, myFetchGet } from "../services/services";
import { notification, Popconfirm } from 'antd';
import ColorCircle from '../components/colorCircle';
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import DatatableRowText from '../components/text/datatable/datatableRowText';
import { capitalize } from '../helpers/helpers';

const technicianDatatables_struct = async () => {
	try {
		const data = await myFetchGet("api/v1/technician/datatables_struct/");
		const columnsTittles = Object.keys(data.columns);
		const columns = [];
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				key: data.columns[columnsTittle].field,
				dataIndex: data.columns[columnsTittle].field,
				sorter: columnsTittle === "ID" ? true : null,
				width: columnsTittle === "ID" ? "40px" : columnsTittle === "Status" ? "120px" : "",
				title: <DatatableHeaderText value={columnsTittle} />,
				align: "left",
				render: columnsTittle === "ID"
					? (value) => <DatatableRowText value={value} />

					: columnsTittle === "Nombre Completo" ?
						(value) =>
							<DatatableRowText value={capitalize(value)} color='#2B80FF' bold={true} />

						: columnsTittle === "Rol" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText
										color={value === "Supervisor" ? "#2B80FF" : "#172B4D"}
										value={value}
									/>
								</span>

							: columnsTittle === "Status" ?
								(value) =>
									<span style={{ display: "flex", alignItems: "center", width: "120px" }}>
										<ColorCircle color={value?.status_color} />
										<DatatableRowText value={value?.name} />
									</span>

								: (value) => <DatatableRowText value={value} />,
				filterMultiple: false,
				filters:
					columnsTittle === 'Status' ?
						[
							{
								text: "Activo",
								value: 0,
							},
							{
								text: "Inactivo",
								value: 1,
							},
						] : null,
				// onFilter: (value, record) =>  record.status?.name.indexOf(value) === 0,
			});
		});
		return columns
	} catch (e) {
		console.log("error", e);
	}
}

const onTechnicianPost = async (data) => {
	try {
		await myFetch("api/v1/technician/", data);
		notification.success({
			style: { fontWeight: 'bold' },
			message: "Técnico creado con éxito",
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		})

		return {
			message: "creado",
			status: 200,
		}


	} catch (error) {
		if (error?.response?.status === 400) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: "El correo ya existe",
				placement: 'bottomLeft',
				// duration: 1.5,
				// onClose: () => console.log("error", error)
			})
		}

		return {
			message: error?.response?.data?.message,
			status: error?.response?.status,
		}
	}
}

async function onUpdateTecnician(technicianID, data, goToList) {
	try {
		const res = await myFetch(`api/v1/technician/${technicianID}/`, data, "PATCH");
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Registro actualizado',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => goToList()
		})
		return res

	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Error',
			description: 'No se ha podido actualizar',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => console.log("error", e)
		})

	}
}
const getID_type = async (countryID) => {
	try {
		const res = await myFetchGet(`api/v1/choices_by_country/?country=${countryID}`);
		return res;
	} catch (e) { console.log("error", e) }
}
const getTechnicianStatus = async () => {
	try {
		const res = await myFetchGet(`api/v1/technician/choices/`);
		return res.status_tecnicos;
	} catch (e) { console.log("error", e) }
}

const getTechnicians = async (operatorID) => {
	try {
		return await myFetchGet(`api/v1/technician/?operator=${operatorID}`);
	} catch (e) { }
}

const getTechniciansCategoryDatatable = async (technicianID) => {
	const filter = {
		"filters": `[[\"ID\",\"equal\",\"${technicianID}\"]]`,
		"offset": 10,
		"start": 0
	}

	try {
		const res = await myFetch("api/v1/technician/datatables_categories/", filter);

		if (res?.data) {
			const rows = res?.data[0]?.map((row, i) => {
				return { ...row, key: i };
			});

			return rows
		}
	} catch (e) { }
}

const techniciansCategory_struct = async (renderOptions) => {
	try {
		const res = await myFetchGet("api/v1/technician/datatables_categories_struct/");
		const columns = [];
		const columnsTittles = Object.keys(res?.columns);
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				key: res?.columns[columnsTittle].field,
				title: <DatatableHeaderText value={columnsTittle} />,
				width: columnsTittle === "Tipo de orden de servicio" ? "360px" : "",
				dataIndex:
					columnsTittle === "Categorias"
						? "categories_name"
						: res?.columns[columnsTittle].field,

				render: columnsTittle === "Tipo de orden de servicio" ?
					(value) => (
						<span key={value?.id} style={{ display: "flex", alignItems: "center" }}>
							<ColorCircle color={value?.color} />
							<DatatableRowText value={value?.name} />
						</span>
					)

					: columnsTittle === "Categorias" ?
						(value) => value?.map(texto => (
							<span key={Math.random()} style={{ display: "flex" }}>
								<DatatableRowText value={texto?.name} />
							</span>
						))

						: (value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText value={value} />
							</span>,

				...((columnsTittle) => {
					if (columnsTittle === "Tipo de orden de servicio") {
						return {
							sorter: (a, b) => a?.os_type?.name.localeCompare(b?.os_type?.name),
						};
					}
					return {};
				})(columnsTittle),
			});
		});

		return [

			...columns,
			{
				title: <DatatableHeaderText value="Acción" />,
				dataIndex: "option",
				width: "300px",
				render: renderOptions,
			}

		]
	} catch (e) { }
};
// ----------------------------------
// ----------------------------------

const btnStyle = {
	border: "none",
	outline: "none",
	color: "#2B80FF",
	fontSize: 12,
	fontWeight: "bold",
	background: "transparent"
}


const postTechnicianTypeCategory = async (creteData) => {
	// Verificar si los datos son nulos o alguno de los campos es nulo
	if (!creteData || Object.values(creteData).some(value => value === null)) {
		// Datos nulos o algún campo es nulo, mostrar una notificación de error
		notification.warning({
			style: { fontWeight: 'bold' },
			message: 'Todos los campos son obligatorios',
			placement: 'bottomLeft',
			duration: 1.5
		});
		return; // Salir de la función
	}

	try {
		await myFetch("api/v1/modify_category_technician/", creteData)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'creado con éxito',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		});
	} catch (error) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Error al crear',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		});
	}
}

const onDelete = async (ID) => {

	const data = {
		// "technician": technicianID,
		"option": "eliminar", // eliminar - modificar - agregar
		"ostype": ID,
	}

	try {
		const res = await myFetch(`api/v1/modify_category_technician/`, data, "POST");
		console.log("res", res)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Registro borrado',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Intentelo nuevamente',
			placement: 'bottomLeft',
			duration: 1.5,
		})
	}
}

const onEdit = async (data) => {
	try {
		const res = await myFetch(`api/v1/modify_category_technician/`, data);
		console.log("res:", res)

	} catch (e) { }
}

const datatables_struct = async () => {
	try {
		const res = await myFetchGet("api/v1/technician/datatables_categories_struct/");
		const columns = [];
		const columnsTittles = Object.keys(res?.columns);
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				key: res?.columns[columnsTittle].field,
				title: columnsTittle,
				dataIndex:
					columnsTittle === "Tipo de orden de servicio"
						? "os_type"
						: columnsTittle === "Categorias" ? "categories_name"
							: "Operación",

				render: columnsTittle === "Tipo de orden de servicio" ?
					(value) => (
						<span key={value?.id} style={{ display: "flex", alignItems: "center" }}>
							<ColorCircle color={value?.color} />
							<DatatableRowText value={value?.name} />
						</span>
					)

					: columnsTittle === "Categorias" ?
						(value) => value?.map(texto => (
							<span key={Math.random()} style={{ display: "flex" }}>
								<DatatableRowText value={value?.name} />
							</span>
						))

						: (value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText value={value} />
							</span>
			});
		});

		return [
			...columns,
			{
				title: "Operación",
				dataIndex: "os_type",
				render: (value) => (
					<span style={{ display: "flex", alignItems: "center" }}>
						<button style={btnStyle} onClick={() => onEdit(value?.id)}>Modificar</button>

						<Popconfirm
							placement="topRight"
							icon={""}
							title="¿Eliminar registro?"
							description={() => {
								return (
									`Si eliminas este registro, se \neliminara de forma permanente\nde tu historial`)
							}}
							onConfirm={(e) => {
								// e.stopPropagation();
								// onDelete(value?.id);
								console.log("onConfirm", value)
							}}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Eliminar"
							cancelText="Cancelar"

						>
							<button style={btnStyle} onClick={() => onDelete(value)}>Eliminar</button>
						</Popconfirm>
					</span>
				)
			},

		];
	} catch (e) { }
};

const getDocuments = async (technicianID) => {
	try {
		const res = await myFetchGet(`api/v1/technicianpic/?owner_tech=${technicianID}`)
		const resultFilter = res?.filter(obj => !obj.deleted)
		return resultFilter;
	} catch (e) { }
}
const postDocument = async (document) => {
	try {
		await myFetch(`api/v1/technicianpic/`, document)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Se ha creado el documento',
			placement: 'bottomLeft',
			duration: 2,
			onClose: () => window.location.reload()
		})

	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se pudo crear el documento',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => console.log("no se pudo...a rezarle a dios")
		})
	}
}
const deleteDocument = async (documentID) => {
	try {
		await myFetch(`api/v1/technicianpic/${documentID}`, "", "DELETE")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Se ha borrado el documento',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
			// onClose: () => console.log("se borro")
		})

	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se pudo borrar el documento',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => console.log("no se pudo...a rezarle a dios")
		})
	}
}
const updateDocument = async (documentID, postData) => {
	console.log("documentID", documentID)

	try {
		await myFetch(`api/v1/technicianpic/${documentID}/`, postData, "PATCH")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Se ha editado el documento',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		})

	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se pudo editar el documento',
			placement: 'bottomLeft',
			duration: 2,
			// onClose: () => console.log("no se pudo...a rezarle a dios")
		})
	}
}

export {
	technicianDatatables_struct,
	techniciansCategory_struct,
	onTechnicianPost,
	getID_type,
	getTechnicianStatus,
	getTechnicians,
	onUpdateTecnician,
	getTechniciansCategoryDatatable,
	datatables_struct,
	onDelete,
	onEdit,
	postTechnicianTypeCategory,
	getDocuments,
	postDocument,
	deleteDocument,
	updateDocument,
}