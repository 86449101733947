import React from 'react'
import './index.scss'

const DatatableRowText = ({ value = "", bold = false, color = "#172B4D" }) => {
  return (
    <span className='datatableRowText'
      style={{
        fontWeight: bold === true ? "bold" : "400",
        color
      }}
    >{value}</span>
  )
}

export default DatatableRowText