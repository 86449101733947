/* eslint-disable */
import React, { useState, useEffect } from "react";
import { availabilityDataTable, availability_structColumns } from "../../../api/available_hours";
import Br from '../../../components/br/Br.jsx'
import { Table } from "antd";
import styled from "styled-components";
import NormalText from "../../../components/text/NormalText.jsx";
import { PanelBody } from "../../../components/panel/panel.jsx";
import AvailableCalendar from "./availableCalendar/index.jsx";
import { useSelector } from "react-redux";
import { permissionCheck } from "../../../helpers/helpers";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../helpers/sizes";

export default function AvailabilityPanel({ data }) {
	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [ROWS, setRows] = useState([]);
	const [COLUMNS, setColumns] = useState([]);
	const [directions, setDirections] = useState(null)
	const technicianID = data?.technician?.ID

	console.log("TableData:", ROWS)

	useEffect(() => {
		technicianID && availabilityDataTable(technicianID).then(res => setRows(res))
		technicianID && availability_structColumns().then(res => setColumns(res))
	}, []);

	const formClose = () => setIsVisibleForm(!isVisibleForm)

	return (
		<Container>
			{isVisibleForm === false
				? <span>
					<span className="header__content">
						<label className="label_header_text">Listado de registros de disponibilidad del técnico.</label>

						{
							rolPermission("add_schedule") === true ? (
								<button className="button" onClick={() => setIsVisibleForm(!isVisibleForm)}>
									<i className="fa-solid fa-plus"></i>
									<span className="export_text">Agregar disponibilidad</span>
								</button>
							) : null
						}
					</span>
					<Br />

					<Table
						columns={COLUMNS}
						dataSource={ROWS}
						pagination={false}
					/>
				</span>

				: <AvailableCalendar
					close={formClose}
					technicianID={technicianID}
				/>
			}

		</Container>
	)
}

const Container = styled.div`
width: 100%;

.row{
	display: flex;
	align-items: center;
}

.space-between{
	display: flex;
	justify-content: space-between;
}
.header__content{
    display: flex;
		align-items: center;
    justify-content: space-between;
    padding-left: 43px;
    padding-right: 69px;
		padding-top: 43.57px;
}

.button{
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	background: #DDE4EB;
	color: #131523;
	border: 1px solid #0060FF;
	border-radius: 3px;
	font-weight: 400;
	cursor: pointer;
	padding: 0 10px;

		:hover{
			background: #0060FF;
			color: white;
		}
  }

  .pdf_icon{
    font-size: ${smallSizes.fontSize.fontSize12};
  }

  .export_text{
    font-size: ${smallSizes.fontSize.fontSize12};
    margin-left: 5px;
  }

	.label_header_text{
		font-size: ${smallSizes.fontSize.fontSize14};
		color: #5A607F;
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.label_header_text{
			font-size: ${smallSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${smallSizes.fontSize.fontSize12};;
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.label_header_text{
			font-size: ${mediumSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${mediumSizes.fontSize.fontSize12};;
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.label_header_text{
			font-size: ${largeSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${largeSizes.fontSize.fontSize12};;
		}
	}
`

