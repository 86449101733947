import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Space, Switch, notification } from 'antd';
import Form from '../../../../components/form/index.jsx';
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx';
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx';
import Br from '../../../../components/br/Br.jsx';
import { format } from '../../../../helpers/helpers.js';
import { myFetch } from '../../../../services/services.js';
import { getDirections } from '../../../../api/available_hours.js';
import './index.scss';

import Select from 'react-select';
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx';
import BottomSection from '../../../../components/form/bottomSection/index.jsx';

import { debounce } from "lodash";

const ZoneAvilitationForm = ({ onModalZoneClose, firstDayOfWeek, technicianID, sendToForm }) => {
	const [culmination, setCulmination] = useState(true);
	const [endDate, setEndDate] = useState(null);

	// resultado de la api getDirections
	const [searchResults, setSearchResults] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [showOptions, setShowOptions] = useState(false)
	const [inputStatus, setInputStatus] = useState({ input1: '', input2: '' })

	const handleInputChange = (newValue) => setSearchValue(newValue);
	const handleChange = (selected) => setSelectedOptions(selected)

	const regions = selectedOptions?.map((option) => option?.value);

	const onDateChange = (_, dateString) => setEndDate(dateString);

	const options = searchResults?.map(item => {
		return {
			value: item?.id,
			label: item?.full_direction
		}
	})

	const customStyles = {
		multiValue: (base) => ({
			...base,
			backgroundColor: "white",
			color: '#5A607F',
			border: '1px solid #5A607F',
			borderRadius: "2px",
			fontSize: '12px'
		}),
		multiValueLabel: (base) => ({ ...base, color: '#5A607F' }),
		multiValueRemove: (base, state) => ({
			...base,
			backgroundColor: 'white',
			color: '#5A607F',
			':hover': { backgroundColor: "white" },
		}),
		control: (base, state) => ({
			...base,
			borderColor: inputStatus.input1 === "error" ? "red" : '#4096ff', // Cambia el color del borde en foco
			boxShadow: state.isFocused ? 'none' : base.boxShadow, // Cambia el color del sombreado en foco
		}),
	};

	const habilitationZoneCreate = async () => {
		const updatedDisponibility = sendToForm?.map(obj => ({ ...obj, dir: regions }));
		let habilitationData = {
			start_date: firstDayOfWeek,
			technician: technicianID,
			disponibility: updatedDisponibility
		};

		if (culmination === true) {
			habilitationData["end_date"] = endDate

			if (selectedOptions === null || endDate === null) {
				setInputStatus({
					input1: selectedOptions === null ? 'error' : '',
					input2: endDate === null ? 'error' : '',
				});

				notification.warning({
					style: { fontWeight: 'bold' },
					message: "Completa todos los campos",
					placement: 'bottomLeft',
					duration: 2.5,
				})
				return
			}

		}

		if (culmination === false) {
			if (selectedOptions === null) {
				setInputStatus({
					input1: selectedOptions === null ? 'error' : '',
					input2: '',
				});

				notification.warning({
					style: { fontWeight: 'bold' },
					message: "Completa todos los campos",
					placement: 'bottomLeft',
					duration: 2.5,
				})
				return
			}
		}

		try {
			await myFetch('api/v1/create_disponibility/', habilitationData);
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Se ha creado la nueva habilitación',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => window.location.reload()
			});
		} catch (e) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'No se ha creado la nueva habilitación',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => console.log('error', e)
			});
		}


	};

	// funcion que captura la tecla cuando se deja de oprimir
	const onKeyUp = () => {
		handlerSearch()
	}

	// funcion que captura al oprimir tecla
	const onKeyDown = () => {
		handlerSearch.cancel()
	}

	// Funcion para peticion de direcciones
	const handlerSearch = debounce(() => {
		if (searchValue?.length !== 0) {
			getDirections(searchValue)
				.then(res => {
					setSearchResults(res)
					setShowOptions(true)
				});
		} else {
			setSearchResults([])
			setShowOptions(false)
		}
	}, 750);

	useEffect(() => {
		if (searchValue?.length !== 0){
			setShowOptions(true)
		} else {
			setSearchResults([])
			setShowOptions(false)
		}
	}, [searchValue])
	

	return (
		<Form>
			<MainTitle
				value="Habilitación de zona"
				onClick={onModalZoneClose}
			/>

			<SubtitleBlue value="Zona de cobertura" />
			<LabelDrawer value="Región o comuna" />
		<div onKeyUp={onKeyUp}>
			<Select
				options={options}
				isMulti
				isSearchable
				value={selectedOptions}
				onInputChange={handleInputChange}
				menuIsOpen={showOptions === false ? false : true}
				onChange={handleChange}
				styles={customStyles}
				placeholder="Busca una ciudad"
				className='select_font_12'
				onKeyDown={onKeyDown}
			/>
			</div>

			<div className="switch_container">
				<div className="row">
					<span className='text_switch select_font_12'>
						Repetir siempre esta configuración?
					</span>
					<span className="switch_box">
						<span className='select_font_12'>No</span>
						<Switch
							style={{ margin: '0 5px' }}
							onChange={() => setCulmination(!culmination)}
						/>
						<span className='select_font_12'>Si</span>
					</span>
				</div>
			</div>

			<Br />
			{culmination && (
				<div className="column">
					<span>
						<LabelDrawer value="Fecha de culminación" />
					</span>
					<DatePicker
						inputReadOnly
						style={{ height: '40px' }}
						format={format}
						onChange={onDateChange}
						placeholder="Seleccione una fecha"
						status={inputStatus.input2 === "error" ? "error" : ""}
					/>
				</div>
			)}

			<Br />

			{/* <div className="bottom_section">
				<Space>
					<Button className="btn_normal" type="delete" onClick={onModalZoneClose}>
						Cancelar
					</Button>
					<Button className="btn_silver" onClick={habilitationZoneCreate}>
						Guardar
					</Button>
				</Space>
			</div> */}
			<BottomSection onCancel={onModalZoneClose} onAcept={habilitationZoneCreate} />
		</Form>
	);
};

export default ZoneAvilitationForm;
