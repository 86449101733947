/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getGalleryImage } from '../../../api/imageGallery';
import UploadImageForm from './UploadImageForm';

import EditImageForm from './EditImageForm';
import CustomTable from './CustomTable';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';
import Br from '../../../components/br/Br';

export default function GalleryPanel({ data }) {
	const [visibleEditForm, setVisibleEditForm] = useState(false)
	const [visibleUploadForm, setVisibleUploadForm] = useState(false)

	const [ROWS, setROWS] = useState(null)
	const [selectedInfo, setSelectedInfo] = useState(null)

	useEffect(() => {
		IDdeOS && getGalleryImage(IDdeOS).then(res => setROWS(res?.response))
	}, [])

	const IDdeOS = data?.id_unico;
	const onEditForm = (item) => {
		setSelectedInfo(item)
		setVisibleEditForm(true)
	}

	const openUploadForm = () => setVisibleUploadForm(true)
	const closeUploadForm = () => setVisibleUploadForm(false)



	return (
		<Container>
			<p className='text-gallery-description'>Listados de imágenes asociadas a la categoría de la orden de servicio.</p>
			<CustomTable
				onEditForm={onEditForm}
				setVisibleUploadForm={setVisibleUploadForm}
				setSelectedInfo={setSelectedInfo}
				row={ROWS}
			/>

			{/* Form para Subir */}
			{visibleUploadForm &&
				<UploadImageForm
					ID={IDdeOS}
					selectedInfo={selectedInfo}
					openUploadForm={openUploadForm}
					closeUploadForm={closeUploadForm}

				/>
			}

			{/* Form para editar */}
			{visibleEditForm &&
				<EditImageForm
					setVisibleEditForm={setVisibleEditForm}
					selectedInfo={selectedInfo} />
			}
		</Container>
	)

}

const Container = styled.div`
  width: 100%;
	margin-top: 21px;


  .text-gallery-description{
    color: #5A607F;
    font-size: ${smallSizes.fontSize.fontSize14};
    padding-left: 48px !important;
  }
  .img{
    width: 116px;
    height: 76px;
  }
  .date{
    font-size: 16px;
    color: #2B80FF;
    font-weight: 600;
  }
  .blue_text{
    font-size: 13px;
    color: #2B80FF;
    text-decoration: none;
  }

	@media screen and (min-width: ${devices.smallDesk}){
		.text-gallery-description{
			font-size: ${smallSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.text-gallery-description{
			font-size: ${mediumSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.text-gallery-description{
			font-size: ${largeSizes.fontSize.fontSize14};
		}
	}
`