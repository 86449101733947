import React, { useRef, useState } from 'react'
import styled from 'styled-components';

import { CloseOutlined } from '@ant-design/icons';
import CustomModal from '../../../components/modal/CustomModal';
import { Button, Divider, Input, Popconfirm, notification } from 'antd';

import Br from '../../../components/br/Br';
import { myFetch } from '../../../services/services';
import size from '../../../helpers/sizes';
import color from '../../../helpers/color';
import MainTitle from '../../../components/text/drawer/mainTitle';
import Form from '../../../components/form/index.jsx';
import LabelDrawer from '../../../components/text/drawer/label';
import BottomSection from '../../../components/form/bottomSection';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';

const EditImageForm = (props) => {
	const onModalClose = () => props.setVisibleEditForm(false)
	const [caption, setCaption] = useState(props?.selectedInfo?.name)
	const [photo, setTempPhoto] = useState(props?.selectedInfo?.photo)
	const [base64Img, setBase64Img] = useState(null)

	const { ID } = props?.selectedInfo

	const clearTempImage = () => {
		setTempPhoto(null)
		setBase64Img(null)
	}

	const inputFile = useRef()

	const onUploadOspic = async () => {
		const data = {
			"caption": caption,
			"photo_base64": base64Img,
		}
		try {
			await myFetch(`api/v1/ospic/${ID}/`, data, "PATCH")
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Actualizado con exito',
				placement: 'bottomLeft',
				onClose: () => {
					onModalClose();
					window.location.reload();
				}
			});
			// onModalClose()
			// window.location.reload();
		} catch (error) {
			console.log(error);
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: 'No se ha podido actualizar',
				placement: 'bottomLeft',
				duration: 2.5,
				onClose: () => onModalClose()
			});
		}
	}

	const passingToBase64 = (archivos) => {

		setTempPhoto(archivos[0])

		Array.from(archivos).forEach(archivo => {

			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplited = []
				const base64 = reader.result;

				arraySplited = base64.split(',');
				setBase64Img(arraySplited[1])
			}
		})
	}

	const hidden = { display: "none" }

	return (
		<CustomModal onClick={onModalClose}>
			<Form>
				<Container>
					<MainTitle value='Subir archivo' onClick={onModalClose} />

					<button
						className="uploader_box"
						onClick={() => inputFile.current.click()}
					>
						<i className="fa-solid fa-upload icon_size_button" />
						<span className='subtitle_button'>Haga click o arrastre el archivo a esta área para cargarlo</span>
						<span className='help_button'>Soporte para una carga única o masiva. Prohibido estrictamente</span>
						<span className='help_button'>cargar datos de la empresa u otros archivos de bandas.</span>
						<input type="file" ref={inputFile} onChange={(e) => passingToBase64(e.target.files)} style={hidden} />
					</button>

					{base64Img && <Br />}

					{
						base64Img &&
						<div className='temp_image_box'>
							<img className='temp_image animate__animated animate__fadeIn' src={`data:image/png;base64,${base64Img}`} alt="Temporal selected base64" />
							<p className="animate__animated animate__fadeIn temp_name">{photo?.name}</p>
							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Eliminar fotografía?"
								description={() => {
									return (
										`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
								}}
								onConfirm={clearTempImage}
								okText="Eliminar"
								cancelText="Cancelar"
							>
								<i className="fa-solid fa-trash clear_tempImageIcon" ></i>
							</Popconfirm>

						</div>
					}

					{/* ------------------------- */}
					<Br />
					<div className="column">
						<LabelDrawer value='Descripciòn' />
						<Input
							style={{ height: "40px" }}
							className="input"
							type="text"
							onChange={(e) => setCaption(e.target.value)}
							placeholder={caption}
						/>
					</div>
					<Br />
					<Br />

					<BottomSection onCancel={onModalClose} onAcept={onUploadOspic} />


				</Container>
			</Form>
		</CustomModal>
	)
}



export default EditImageForm


const Container = styled.div`

p{
    display: inline;
    margin: inherit;
}

.formularioOSContent{
    width: ${size.drawerForm};
    height: 100%;
    display: flex;
    flex-flow: column;
    background: white;
    padding: 0 28.69px;
    padding-top: 33.43px;
  }

.row {
	margin: inherit;
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}
.align-item-center{
    align-items: center;
}
.row-center{
    justify-content: center;
}
.flex-end{
    justify-content: flex-end;
}
.space-between{
    justify-content: space-between;
}
.column_center{
    justify-content: center;
}

.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: bold;
    color: #2B80FF;
    margin-bottom: 1em;
}

.blue{
    color: #2B80FF;
}


.uploader_box {
	display: flex;
	flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;

  :hover {
			border: 1px dashed #d9d9d9;
		}
}
.temp_image_box {
  width: 100%;
  // height: 76px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  padding: 1em;
  display: flex;
  align-items: center;
}
.temp_image {
  width: 116px;
  height: 76px;
  border-radius: 3px;
  object-fit: cover;
}
.clear_tempImageIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #2B80FF;
  border-radius: 2px;
  cursor: pointer;

		:hover {
			color: grey;
		}
}

.uploader_box .icon_size_button{
	font-size: ${smallSizes.fontSize.fontSize36};
	color: #DDE4EB;
	margin-bottom: 10px;
}

.uploader_box .help_button{
	color: silver;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.temp_image_box .temp_name{
	color: #5A607F;
	padding: 0 10px;
	flex: 1;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

//------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${smallSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.mediumDesk}){
	.subtitle_button{
    font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${mediumSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.largeDesk}){
	.subtitle_button{
    font-size: ${largeSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${largeSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

}

`


