import React from 'react';
import App from './app.jsx';
import store from './store/index.js';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from "react-dom/client";

// css
import "antd/dist/reset.css"
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

// css-brujula
import './index.css';
import "./scss/main.scss";

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'axios-progress-bar/dist/nprogress.css'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import moment from "moment";
import { ConfigProvider } from "antd";
import esES from "antd/locale/es_ES";

import "moment/locale/es";
moment.locale("es");

const persistor = persistStore(store)
createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<ConfigProvider locale={esES}>
					<App />
				</ConfigProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);