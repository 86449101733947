/* eslint-disable */
import { Input, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { myFetch, myFetchGet } from '../../../../services/services'
import LabelDrawer from '../../../../components/text/drawer/label';
import Br from '../../../../components/br/Br';
import { emptyInputsCheck, onlyNumber, onlyTextAndSpace } from '../../../../helpers/helpers';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes';
import SearchDirection from '../searchDirection/SearchDirection';
import { debounce } from "lodash";

const { TextArea } = Input;

export default function InputSearch(
	{
		loginOperadorID,
		setFiltreValue,
		filtreValue,
		dataUser,
		idTypes,
		setManualRegister,
		setName,
		setLast_name,
		setDocumentType,
		setDocumentNumber,
		setPhone,
		setEmail,
		setIdServices,
		setAddress,
		camposIncompletos,
		setSelectUserSearch,
		category,
		setAvailableTechnicians,
		setSelectedDirectionId,
		setSelectedDirectionName
	}
) {
	const [isVisibleListResult, setIsVisibleListResult] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [valueList, setValueList] = useState([])

	const [showManualUI, setShowManualUI] = useState(false)

	const [prefixes, setPrefixes] = useState('')

	const [id_type, setId_type] = useState(null)

	const [Id_typeName, setId_typeName] = useState(null)

	const { Option } = Select;
	const [error, setError] = useState(false)

	const [fullName, setFullName] = useState()

	// ------------Validations:-------------
	// -------------------------------------
	const [userNameError, setUserNameError] = useState(null)
	const [last_nameError, setLast_nameError] = useState(null)
	const [phoneError, setPhoneError] = useState(null)
	const [idNumberError, setIdNumberError] = useState(null);

	const [idNumber, setIdNumber] = useState(null);
	const [searchDirection, setSearchDirection] = useState('');

	const [isVisibleListResultDirection, SetIsVisibleListResultDirection] = useState(false)
	const [dataDirection, SetDataDirection] = useState([])
	const [loaddingSearchDirection, SetLoaddingSearchDirection] = useState(false)
	const [directionSelected, setDirectionSelected] = useState('');

	const [dataUserSelected, setDataUserSelected] = useState('');

	const onNameChange = (e) => {
		if (onlyTextAndSpace(e.target.value)) {
			setUserNameError("")
			setName(e.target.value)
		} else {
			setName(null)
			setUserNameError("Ingresa solo texto, sin números")
		}
	}
	const onSurnameChange = (e) => {
		if (onlyTextAndSpace(e.target.value)) {
			setLast_nameError("")
			setLast_name(e.target.value)
		} else {
			setLast_name(null)
			setLast_nameError("Ingresa solo texto, sin números")
		}
	}

	const phoneOnchange = (e) => {
		if (onlyNumber(e.target.value)) {
			setPhone(e.target.value)
			setPhoneError(null)

		} else {
			setPhone(null)
			setPhoneError("Ingresa solo números")
		}
	}

	// -------------------------------------
	// -------------------------------------


	const namePlaceholder = "Ingresar Nombre o Apellido"
	const docPlaceholder = "Ingresar Documento de Indentidad"
	const idServicePlaceholder = "Ingresar ID de Servicio"


	const onChangeCheck = (e) => setFiltreValue(e)

	const onManualRegister = () => {
		setIsVisibleListResult(false)
		setShowManualUI(true)
		setManualRegister(true)
	}

	const getUser = async () => {
		if (inputValue !== "") {
			try {
				const res = await myFetchGet(`api/v1/get_all_users/?operator=${loginOperadorID}&tipo_busqueda=${filtreValue}&valor_busqueda=${inputValue}`);
				setValueList(res?.users)
			} catch (e) {
				setIsVisibleListResult(false)
			}
		} else { setValueList([]) }
	}

	function handleSelecteValue(user) {
		// Nombre a visualizar en el Input
		setFullName(user?.first_name + " " + user?.last_name)
		// Data de usuario seleccionado
		dataUser(user);
		setIsVisibleListResult(false);
		// Visualizacion data de usuario seleccionado 
		setSelectUserSearch(true);
		// data usuario para peticion de disponibilidad
		setDataUserSelected(user);
	}

	// -------------------------------
	// -------------------------------
	// const ISO = "CL"
	const ISO = idTypes?.iso
	const isoFlag = ISO?.toLowerCase()

	const handleSelectChange = (value) => {

		// string = name
		setId_typeName(value)

		// number
		const filtered = idTypes?.data?.filter(item => item.name === value)
		setId_type(filtered[0].ID)
		setDocumentType(filtered[0].ID)

	};

	const onIdentificationNumber = (e) => {
		const value = e.target.value
		setIdNumber(value)
		setDocumentNumber(value)
	}

	const regexFormats = {
		"CL-RUN": /^[0-9]+[-|‐]{1}[0-9kK]{1}$/i,
		// *Nota: Ingresar sin puntos + "-" + codigo verificador.

		//valida que sean num. y termine en "k"
		"CL-RUT": /^[0-9]+[-|‐]{1}[0-9kK]{1}$/i,

		// 'pasaporte debe contener 2 MAYUSCULAS + 7 digitos' sin puntos
		"CL-Pass": /^[A-Z]{2}[0-9]{7}$/,

		// DNI de peru debe llevar 8 digitos + - + codigo de verificacion
		"PE-DNI": /^\d{8}[-|‐]{1}[0-9]{1}$/,

		// ce debe llevar 9 numeros
		"PE-CE": /^\d{9}$/,

		//default
		"default": /^\d{9}$/
	}

	useEffect(() => {
		if (idNumber !== null) {
			var timeoutId = setTimeout(() => {
				var expr = ISO + "-" + Id_typeName;
				switch (expr) {
					default:

						if (expr in regexFormats) {
							regexFormats[expr].test(idNumber) === false ? setIdNumberError(idErrorMsg) : setIdNumberError("")
						} else {
							regexFormats['default'].test(idNumber) === false ? setIdNumberError(idErrorMsg) : setIdNumberError("")
						}
				}
			}, 1000);
		}

		return () => clearTimeout(timeoutId);
	}, [idNumber]);


	useEffect(() => {
		if (idTypes?.iso === "PE") {
			setPrefixes("+51")
		} else if (idTypes?.iso === "CL") {
			setPrefixes("+56")
		} else if (idTypes?.iso === "CO") {
			setPrefixes("+57")
		} else if (idTypes?.iso === "VE") {
			setPrefixes("+58")
		}
	}, [idTypes?.iso])

	const idErrorMsg = "La identificacion no cumple con el formato"

	// metodo para recolectar el dato escrito en el input direccion
	const onDirectionSearch = ({ target }) => {
		// estado para busqueda de la direccion
		setSearchDirection(target.value);
		SetIsVisibleListResultDirection(true);
		// esatdo para visualizar en el imnut
		setDirectionSelected(target.value);
	}

	// metodo apra realizar la peticion de api de direccion 
	const getDirection = async () => {
		const res = await myFetchGet(`api/v1/direction/search/?generic=${searchDirection}`);
		SetDataDirection(res);
		SetLoaddingSearchDirection(true);
	}

	// const getTechnicials = async (directionId) => {
	// 	const responseTechnicials = await myFetchGet(`api/v1/schedule/category_match/?dir=${directionId}&category=${category}`)
	// 	setAvailableTechnicians(responseTechnicials.data);
	// }

	// funcion que captura la tecla cuando se deja de oprimir
	const handleKeyUp = () => {
		handlerSearchUser();
	};

	// funcion que captura al oprimir tecla
	const handleKeyDown = () => {
		handlerSearchUser.cancel();
	};

	// funcion con calback para el tiempo
	const handlerSearchUser = debounce(() => {
		getUser();
	}, 750);

	const onInputChange = (e) => {
		// hacer validacion que si esta vacio elimine la seccion de registro de manual
		if (e.target.value === '') {
			setIsVisibleListResult(false);
			setSelectUserSearch(false);
		} else {
			setIsVisibleListResult(true)
		}
		const value = setInputValue(e.target.value)
		setFullName(value)
	}

	const handleSelectDirection = (item) => {
		setDirectionSelected(item);
		SetIsVisibleListResultDirection(false);
	}

	const handleKeyUpDirection = () => {
		handlerSearchDirection();
	}

	const handleKeyDownDirection = () => {
		handlerSearchDirection.cancel();
	}

	const handlerSearchDirection = debounce(() => {
		if (searchDirection !== '') getDirection();
	}, 750);

	// useEffect para validar si el input esta vacio, y ejecutar metodo getDirection
	useEffect(() => {
		if (searchDirection === '') {
			SetIsVisibleListResultDirection(false);
			SetLoaddingSearchDirection(false);
		}
	}, [searchDirection])

	useEffect(() => {
		if (directionSelected.id !== undefined) {
			setSelectedDirectionId(directionSelected.id);
			setSelectedDirectionName(directionSelected.name);
		} else if (dataUserSelected.direction_id !== null && category !== null) {
			setSelectedDirectionId(dataUserSelected.direction_id);
			setSelectedDirectionName(directionSelected.name);
		}

	}, [directionSelected, dataUserSelected])

	return (
		<Container>
			{
				showManualUI === false
					? (
						<>
							<div className='row space-between'>
								<LabelDrawer value="Buscar cliente" />
								<div className="row">
									<Radio
										onChange={() => onChangeCheck('docIndentidad')}
										checked={filtreValue === 'docIndentidad' ? true : false}
									>Documento de identidad</Radio>

									<Radio
										onChange={() => onChangeCheck('name')}
										checked={filtreValue === 'name' ? true : false}
									>Nombre</Radio>

									<Radio
										onChange={() => onChangeCheck('idService')}
										checked={filtreValue === 'idService' ? true : false}
									>ID del servicio</Radio>
								</div>
							</div>
							<Input
								className='select-input-search'
								allowClear
								placeholder={
									filtreValue === "name" ? namePlaceholder
										: filtreValue === "docIndentidad" ? docPlaceholder
											: idServicePlaceholder
								}
								onKeyUp={handleKeyUp}
								onKeyDown={handleKeyDown}
								onChange={onInputChange}
								value={fullName}
								status={emptyInputsCheck(camposIncompletos, "user_id") ? "error" : ""}
							/>
						</>
					)
					: (
						<ManualUI>

							{/* --------------Nombre y Apellido-------------- */}
							<span className="row space-between">
								<div className="left">
									<LabelDrawer value="Nombre" />
									<Input
										style={{ height: "40px" }}
										className="input"
										type="text"
										onChange={onNameChange}
										placeholder='Ingrese Nombre'
										status={emptyInputsCheck(camposIncompletos, "first_name") ? "error" : ""}

									/>
								</div>
								<div className="right">
									<LabelDrawer value="Apellido" />
									<Input
										style={{ height: "40px" }}
										className="input"
										type="text"
										onChange={onSurnameChange}
										placeholder='Ingrese Apellido'
										status={emptyInputsCheck(camposIncompletos, "last_name") ? "error" : ""}
									/>
								</div>
							</span>

							{/* ---ERRORS--- */}
							<span className="row space-between">
								<div className="left">{userNameError && <span className='error__message'>{userNameError}</span>} </div>
								<div className="right">{last_nameError && <span className='error__message'>{last_nameError}</span>} </div>
							</span>


							{/* --------------DNI y Telefono-------------- */}

							<Br />
							<span className="row space-between">
								<div className="left">
									<div className="column">
										<LabelDrawer value="Documento de identidad" />
										<div className="row space-between">
											<Select
												onChange={handleSelectChange}
												style={{ height: "40px" }}
												className="identityDocSelect"
												placeholder="Tipo"
												status={emptyInputsCheck(camposIncompletos, "document_type") ? "error" : ""}
											>
												{idTypes?.data?.map(item =>
													<Option key={item.ID} value={item?.name}>
														{item?.name}
													</Option>)}
											</Select>

											<span className='column' style={{ width: "calc(97% - 70px)" }}>
												<Input
													id="idNumber"
													onChange={onIdentificationNumber}
													maxLength="12"
													className="input__number"
													value={idNumber}
													placeholder="Ingrese numero de documento"
													status={emptyInputsCheck(camposIncompletos, "document_number") ? "error" : ""}
												/>
											</span>
										</div>
									</div>

								</div>
								<div className="right">
									<div className="column">
										<LabelDrawer value="Número de teléfono" />

										<div className="row space-between">
											<span
												className='phoneFlagsSelect'
												style={{ marginBottom: error ? 12 : 0 }}
											>
												{
													isoFlag && (
														<img
															className='img-phone'
															src={`https://flagcdn.com/20x15/${isoFlag}.png`}
															alt=""
														/>
													)
												}

												{
													prefixes && (
														<span className='prefixes-phone'>
															{prefixes}
														</span>
													)
												}
											</span>
											<Input
												onChange={phoneOnchange}
												className="input__phoneNumber"
												style={{ height: 40 }}
												placeholder="Ingrese numero de telefono"
												status={emptyInputsCheck(camposIncompletos, "phone") ? "error" : ""}
											/>
										</div>

									</div>
								</div>
							</span>

							{/* ---ERRORS--- */}
							<span className="row space-between">
								<div className="left">{idNumberError && <span className='error__message'>{idNumberError}</span>} </div>
								<div className="right">{phoneError && <span className='error__message'>{phoneError}</span>} </div>
							</span>

							<Br />

							{/* --------------Correo y ID de servicio-------------- */}
							<span className="row space-between" style={{ marginTop: 9 }}>
								<div className="left">
									<LabelDrawer value="Correo electrónico" />
									<Input
										placeholder='Example@gmail.com'
										style={{ height: "40px" }}
										className="input"
										type="text"
										onChange={(e) => setEmail(e.target.value)}
										status={emptyInputsCheck(camposIncompletos, "email") ? "error" : ""}
									/>
								</div>
								<div className="right">
									<LabelDrawer value="ID del servicio" />
									<Input
										style={{ height: "40px" }}
										className="input"
										type="text"
										onChange={(e) => setIdServices(e.target.value)}
										maxLength={12}
										placeholder='Ingrese Id de servicio'
										status={emptyInputsCheck(camposIncompletos, "service_number") ? "error" : ""}
									/>
								</div>
							</span>

							<Br />
							{/* --------------Buscar Direccion-------------- */}
							<span className='column'>
								<LabelDrawer value="Buscar dirección" />
								<Input
									placeholder='Ingrese la dirección a consultar'
									style={{ height: 40 }}
									onKeyUp={handleKeyUpDirection}
									onKeyDown={handleKeyDownDirection}
									onChange={onDirectionSearch}
									value={directionSelected.full_direction}
									allowClear
									status={emptyInputsCheck(camposIncompletos, "direction") ? "error" : ""}
								/>
							</span>
							{isVisibleListResultDirection &&
								<SearchDirection
									data={dataDirection}
									loadding={loaddingSearchDirection}
									handleSelectDirection={handleSelectDirection}
								/>}

							<Br />
							{/* --------------Direccion-------------- */}
							<span className='column'>
								<LabelDrawer value="Complemento dirección" />
								<Input
									placeholder='Ingrese descripcion'
									maxLength={100}
									style={{ height: 65 }}
									onChange={(e) => setAddress(e.target.value)}
									status={emptyInputsCheck(camposIncompletos, "direction_text") ? "error" : ""}
								/>
							</span>

						</ManualUI>
					)
			}


			{
				isVisibleListResult &&
				(
					<SubContInputSearch>
						<div className='container_search_users custom_scroll'>
							{
								valueList?.map((item) =>
								(
									<div className='subcontainer_search_users' onClick={() => handleSelecteValue(item)} key={item?.ID}>
										<span className='subspan_text'>
											<span className='text_search'>{item?.document_type_name} {item?.document_number} </span>
											<span className='text_mid_search'>{item?.first_name} {item?.last_name}</span>
											<span className='text_right_search'>{item?.number_service}</span>
										</span>

										<span className='prueba'>{item?.direction}</span>

									</div>
								)
								)}
							<button className='manual_register' onClick={onManualRegister}>Registro manual de cliente</button>
						</div>
					</SubContInputSearch>
				)
			}
		</Container>
	)
}
const Container = styled.div`

.row{
	margin: 0 !important;
	align-items: center;
	margin-top: 5px;
}

.row_center{
	justify-content: center;
}
.row_end{
	justify-content: flex-end;
}
.space-between{
	justify-content: space-between;
	margin: 0 !important;
}
.subtitled{
	font-size: 14px;
	font-weight: bold;
	color: #2B80FF;
}
.blue{
	color: #2B80FF;
}

.ant-radio-wrapper{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.select-input-search{
	width: 100%;
	height: 40px;
	margin-top: 7px;
	border-radius: 5px;
	// margin-bottom: 30px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){

	.ant-radio-wrapper{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.ant-radio-wrapper{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.ant-radio-wrapper{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`

const SubContInputSearch = styled.div`


.container_search_users{
	width: 100%;
	background: white;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 15px;
	overflow-y: auto;
	max-height: 200px;
	height: auto;
	// position: reative;
}

.container_search_users .subcontainer_search_users{
	cursor: pointer;
	// display: flex;
	margin-bottom: 11px;
	// justify-content: space-between;
	width: 70%
}

.container_search_users .subcontainer_search_users:hover {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search_users .subspan_text{
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.subcontainer_search_users .text_search{
	font-size: 12px;
	color: #131523;
	width: 40%;
}

.subcontainer_search_users:hover .text_search {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search_users .text_mid_search{
	width: 40%;
	text-align: left;
}

.subcontainer_search_users .text_right_search{
	width: 40%;
	text-align: left;
}

.subcontainer_search_users .prueba{
	margin: 0;
}

.manual_register{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-top: auto;
	padding: 0 !important;
	font-weight: bold;
	width: 190px;
	height: 15px;
	border: none;
	outline: none;
	background: white;
	text-align: left;
}

@media screen and (min-width: ${devices.smallDesk}){

	.manual_register{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.manual_register{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.manual_register{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`
const SubCont = styled.div`
  cursor: pointer;
`
const SubCont2 = styled.div`
  display: flex;
  flex-flow: column;
  background: white;
  color: #131523;
  margin-bottom: 11px;

  :hover{
    color: #2B80FF;
  }

  .RUT{
    font-size: 12px;
    font-weight: bold;

    .full_name{
      margin: 0 25px 0 14px;
    }
  }
  .direction{
    font-size: 11px;
    font-weight: bold;
  }
`

const ManualUI = styled.div`

  .row {
		margin: inherit;
		display: flex;
		align-items: center;
    margin-top: 10px;
	}
	.column {
		display: flex;
		flex-flow: column;
    }
  .align-item-center{
    align-items: center;
  }
  .row-center{
    justify-content: center;
  }
 
  .flex-end{
    justify-content: flex-end;
  }
  .space-between{
    justify-content: space-between;
  }
  .column_center{
    justify-content: center;
  }

  .subtitle{
    font-size: 14px;
    font-weight: bold;
    color: #2B80FF;
  }

// input text nomal de ant
.ant-input {
	margin: 0;
	padding: 0;
	width: 100%;
	padding: 4px 11px;
	color: #868D96;
	font-size: ${smallSizes.fontSize.fontSize12};
	background-color: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: all 0.3s;
}

.identityDocSelect{
  width: 72px;
  height: 40px;
  background: white;
  border-radius: 5px;
}
.phoneFlagsSelect{
  width: 84px;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  display : flex;
  justify-content: center;
  align-items: center;
}

// select ant;
.irNypR .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #E0E0E0;
}
  

.blue{
    color: #2B80FF;
}
 .select{
    width: 100%;
    height: 40px;
    background: white;
    color: #868D96;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    
    padding: 6px 11px 6px;
  
  }
 .input__number{
    // width: 97%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    color: #868D96;
    padding-left: 11px;
    background: white;
  }
 .input__phoneNumber{
    width: calc(97% - 84px);
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    color: #868D96;
    padding-left: 11px;
  }
  /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
}
  
.left, .right {
	width: 49%
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
}

.prefixes-phone{
	color: #868D96;
	margin-left: 8px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.img-phone{
	width: ${smallSizes.fontSize.fontSize12};
	height: ${smallSizes.fontSize.fontSize12};
}


@media screen and (min-width: ${devices.smallDesk}){

	.ant-input {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${smallSizes.fontSize.fontSize12};
		height: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.ant-input {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${mediumSizes.fontSize.fontSize12};
		height: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.ant-input {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${largeSizes.fontSize.fontSize12};
		height: ${largeSizes.fontSize.fontSize12};
	}
}
`